import { React } from "react";

import { useTranslation } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser';

const TextoNoticia = ({ noticia, populares, ruta }) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

    if (noticia.imagen) {
        imagen = ruta + "/blog/" + noticia.id + "/" + noticia.imagen;
    }

    const results = [];
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={t("Blog")} style={{ backgroundImage: "url('" + imagen + "')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover" }} >
                    </div>
                    <h1 className="noticia-individual mt-5 mb-5">{noticia.eltitulo}</h1>
                    <div className="noticia-individual meta mb-4 gris">
                        <i className="fa fa-calendar"></i>
                        <a className="gris" href="#"> {noticia.fecha_publica} </a>
                    </div>
                    <div className="noticia-individual-descripcio-curta fw-bolder mb-4">
                        {ReactHtmlParser(noticia.ladesccorta)}
                    </div>
                    <div className="noticia-individual-descripcio-llarga mb-5">
                        {ReactHtmlParser(noticia.ladesclarga)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TextoNoticia;
