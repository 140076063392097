
import Menu from './Compartido/Menu.jsx';
import Buscador from './Buscar/Buscador.jsx';
import PropiedadesBuscar from './Buscar/PropiedadesBuscar.jsx';
import Footer from './Compartido/Footer.jsx';

import { useTranslation } from 'react-i18next';

const Buscar = (props) => {

    let { t } = useTranslation();

    return (
        <main>
            <Menu
                idiomas={props.idiomas}
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                ruta={props.ruta}
                meta_titulo={"Magno Apartments - " + t("Listado alojamientos")}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
            />
            <div className="container pujarenmobil">
                <div id="post-14210" className="post-14210 page type-page status-publish hentry element-hidden-tablet element-hidden-tablet_extra element-hidden-mobile element-hidden-mobile_extra capsacercador">
                    <section className="wpb-content-wrapper">
                        <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: "90px" }}>
                            <PropiedadesBuscar
                                lang={props.lang}
                                ruta={props.ruta}
                                propiedades={props.propiedades}
                                elementosweb={props.elementosweb}
                                adultos={props.adultos}
                                ninos={props.ninos}
                                bebes={props.bebes}
                                llegada={props.llegada}
                                salida={props.salida}
                                config={props.websgrupo}
                                moneda={props.moneda}
                                totalapartamentos={props.totalapartamentos}
                                pagina_actual={props.pagina_actual}
                                palabra={props.palabra}
                                orden={props.orden}
                            />
                            <Buscador
                                destino={props.destino}
                                destinos={props.destinos}
                                adultos={props.adultos}
                                ninos={props.ninos}
                                bebes={props.bebes}
                                llegada={props.llegada}
                                salida={props.salida}
                                tipocasa={props.tipocasa}
                                lang={props.lang}
                                config={props.websgrupo}
                                filtros={props.filtros}
                                palabra={props.palabra}
                                propiedades={props.propiedades}
                                losbarrios={props.barrios}
                                tipoprops={props.tipoprops}
                                totalapartamentos={props.totalapartamentos}
                                ruta={props.ruta}
                                orden={props.orden}
                            />
                        </div>

                        <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                idiomas={props.idiomas}
                ruta={props.ruta}
            />
        </main>
    );
}

export default Buscar;
