import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import BotonWhatsapp from "./BotonWhatsapp";

const Footer = ({ datos, terminos, protocolos, avisolegal, config, elementosweb, politicaprivacidad, lang, ruta, logos_pie }) => {

    const { t } = useTranslation();

    var currentTime = new Date();
    var year = currentTime.getFullYear();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            <footer className={"footer bg-white pt-5"}>
                <Container>
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <img className="logo-footer" alt="KuKaay" title="Kukaay" src="https://bedloops3bucket-1.s3.eu-south-2.amazonaws.com/hersolrentals/web/logo/Ku_Kaay_logotype_D.png" />
                        </div>
                        <div className="col-md-3">
                            <ul className="no-padding">
                                <li>{datos.nombre_comercial}</li>
                                <li>{datos.nombre_fiscal}</li>
                                <li>{datos.direccion_fiscal}</li>
                                <li>{datos.codigo_postal_fiscal}</li>
                                <li>{datos.nif_fiscal}</li>
                                <li>&copy; {year}</li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="no-padding">
                                <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>
                                {protocolos.ladesc != "" ? <li><a href={"/" + preidioma + t("protocolos")}>{t("Protocolos")} </a></li> : ''}
                                <li><a href={"/" + preidioma + "faqs"}>{t("Faq's")}</a></li>
                                {terminos.ladesc != "" ? <li><a href={"/" + preidioma + t("terminos")}>{t("Términos")}</a></li> : ''}
                                <li><a href={"/" + preidioma + t("politica-de-cancelacion")}>{t("Política_de_cancelación")}</a></li>
                                {politicaprivacidad.ladesc != "" ? <li><a href={"/" + preidioma + t("politica-de-privacidad")}>{t("Política de privacidad")}</a></li> : ''}
                                {avisolegal.ladesc != "" ? <li><a href={"/" + preidioma + t("legal")}>{t("Aviso Legal")}</a></li> : ''}
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="no-padding">
                                {datos.url_facebook && datos.url_facebook != "" ? <li><a href={datos.url_facebook}><i className="fa-brands fa-facebook" target="_blank"></i> Facebook</a></li> : ''}
                                {datos.url_twitter && datos.url_twitter != "" ? <li><a href={datos.url_twitter}><i className="fa-brands fa-twitter" target="_blank"></i> Twitter</a></li> : ''}
                                {datos.url_instagram && datos.url_instagram != "" ? <li><a href={datos.url_instagram}><i className="fa-brands fa-instagram" target="_blank"></i> Instagram</a></li> : ''}
                            </ul>
                        </div>
                    </div>
                </Container>
                {
                    (logos_pie) ?
                        <Container>
                            <div className="row">
                                {
                                    (logos_pie.url_1 != "" && logos_pie.imagen_1 != "") ?
                                        <div className="mb-5 col-3">
                                            <a href={logos_pie.url_1}>
                                                <img className="mx-auto img-fluid w-50 d-block" src={ruta + "/piedepagina/1/logos/1/" + logos_pie.imagen_1} />
                                            </a>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (logos_pie.url_2 != "" && logos_pie.imagen_2 != "") ?
                                        <div className="mb-5 col-3">
                                            <a href={logos_pie.url_2}>
                                                <img className="mx-auto img-fluid w-50 d-block" src={ruta + "/piedepagina/1/logos/1/" + logos_pie.imagen_2} />
                                            </a>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (logos_pie.url_3 != "" && logos_pie.imagen_3 != "") ?
                                        <div className="mb-5 col-3">
                                            <a href={logos_pie.url_3}>
                                                <img className="mx-auto img-fluid w-50 d-block" src={ruta + "/piedepagina/1/logos/1/" + logos_pie.imagen_3} />
                                            </a>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (logos_pie.url_4 != "" && logos_pie.imagen_4 != "") ?
                                        <div className="mb-5 col-3">
                                            <a href={logos_pie.url_4}>
                                                <img className="mx-auto img-fluid w-50 d-block" src={ruta + "/piedepagina/1/logos/1/" + logos_pie.imagen_4} />
                                            </a>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                        </Container>
                        :
                        ""
                }
            </footer>
            <BotonWhatsapp
                websgrupo={datos}
            />
        </>

    );
};
export default Footer;

