import { React } from "react";
import { Container } from 'react-bootstrap';

const DatosBarrio = ({ barrio }) => {
    return (
        <div className="pt-2 pb-5 potblur">
            <h1 className="mb-5 text-center">{barrio.elnombre}</h1>
            <Container>
                <div className="noticia-individual-descripcio-llarga">
                    <div dangerouslySetInnerHTML={{ __html: barrio.eltexto }}></div>
                </div>
            </Container>
        </div>
    );
}

export default DatosBarrio;
