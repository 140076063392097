import { React } from "react";

import { useTranslation } from 'react-i18next';

const TextosPreautorizar = ({ datoscliente, datospropiedad, reserva, fianza, config, lang, ruta, moneda, params, version, signature, urlpago, colorboton }) => {
    const { t } = useTranslation();

    return (
        <div className="about-us content-area-7" style={{ marginTop: "100px" }}>
            <div className="container">
                <div className="mt-4 row">
                    <div className="col-lg-12 align-self-center mt-30">
                        <div className="mb-3 row">
                            <div className="col-md-6">
                                <h3 className="mb-3"> {t("Querido") + " " + datoscliente.nombre + " " + datoscliente.apellidos}</h3>
                                <h4 className="mb-3"> {t("Sus datos de reserva son")}: </h4>
                                <div className="row">
                                    <div className="mb-3 col-md-6"><strong>{t("Propiedad")}:</strong></div>
                                    <div className="mb-3 col-md-6">{datospropiedad.nombre}</div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6"><strong>{t("Check-in")}:</strong></div>
                                    <div className="mb-3 col-md-6">{reserva.inicio}</div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6"><strong>{t("Check-out")}:</strong></div>
                                    <div className="mb-3 col-md-6">{reserva.final}</div>
                                </div>

                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                        <strong>{t("A Preautorizar ahora")}:</strong>
                                    </div>
                                    <div className="mb-3 col-md-6">{fianza + " " + moneda} </div>
                                </div>
                                <div id="paymentOption" className="mt-40 payment-option-wrapper">

                                    <div id="payButton">
                                        <div id="paymentsCreditCard" className="payment-option-form">
                                            <div className="inner">
                                                <h4 className="mb-20" style={{ fontWeight: "300" }}>
                                                    {t("Su pago a realizar") + " "}
                                                    <span className="totalprice">
                                                        {fianza + " " + moneda}
                                                    </span>
                                                </h4>
                                                <p>
                                                    {t("Esta cantidad será retenida en su tarjeta de crédito y se le será liberada en unos dias")}
                                                </p>
                                            </div>
                                        </div>
                                        <br />
                                        <form name="frm" action={urlpago} method="POST" target="_blank" className="pt-20">
                                            <input type="hidden" name="Ds_SignatureVersion" value={version} />
                                            <input type="hidden" name="Ds_MerchantParameters" value={params} />
                                            <input type="hidden" name="Ds_Signature" value={signature} />
                                            <button type="submit" className="btn btn-primary btn-lg btn-icon botoncolor" style={{ backgroundColor: colorboton, borderColor: colorboton }}>
                                                {t("Confirmar") + " " + fianza + " " + moneda}
                                                <span className="icon">
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                </span>
                                            </button>
                                        </form>
                                    </div>
                                </div>


                            </div>

                            <div className="col-md-6">
                                {
                                    datospropiedad.foto_principal.length != 0
                                        ? <img src={ruta + "/propiedades/" + datospropiedad.id + "/fotos/web/" + datospropiedad.foto_principal[0].nombre} alt="--" title="" className="mt-1 imagereservation w100" />
                                        : <img src="/images/web/bedloop-nofoto.png" alt="--" title="" className="mt-1 imagereservation w100" />
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TextosPreautorizar;





