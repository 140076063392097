import React from "react";
import { useTranslation } from 'react-i18next';
import WidgetPropiedad from "../Compartido/WidgetPropiedad";

const PropiedadesDestino = ({ destino, lang, ruta, propiedadesdestino, config }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    {/* *********** */ }

    return (
        <div className="pt-5">
            <div className="destacats">
                {propiedadesdestino.map((propiedad, index) => (
                    <WidgetPropiedad
                        propiedad={propiedad}
                        ruta={ruta}
                        config={config}
                        lang={lang}
                        index={"d" + index}
                        key={"d" + index}
                    />
                ))}
            </div>
        </div>
    );
};

export default PropiedadesDestino;
