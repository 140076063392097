import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser';

const ListaCaracteristicas = ({ config }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="text-center row">
                <div className="col-md-8 offset-md-2">
                    <h2>{config.nombre_comercial}</h2>
                    <p className="mb-5 font-italic">{t("¿Por_qué_reservar_con_nosotros?")}</p>
                </div>
            </div>
            <div className="row gx-lg-5">

                <div className="mb-5 col-lg-6 col-xxl-4">
                    <div className="bg-white border-0 shadow card bg-light h-100 br15">
                        <div className="p-4 pt-0 text-center card-body p-lg-5 pt-lg-0">
                            <div className="mb-5 caracteristica">
                                <i className={"fa-solid fa-circle-1 fa-2x"}></i>
                            </div>
                            <h2 className="fs-4 fw-bold">{t("Experiencias Únicas en Goletas, Mini Cruceros y Catamaranes")}</h2>
                            <div className="mb-0">
                                <p>
                                    {ReactHtmlParser(t("Navega por los mares más impresionantes del mundo en nuestras goletas, mini cruceros y catamaranes. Descubre rincones paradisíacos en Turquía, Grecia, Croacia, Italia y más, combinando lujo, aventura y cultura en un solo viaje."))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-5 col-lg-6 col-xxl-4">
                    <div className="bg-white border-0 shadow card bg-light h-100 br15">
                        <div className="p-4 pt-0 text-center card-body p-lg-5 pt-lg-0">
                            <div className="mb-5 caracteristica">
                                <i className={"fa-solid fa-circle-2 fa-2x"}></i>
                            </div>
                            <h2 className="fs-4 fw-bold">{t("Lujo y Comodidad a un Precio Asequible")}</h2>
                            <div className="mb-0">
                                <p>{t("Disfruta de la exclusividad de un viaje en el mar sin precios desorbitados. Ofrecemos opciones para el segmento medio-alto, con una relación calidad-precio excepcional y tarifas transparentes para cada tipo de embarcación.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5 col-lg-6 col-xxl-4">
                    <div className="bg-white border-0 shadow card bg-light h-100 br15">
                        <div className="p-4 pt-0 text-center card-body p-lg-5 pt-lg-0">
                            <div className="mb-5 caracteristica">
                                <i className={"fa-solid fa-circle-3  fa-2x"}></i>
                            </div>
                            <h2 className="fs-4 fw-bold">{t("Flexibilidad con Seguro de Cancelación")}</h2>
                            <div className="mb-0">
                                <p>
                                    {ReactHtmlParser(t("Reserva con total tranquilidad gracias a nuestro seguro de cancelación. Si tus planes cambian, tienes la posibilidad de modificar o cancelar tu viaje con condiciones flexibles, sin preocupaciones ni pérdidas."))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5 col-lg-6 col-xxl-4">
                    <div className="bg-white border-0 shadow card bg-light h-100 br15">
                        <div className="p-4 pt-0 text-center card-body p-lg-5 pt-lg-0">
                            <div className="mb-5 caracteristica">
                                <i className={"fa-solid fa-circle-4 fa-2x"}></i>
                            </div>
                            <h2 className="fs-4 fw-bold">{t("Tarifas Claras y Flexibles para Pensión Completa o Media Pensión")}</h2>
                            <div className="mb-0">
                                <p>
                                    {ReactHtmlParser(t("Al solicitar una reserva, te proporcionamos el precio de la embarcación junto con las tarifas para media pensión o pensión completa, según tu preferencia. Así puedes personalizar tu experiencia y pagar solo por lo que realmente necesitas."))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5 col-lg-6 col-xxl-4">
                    <div className="bg-white border-0 shadow card bg-light h-100 br15">
                        <div className="p-4 pt-0 text-center card-body p-lg-5 pt-lg-0">
                            <div className="mb-5 caracteristica">
                                <i className={"fa-solid fa-circle-5 fa-2x"}></i>
                            </div>
                            <h2 className="fs-4 fw-bold">{t("Opciones Privadas y Compartidas")}</h2>
                            <div className="mb-0">
                                <p>
                                    {ReactHtmlParser(t("Viaja a tu manera. Elige entre una goleta o catamarán privado para disfrutar con tu grupo o una opción compartida para conocer nuevos amigos mientras exploras el Mediterráneo. ¡Ambas garantizan una experiencia inolvidable!"))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5 col-lg-6 col-xxl-4">
                    <div className="bg-white border-0 shadow card bg-light h-100 br15">
                        <div className="p-4 pt-0 text-center card-body p-lg-5 pt-lg-0">
                            <div className="mb-5 caracteristica">
                                <i className={"fa-solid fa-circle-6 fa-2x"}></i>
                            </div>
                            <h2 className="fs-4 fw-bold">{t("Atención Personalizada y Servicio de Primera")}</h2>
                            <div className="mb-0">
                                <p>
                                    {ReactHtmlParser(t("Desde el primer contacto hasta el final de tu viaje, nuestro equipo está disponible para asesorarte en cada detalle. Nos aseguramos de que tu experiencia a bordo sea perfecta y adaptada a tus necesidades, con la mejor tripulación y embarcaciones de alta calidad."))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

const Caracteristicas = ({ config }) => {

    return (
        <div className="pt-4 pb-5 mb-5">
            <Container>
                <ListaCaracteristicas
                    config={config}
                />
            </Container>
        </div>
    );
}

export default Caracteristicas;
