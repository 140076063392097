import { React } from "react";
import { useTranslation } from 'react-i18next';
import WidgetPropiedad from "../Compartido/WidgetPropiedad";


const ListaDestacados = ({ ruta, destacadas, config, lang }) => {

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const { t } = useTranslation();

    let results = [];

    {/* ************* */ }

    return (
        <>
            {destacadas.forEach((propiedad, index) => {
                results = results.concat(
                    <WidgetPropiedad
                        propiedad={propiedad}
                        ruta={ruta}
                        config={config}
                        lang={lang}
                        index={index}
                        key={index}
                    />
                )
            })}

            {results}
        </>
    );
};

const Destacados = ({ destinos, ruta, destacadas, elementosweb, config, lang }) => {

    const { t } = useTranslation();

    return (

        <div className="pt-5 fons">
            {elementosweb.subtitulo_buscador && <h2 className="mb-5 text-center">{elementosweb.subtitulo_buscador[lang]}</h2>}
            {elementosweb.subtitulo_propiedades && <h3 className="text-center">{elementosweb.subtitulo_propiedades[lang]}</h3>}

            <div className="destacats">
                <ListaDestacados
                    ruta={ruta}
                    destacadas={destacadas}
                    config={config}
                    lang={lang}
                />
            </div>
        </div>
    );
};

export default Destacados;
