import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

const CustomSelect = (props) => {
    const { t } = useTranslation();

    const [showOptionList, setShowOptionList] = useState(false);
    const [optionsList] = useState(props.optionsList);
    const [nameInput] = useState(props.nameInput);
    const [realInput, setRealInput] = useState(props.realInput);


    const defecte = useRef(null);

    // Aques mètode maneja el clic que passa fora del
    // select text i l'area de llista
    const handleClickOutside = (e) => {
        if (
            !e.target.classList.contains("custom-select-option") &&
            !e.target.classList.contains("selected-text")
        ) {
            setShowOptionList(false);
        }
    };

    // Maneja la llista d'opcions
    const handleListDisplay = () => {
        const newShowState = !showOptionList;
        setShowOptionList(newShowState);
        if (newShowState && props.onOpen) {
            props.onOpen();
        } else if (!newShowState && props.onClose) {
            props.onClose();
        }
    };


    // Maneja posar el nom al select area
    // i la llista a la seleccío
    const handleOptionClick = (e) => {
        defecte.current.textContent = e.target.getAttribute("data-name");
        setShowOptionList(false);
        setRealInput(e.target.getAttribute("data-value"));

        if (props.funcion) {
            props.funcion(e.target.getAttribute("data-value"));
        }
        props.onClose();
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    });

    return (

        <div className="custom-select-container">
            <div
                className={showOptionList ? "selected-text active noborder" : "selected-text noborder"}
                onClick={handleListDisplay} ref={defecte}
            >
                {t(props.defaultText)}
            </div>
            {showOptionList && (
                <ul className="select-options">
                    {optionsList.map(option => {
                        return (
                            <li
                                className="custom-select-option"
                                data-name={t(option.name)}
                                data-value={option.value}
                                key={option.id}
                                onClick={handleOptionClick}
                            >
                                {t(option.name)}
                            </li>
                        );
                    })}
                </ul>
            )}
            <input type="hidden" id={nameInput} name={nameInput} defaultValue={realInput} />
        </div>
    )
}

export default CustomSelect;
