import { useTranslation } from 'react-i18next';
import BotonWhatsapp from './BotonWhatsapp';


const Footer = ({ lang, websgrupo }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const toTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <>
            <div className="widgets_wrapper">
                <div className="container">
                    <div className="column one-second">
                        <aside className="widget_text widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <h4>{t("Si tienes una pregunta, llámanos")}</h4>
                                <h3>
                                    <i className="icon-call callColor"></i>
                                    +34 93 315 27 96&nbsp;
                                </h3>

                                <p>
                                    {t("De lunes a viernes, 09h to 13h")}
                                </p>
                            </div>
                        </aside>
                    </div>

                    <div className="column one-fourth">
                        <aside className="widget_text widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <h6>{t("Enlaces útiles")}</h6>
                                <p>
                                    <a href={"/" + preidioma + t("legal")}>{t("Aviso Legal")}</a><br />
                                    <a href={"/" + preidioma + t("politica-de-privacidad")}>{t("Política de privacidad")}</a><br />
                                    <a href={"/" + preidioma + t("politica-redes")}>{t("Política de redes sociales")}</a><br />
                                    <a href={"/" + preidioma + t("cookies")}>{t("Política de Cookies")}</a><br />
                                    <a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a><br />
                                    <a href="#" className="pdcc-open-modal">{t("Panel de Cookies")}</a>
                                </p>
                            </div>
                        </aside>
                    </div>

                    <div className="column one-fourth">
                        <aside className="widget_text widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <h6>{t("DIRECCIÓN DE CONTACTO")}</h6>
                                <p>Apartur <br />Carrer Madrazo, 27, 2º5ª,<br />08006 <br /> Barcelona</p>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>

            <div className="footer_copy">
                <div className="container">
                    <div className="column one">
                        <a id="back_to_top" className="footer_button" onClick={toTop} style={{ cursor: "pointer" }}><i className="fa-solid fa-angle-up"></i></a>
                        <div className="copyright">
                            &copy; {new Date().getFullYear()} Apartur - {t("Hecho con")}  <i className="fa-solid fa-heart" style={{ color: "red" }}></i> {t("en")} <a target="_blank" rel="nofollow" href="https://www.bedloop.com?utm_source=sponsor&utm_medium=web&utm_campaign=apartur&utm_id=apartur" style={{ textDecoration: "none" }}>BedLoop</a>
                        </div>
                    </div>
                </div>
            </div>

            <BotonWhatsapp
                websgrupo={websgrupo}
            />
        </>
    );
}

export default Footer;
