import React from 'react';
import { useTranslation } from 'react-i18next';
import EstrellasPropiedad from './EstrellasPropiedad';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const WidgetPropiedad = ({ propiedad, ruta, config, lang, index, adultos = "", ninos = "", bebes = "", llegada = "", salida = "", moneda }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="destacats-propietat destacats-ob" key={"d" + index}>
            <a href={"/" + preidioma + t("embarcaciones") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica}>
                {
                    propiedad.foto_principal.length != 0
                        ? <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + propiedad.foto_principal[0].nombre} alt={propiedad.foto_principal[0].texto_alt ? propiedad.foto_principal[0].texto_alt[lang] : ""} title={propiedad.foto_principal[0].texto_title ? propiedad.foto_principal[0].texto_title[lang] : ""} />
                        : <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src="/images/web/bedloop-nofoto.png" alt="" title="" />
                }
            </a>
            <div className="destacats-text">
                <span className="destacats-icones-propietat" style={{ color: "" + config.color_corporativo_web + "" }}>
                    <i className="fa-solid fa-ruler-triangle"></i> {propiedad['metros_cuadrados']}m | <i className="fa-solid fa-bed"></i> {propiedad['num_habitaciones']} | <i className="fa-solid fa-users"></i> {propiedad.capacidad_maxima}
                </span>
                <span className="destacats-titol-propietat">
                    <a href={"/" + preidioma + t("embarcaciones") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica}>
                        {propiedad.nombre}
                    </a>
                </span>
                <span className="destacats-ciutat-propietat"> {propiedad.ciudad}</span>
                <EstrellasPropiedad
                    propiedad={propiedad}
                />
                {adultos && llegada && salida && propiedad.by_request == 0
                    ?
                    <a href={"/" + preidioma + t("embarcaciones") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica + "?adultos=" + adultos + "&ninos=" + ninos + "&bebes=" + bebes + "&llegada=" + llegada + "&salida=" + salida} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                        <i className="fa-duotone fa-calendar-check"></i> {t("Desde") + " " + (propiedad.precio / 100).toFixed(2) + moneda}
                    </a>
                    :
                    adultos && llegada && salida && propiedad.by_request == 1
                        ?
                        <a href={"/" + preidioma + t("embarcaciones") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica + "?adultos=" + adultos + "&ninos=" + ninos + "&bebes=" + bebes + "&llegada=" + llegada + "&salida=" + salida} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                            <i className="fa-duotone fa-calendar-check"></i> {t("Petición")}
                        </a>
                        :
                        propiedad.by_request == 1
                            ?
                            <a href={"/" + preidioma + t("embarcaciones") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                                {t("Petición")}
                            </a>
                            :
                            <a href={"/" + preidioma + t("embarcaciones") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                                {t("Reservar")}
                            </a>
                }
            </div>
        </div>
    );
};
export default WidgetPropiedad;
