import React from 'react';
import { useTranslation } from 'react-i18next';

const BotonWhatsapp = ({ websgrupo }) => {

    const { t } = useTranslation();

    const handleClick = () => {
        window.open('https://api.whatsapp.com/send?phone=' + websgrupo.numero_whatsapp, '_blank');
    };

    return (
        (websgrupo.numero_whatsapp != null && websgrupo.numero_whatsapp != "") ?
            <button className="floating-button" onClick={handleClick}>
                <i className="fa-brands fa-whatsapp"></i>
            </button>
            :
            null
    );
};

export default BotonWhatsapp;
