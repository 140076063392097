import { React } from "react";
import { Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextosSobreNosotros = ({ sobrenosotros, personas, ruta }) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

    if (sobrenosotros.imagen_cabecera) {
        imagen = ruta + "/" + sobrenosotros.imagen_cabecera;
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="imatge-banner" title={t("Sobre_Nosotros")} style={{ backgroundImage: "url('" + imagen + "')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover" }}></div>

                        <h1 className="mb-5 noticia-individual">{t("Sobre_Nosotros")}</h1>
                        <div className="mt-5 mb-5 noticia-individual">
                            <div className="noticia-individual-descripcio-llarga">
                                {ReactHtmlParser(sobrenosotros.ladesc)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                personas.length > 0 ?
                    <div className="container pt-5 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center section-title-2 alt">
                                    <h2>{t("Equipo")}</h2>
                                    <p>{t("Conozca a los artífices de esta iniciativa")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-6 destacats">

                            {personas.map((equipo, index) => (
                                <div className="destacats-equip" key={index} >
                                    {
                                        equipo.imagen ?
                                            <img className="destacats-imatge-equip" src={ruta + "/quienesSomos/" + equipo.id + "/" + equipo.imagen} alt={'persona' + index} title={'persona' + index} />
                                            :
                                            <img className="destacats-imatge-equip" src='/images/web/bedloop-nofoto.png' alt={'persona' + index} title={'persona' + index} />
                                    }
                                    <div className="destacats-text-equipo">
                                        <h5 className="destacats-titol-propietat">{equipo.nombre} {equipo.apellidos}</h5>
                                        <div className="destacats-text-propietat" dangerouslySetInnerHTML={{ __html: equipo.ladesc }}></div>
                                    </div>

                                </div>
                            ))}

                        </div>
                    </div>
                    :
                    null
            }
        </>
    );
}


export default TextosSobreNosotros;
