import React from 'react';

const EstrellasPropiedad = ({ propiedad }) => {
    // Verificamos si hay comentarios
    if (propiedad.comentarios.length > 0) {
        // Calculamos la suma de estrellas
        const sumaEstrellas = propiedad.comentarios.reduce((acumulador, comentario) => acumulador + comentario.estrellas, 0);
        // Calculamos la media
        const mediaEstrellas = sumaEstrellas / propiedad.comentarios.length;

        // Redondeamos la media a un número entero
        const estrellasCompletas = Math.floor(mediaEstrellas);
        const tieneMediaEstrella = mediaEstrellas % 1 >= 0.5; // Verificamos si hay media estrella

        return (
            <div className="destacats-comentarios">
                {/* Renderizamos las estrellas completas */}
                {[...Array(estrellasCompletas)].map((_, index) => (
                    <i key={index} className="fa-solid fa-star color-onboarding"></i>
                ))}
                {/* Renderizamos una media estrella si corresponde */}
                {tieneMediaEstrella && <i className="fa-solid fa-star-half-alt color-onboarding"></i>}
                {/* Opcional: Renderizamos estrellas vacías si quieres mostrar un total de 5 */}
                {[...Array(5 - estrellasCompletas - (tieneMediaEstrella ? 1 : 0))].map((_, index) => (
                    <i key={index + estrellasCompletas + (tieneMediaEstrella ? 1 : 0)} className="fa-regular fa-star color-onboarding"></i>
                ))}
            </div>
        );
    }

    return null; // O puedes retornar un mensaje alternativo si no hay comentarios
};

export default EstrellasPropiedad;
