import { React, useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const DatosReserva = ({ propiedad, precios, llegada, salida, difdias, adultos, ninos, bebes, lang, ruta, config, tpvs, paypal, transferencia, stripe, paycomet, moneda, numpropiedades, websgrupo }) => {

    let { t } = useTranslation();

    let resprices = [];
    let resextras = [];

    let precioBase = 0;
    let cuentaNoches = 0;
    let sumaextras = 0;

    let prepago = 0;

    let vectornumpropiedades = []

    for (let i = 0; i < numpropiedades; i++) {
        if (i === 0)
            vectornumpropiedades = vectornumpropiedades.concat(<option value={i + 1} key={"vp" + i} data-key={"vp" + i}>{(i + 1) + " " + t("propiedad")} </option>)
        else
            vectornumpropiedades = vectornumpropiedades.concat(<option value={i + 1} key={"vp" + i} data-key={"tarifa" + index}>{(i + 1) + " " + t("propiedades")} </option>)
    }

    {/* *************** */ }

    const [idTarifaRef, setIdTarifaRef] = useState(null);
    const [priceRef, setPriceRef] = useState(null);
    const [prepagoRef, setPrepagoRef] = useState(null);
    const cantApartRef = useRef(1);
    const sumaExtrasRef = useRef(0);
    const sumaExtras2Ref = useRef(0);
    const feesRef = useRef(0);
    const pextraRef = useRef(null);
    const preciosDiariosRef = useRef(null);

    const nombrecRef = useRef(null);
    const apellidoscRef = useRef(null);
    const emailcRef = useRef(null);
    const telefonocRef = useRef(null);
    const documentocRef = useRef(null);
    const aceptacondicionesRef = useRef(null);

    const codigodescuentoprecioRef = useRef(null);
    const codigodescuentoporcentajeRef = useRef(null);

    const formularioreservaRef = useRef(null);

    const datosreservaactualesRef = useRef(null);

    const numpropiedades0Ref = useRef(null);

    const [showHayExtraCat, setShowHayExtraCat] = useState("none");
    const pextracatRef = useRef(null);



    {/* *************** */ }

    const roundN = (value, decimals) => {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    }

    const handleChangeTarifa = () => {

        var numreservas = cantApartRef.current.value;

        var precioEstancia = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").value * numreservas);
        var precioExtras = (Number(sumaExtrasRef.current.value) + Number(sumaExtras2Ref.current.value)) * numreservas;
        var precioTotal = Number(precioEstancia) + Number(precioExtras);
        var prepago = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-prepago'));

        var totalextratipo2 = 0;

        var extras = document.getElementsByClassName("extra");
        for (var i = 0; i < extras.length; i++) {
            var unextra = extras[i];
            if (unextra.getAttribute('data-tipoextra') === 2) {
                var porciento = unextra.getAttribute('data-precio');
                var precioextra = (precioEstancia * porciento) / 100;
                var valorcampo = unextra.value;
                if (valorcampo === 1) {
                    totalextratipo2 += precioextra;
                }
            }
        }
        sumaExtras2Ref.current.value = totalextratipo2;

        if (!isNaN(sumaExtrasRef.current.value)) {
            var nuevoPrecioExtras = roundN(sumaExtrasRef.current.value, 2) * numreservas;
            feesRef.current.value = nuevoPrecioExtras;
        }
        else {
            var nuevoPrecioExtras = 0;
            feesRef.current.value = 0;
        }

        if (!isNaN(sumaExtras2Ref.current.value)) {
            var nuevoPrecioExtrasTipo2 = roundN(sumaExtras2Ref.current.value, 2) * numreservas;
            var fees = roundN(feesRef.current.value, 2);

            feesRef.current.value = parseFloat(nuevoPrecioExtrasTipo2) + parseFloat(fees);
            pextraRef.current.textContent = roundN(parseFloat(fees) + parseFloat(nuevoPrecioExtrasTipo2), 2);
        }
        else {
            var nuevoPrecioExtrasTipo2 = 0;
        }

        var nuevoPrecioTotal = roundN(parseFloat(precioEstancia) + parseFloat(nuevoPrecioExtras) + parseFloat(nuevoPrecioExtrasTipo2), 2);

        let incremento = 0;
        let tipoIncremento = 0;

        {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").value);*/ }
        {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").getAttribute('data-incremento'));*/ }
        {/*console.log(document.querySelector("input[type='radio'][name='payments']:checked").getAttribute("data-tipoincremento"));*/ }

        incremento = document.querySelector("input[type='radio'][name='payments']:checked").getAttribute('data-incremento');
        tipoIncremento = document.querySelector("input[type='radio'][name='payments']:checked").getAttribute("data-tipoincremento");

        let incremento2 = incremento;
        if (tipoIncremento === 1) {
            incremento2 = roundN(Number(nuevoPrecioTotal) * ((Number(incremento) / 100)), 2);
        }

        if (incremento > 0) {
            let incremento_a_mostrar = 0;
            if (tipoIncremento === 0) {
                nuevoPrecioTotal = roundN(Number(nuevoPrecioTotal) + Number(incremento), 2);
                incremento_a_mostrar = roundN(Number(incremento), 2);
            } else {
                incremento_a_mostrar = roundN(Number(nuevoPrecioTotal) * (Number(incremento) / 100), 2);
                nuevoPrecioTotal = roundN(Number(nuevoPrecioTotal) * (1 + (Number(incremento) / 100)), 2);
            }
            setShowHayExtraCat("flex");
            pextracatRef.current.textContent = incremento_a_mostrar;
        }
        else {
            setShowHayExtraCat("none");
            pextracatRef.current.textContent = 0;
        }

        var nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
        var nuevoResto = nuevoPrecioTotal - nuevoPrepago;

        var codigoDescuentoPrecio = codigodescuentoprecioRef.current.value;
        var codigoDescuentoPorcentaje = codigodescuentoporcentajeRef.current.value;

        if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
            var precioSinDescuento = nuevoPrecioTotal;
        }
        else {
            var precioSinDescuento = 0.00;
        }
        if (codigoDescuentoPrecio > 0) {
            nuevoPrecioTotal = (nuevoPrecioTotal - codigoDescuentoPrecio).toFixed(2);
            nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
            nuevoResto = nuevoPrecioTotal - nuevoPrepago;
        }
        if (codigoDescuentoPorcentaje > 0) {
            nuevoPrecioTotal = (nuevoPrecioTotal - (precioEstancia * (codigoDescuentoPorcentaje / 100))).toFixed(2);
            nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
            nuevoResto = nuevoPrecioTotal - nuevoPrepago;
        }

        document.querySelectorAll(".pricebase").forEach(function (elemento) {
            elemento.textContent = roundN(Number(precioEstancia), 2) + " " + moneda;
        });

        document.querySelectorAll(".totalprice").forEach(function (elemento) {
            elemento.textContent = roundN(Number(nuevoPrecioTotal), 2) + " " + moneda;
        });

        document.querySelectorAll(".prepago").forEach(function (elemento) {
            elemento.textContent = roundN(Number(nuevoPrepago), 2)
        });

        document.querySelectorAll(".feesincluded").forEach(function (elemento) {
            elemento.textContent = roundN(Number(incremento2), 2)
        });

        pextraRef.current.textContent = roundN(Number(precioExtras), 2);

        if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
            if (codigoDescuentoPrecio > 0) {
                document.querySelector(".codigo-carro").textContent = "-" + parseFloat(codigoDescuentoPrecio).toFixed(2);
            }
            if (codigoDescuentoPorcentaje > 0) {
                document.querySelector(".codigo-carro").textContent = "-" + (precioEstancia * (codigoDescuentoPorcentaje / 100)).toFixed(2);
            }
        }

        setIdTarifaRef(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa"));

        //idTarifaRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa");

        setPriceRef(btoa(precioEstancia));
        //priceRef.current.value = btoa(precioEstancia);
        setPrepagoRef(prepago);
        //prepagoRef.current.value = prepago;
        preciosDiariosRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-preciosdiarios');

        datosreservaactualesRef.current.setAttribute("data-precioestancia", (Number(precioEstancia), 2));
        datosreservaactualesRef.current.setAttribute("data-totalprice", roundN(Number(precioTotal), 2));
        datosreservaactualesRef.current.setAttribute("data-prepago", roundN(Number(nuevoPrepago), 2));

        if (prepago === 0) {
            if (document.getElementById("payments1"))
                document.getElementById("paymens1").required = false;
            if (document.getElementById("payments2"))
                document.getElementById("payments2").required = false;
        }
        if (prepago > 0) {
            if (document.getElementById("payments1"))
                document.getElementById("payments1").required = true;
            if (document.getElementById("payments2"))
                document.getElementById("payments2").required = true;
            if (document.getElementById("payments5"))
                document.getElementById("payments5").required = true;
            if (document.getElementById("payments6"))
                document.getElementById("payments6").required = true;
        }

    }

    const handleChangeExtra = (param) => {

        var elemento = document.querySelector("select[name='" + param + "']");

        var valor = elemento.value;
        var precioExtra = parseFloat(elemento.getAttribute('data-precio')) / 100;
        var baseExtras = parseFloat(sumaExtrasRef.current.value);
        var baseExtrasTipo2 = parseFloat(sumaExtras2Ref.current.value);

        var precioBase = datosreservaactualesRef.current.getAttribute("data-precioestancia");
        var precioExtraTarifa = datosreservaactualesRef.current.getAttribute("data-precioextratarifa");

        precioBase = precioBase - precioExtraTarifa;

        var tipoextra = parseInt(elemento.getAttribute('data-tipoextra'));

        var porreserva = parseInt(elemento.getAttribute('data-porreserva'));
        var porpersona = parseInt(elemento.getAttribute('data-porpersona'));
        var pordia = parseInt(elemento.getAttribute('data-pordia'));
        var pormes = parseInt(elemento.getAttribute('data-pormes'));

        var personas = parseInt(elemento.getAttribute('data-numpersonas'));
        var difdias = parseInt(elemento.getAttribute('data-numdias'));

        if (porreserva) {
            precioExtra = precioExtra * 1;
        }
        if (porpersona) {
            precioExtra = precioExtra * personas;
        }
        if (pordia) {
            precioExtra = precioExtra * difdias;
        }
        if (pormes) {
            precioExtra = precioExtra * (difdias / 30);
        }

        if (tipoextra === 0) {
            if (valor === "0") {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) - Number(precioExtra), 2);
            }

            else {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) + Number(precioExtra), 2);
            }
        }
        else if (tipoextra === 1) {
            if (valor === "0") {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) - Number(precioExtra), 2);
            }

            else {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) + Number(precioExtra), 2);
            }
        }
        else if (tipoextra === 2) {
            precioExtra = (precioBase * precioExtra) / 100;

            if (valor === "0") {
                sumaExtras2Ref.current.value = roundN(Number(baseExtrasTipo2) - Number(precioExtra), 2);

            }
            else {
                sumaExtras2Ref.current.value = roundN(Number(baseExtrasTipo2) + Number(precioExtra), 2);
            }
        }

        pextraRef.current.textContent = (parseFloat(sumaExtrasRef.current.value) + parseFloat(sumaExtras2Ref.current.value)) * Number(cantApartRef.current.value);

        handleChangeTarifa();

    }

    const handleChangeNumPropiedades = () => {
        cantApartRef.current.value = numpropiedades0Ref.current.value;
        handleChangeTarifa();
    };

    const handleChangeDiscount = () => {

        var el = document.querySelector('#nombrecodigo');

        let parameters = location.search.substring(1);
        var incrementotpv = 0;

        if (document.querySelector("input[type='radio'][name='payments1']") && document.querySelector("input[type='radio'][name='tarifa1']:checked")) {
            incrementotpv = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").attr("data-incremento"));
        }
        if (document.querySelector("#payments2") && document.querySelector("#payments2").checked) {
            incrementotpv = Number(document.querySelector("#payments2").getAttribute("data-incremento"));
        }
        if (document.querySelector("#payments5") && document.querySelector("#payments5").checked) {
            incrementotpv = Number(document.querySelector("#payments5").getAttribute("data-incremento"));
        }

        var precioEstancia = Number(document.querySelector(".tarifa1:checked").value);
        var precioExtras = Number(document.querySelector("#datosreserva").getAttribute('data-extras0'));
        var precioTotal = Number(Number(precioEstancia) + Number(precioExtras));
        var incremento = roundN((precioTotal * (incrementotpv / 100)), 2);
        var preciotpv = roundN((Number(precioTotal) + Number(incremento)), 2);
        var pagoaCuenta = roundN(preciotpv * (Number(document.querySelector(".tarifa1:checked").getAttribute('data-prepago')) / 100), 2);

        const result = axios.post('check-cliente-reserva', {
            idCodigoDescuento: el.value,
            parameters: parameters,
            dataType: 'JSON'
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.status === "success") {
                    var descuento;
                    if (solucion.tipo === 'moneda') {
                        descuento = parseFloat(solucion.descuento).toFixed(2);
                        codigodescuentoprecioRef.current.value = descuento;
                    } else {
                        descuento = (precioEstancia * (solucion.descuento / 100)).toFixed(2);
                        codigodescuentoporcentajeRef.current.value = solucion.descuento;
                    }

                    pagoaCuenta = roundN((preciotpv - descuento) * (Number(document.querySelector(".tarifa1:checked").getAttribute('data-prepago')) / 100), 2);

                    var resto = Number(preciotpv - descuento - pagoaCuenta);

                    document.querySelector('.codigo-carro').innerHTML = "<span>-" + descuento + "</span> ";
                    document.querySelector('.muestra-codigo-carro').style.display = "";
                    document.querySelector('#codigodescuento div').innerHTML = '<div class="ws-errorbox" style="display: block;" id="errorbox-1"><p class="ws-errormessage c008">Codigo de descuento valido.</p></div>';
                    document.querySelector('.prepago').textContent = roundN(Number(pagoaCuenta), 2) + " " + moneda;
                    handleChangeTarifa();
                }
                else {
                    codigodescuentoprecioRef.current.value = "";
                    codigodescuentoporcentajeRef.current.value = "";
                    var resto = preciotpv - pagoaCuenta;
                    document.querySelector('.muestra-codigo-carro').style.display = "none";
                    document.querySelector('.codigo-carro').textContent = '';
                    document.querySelector('#codigodescuento div').innerHTML = '<div class="ws-errorbox" style="display: block;" id="errorbox-1"><p class="ws-errormessage">Codigo de descuento no valido.</p></div>';
                    document.querySelector('.prepago').textContent = roundN(Number(pagoaCuenta), 2) + " " + moneda;
                    handleChangeTarifa();
                }
            })
    };

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    const handleSubmit = () => {
        if (nombrecRef.current.value && nombrecRef.current.value.replace(/\s+/g, '').length > 0 &&
            apellidoscRef.current.value && apellidoscRef.current.value.replace(/\s+/g, '').length > 0 &&
            emailcRef.current.value && emailcRef.current.value.replace(/\s+/g, '').length > 0 &&
            telefonocRef.current.value && telefonocRef.current.value.replace(/\s+/g, '').length > 0 &&
            documentocRef.current.value && documentocRef.current.value.replace(/\s+/g, '').length > 0 &&
            aceptacondicionesRef.current.checked && emailcRef.current.value.includes('@'))
            formularioreservaRef.current.submit();
        else {
            handleShowModal()
        }
    }

    const handleBack = () => {
        window.history.back();
    }

    useEffect(() => {

        if (document.getElementById("payments2")) {
            document.getElementById("payments2").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments5")) {
            document.getElementById("payments5").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments3")) {
            document.getElementById("payments3").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments4")) {
            document.getElementById("payments4").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.querySelector(".tpv")) {
            document.querySelector(".tpv").click(function () {
                handleChangeTarifa();
            });
        }

        if (document.querySelector(".bizum")) {
            document.querySelector(".bizum").click(function () {
                handleChangeTarifa();
            });
        }
    }, []);

    const [showMore, setshowMore] = useState(-1);

    let lostpvs = [];

    {/* *************** */ }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-7 col-lg-8">

                    { /*-- ********************** TARIFES  ********************************* */}
                    <div className="metro-box-wrapper">
                        <h3>{t("Tarifas")}</h3>
                        <div className="linia"></div>
                        <div className="content">
                            {precios.map((unprecio, index) => {

                                let acumulado = unprecio.suma / 100;
                                let checked = ""
                                let preciosdiarios = unprecio.calendario_sin_otas
                                for (let i = 0; i < preciosdiarios.length; i++) {
                                    if (index === 0) cuentaNoches++;
                                }
                                if (index === 0) {
                                    precioBase = acumulado.toFixed(2);
                                }

                                acumulado = acumulado.toFixed(2);
                                resprices = resprices.concat(
                                    <div className="tarifes" key={"tarifa" + index} data-key={"tarifa" + index}>
                                        <div className="content">
                                            <div className="mt-radio-list m15_1_1_1">
                                                <label className="mt-radio radiotarifa">
                                                    {index === 0 ?
                                                        <input type="radio" name="tarifa1" className="tarifa1" defaultChecked id="tarifa" defaultValue={acumulado} data-preciosdiarios="" data-prepago={unprecio.info_tarifa.politica.prepago} data-idpolitica={unprecio.info_tarifa.politica_id} data-tarifa={unprecio.id} data-texto={unprecio.info_tarifa.politica.descripcion[lang]} onClick={() => handleChangeTarifa()} />
                                                        :
                                                        <input type="radio" name="tarifa1" className="tarifa1" id="tarifa" defaultValue={acumulado} data-preciosdiarios="" data-prepago={unprecio.info_tarifa.politica.prepago} data-idpolitica={unprecio.info_tarifa.politica_id} data-tarifa={unprecio.id} data-texto={unprecio.info_tarifa.politica.descripcion[lang]} onClick={() => handleChangeTarifa()} />
                                                    }
                                                    <strong>{" " + acumulado + " " + moneda}  </strong> - {" " + unprecio.info_tarifa.nombre_web}
                                                </label>
                                                <p className="small ms-3" onClick={() => { showMore == index ? setshowMore(-1) : setshowMore(index) }}>
                                                    <span className="small bolder">{unprecio.info_tarifa.politica.nombre[lang]}</span> <span className="cursor-pointer small bolder fst-italic">({showMore == index ? t('Esconder') : t('Mostrar')} {t('Descripción')})</span>
                                                </p>
                                                {showMore === index && (
                                                    <>
                                                        <hr className="mt-0" />
                                                        <p className="small" dangerouslySetInnerHTML={{ __html: unprecio.info_tarifa.politica.descripcion[lang] }}></p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            {resprices}
                        </div>
                    </div>
                    { /*-- ********************** FINAL TARIFES  ********************************* */}

                    <form method="get" action="/resumen" id="formularioreserva" ref={formularioreservaRef}>
                        <input type="hidden" name="idpropiedad" id="idPropiedad" defaultValue={propiedad.id} />
                        <input type="hidden" name="llegada" defaultValue={llegada} id="llegada" />
                        <input type="hidden" name="salida" defaultValue={salida} id="salida" />
                        <input type="hidden" name="adultos" defaultValue={adultos} />
                        <input type="hidden" name="ninos" defaultValue={ninos} />
                        <input type="hidden" name="bebes" defaultValue={bebes} />
                        <input type="hidden" name="price" id="price" defaultValue={priceRef} />
                        <input type="hidden" name="discount" id="discount" defaultValue="0" />
                        <input type="hidden" name="fees" id="fees" defaultValue={propiedad.fees / 100} ref={feesRef} />
                        <input type="hidden" name="idusuario" id="user" defaultValue="" />
                        <input type="hidden" name="difdias" defaultValue={difdias} />

                        <input type="hidden" name="prepago" id="prepago" defaultValue={prepagoRef} />
                        <input type="hidden" name="solorequest" id="solorequest" defaultValue="0" />
                        <input type="hidden" name="idTarifa" id="idTarifa" defaultValue={idTarifaRef} />
                        <input type="hidden" name="cantidadAparts" id="cantidadAparts" defaultValue="1" ref={cantApartRef} />
                        <input type="hidden" name="idpolitica" id="idpolitica" defaultValue={precios[0].info_tarifa.politica_id} />

                        <input type="hidden" name="preciosDiarios" id="preciosDiarios" defaultValue="" ref={preciosDiariosRef} />

                        {/* ***************** EXTRES ***************** */}
                        <div className="metro-box-wrapper">
                            <h3>{t("Extras")}</h3>
                            <div className="linia"></div>

                            <div className="content">
                                <p>{t("Mejore su estancia con los siguientes extras.")}</p>

                                <div className="form-horizontal mt-15">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-md-3"></label>
                                    </div>

                                    {propiedad.relacion_extras.map((unextra, index) => {

                                        {
                                            let baseprecio = unextra.precio;
                                            let dias_a_multiplicar = Math.min(difdias, propiedad.destino.maximo_dias);

                                            if (unextra.obligatorio) {
                                                let tipo_precio = unextra.tipo_precio;
                                                if (tipo_precio === "1") {
                                                    if (unextra.por_persona === 1) {
                                                        baseprecio = baseprecio * (Number(adultos) + Number(ninos))
                                                    }
                                                    if (unextra.por_dia_reserva === 1) {
                                                        baseprecio = baseprecio * difdias;
                                                    }
                                                }
                                                else {
                                                    baseprecio = precioBase * (baseprecio / 100);
                                                    if (unextra.por_persona === 1) {
                                                        baseprecio = baseprecio * (Number(adultos) + Number(ninos))
                                                    }
                                                    if (unextra.por_dia_reserva === 1) {
                                                        baseprecio = baseprecio * dias_a_multiplicar;
                                                    }

                                                }
                                                sumaextras += (baseprecio / 100)
                                            }

                                            resextras = resextras.concat(
                                                <div className="form-group row" key={"e" + index} data-key={"e" + index}>
                                                    <label className="col-md-4">
                                                        <i className="fa-duotone fa-check"></i> {unextra.nombre}
                                                    </label>

                                                    <div className="col-md-8 col-lg-4">
                                                        <select className="mb-0 form-control extra" name={"extra" + unextra.servicios_extra_id} disabled={unextra.obligatorio ? true : false} data-dialimpieza="" data-extraid={unextra.servicios_extra_id} data-precio={baseprecio} data-porreserva={unextra.por_reserva ? 1 : 0} data-porpersona={unextra.por_persona ? 1 : 0} data-pordia={unextra.por_dia_reserva ? 1 : 0} data-pormes={unextra.por_mes_reserva ? 1 : 0} data-numdias={difdias} data-numpersonas={Number(adultos) + Number(ninos)} data-tipoextra={unextra.tipo} defaultValue={unextra.obligatorio ? 1 : 0} onChange={() => handleChangeExtra("extra" + unextra.servicios_extra_id)}>
                                                            <option value="0">{t("Seleccione")}</option>
                                                            <option value="1">{(baseprecio / 100).toFixed(2)} {
                                                                unextra.por_reserva ?
                                                                    moneda + " / " + t("Por reserva")
                                                                    :
                                                                    unextra.por_persona ?
                                                                        moneda + " / " + t("Por persona")
                                                                        :
                                                                        unextra.por_dia_reserva ?
                                                                            moneda + " / " + t("Por dia")
                                                                            :
                                                                            unextra.por_mes_reserva ?
                                                                                moneda + " / " + t("Por mes")
                                                                                :
                                                                                ""
                                                            }
                                                            </option>
                                                        </select>

                                                        {unextra.obligatorio ?
                                                            <input type="hidden" name={"extra" + unextra.servicios_extra_id} defaultValue={unextra.obligatorio ? 1 : 0} />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}

                                    {resextras}
                                    <input type="hidden" name="baseextras" id="baseextras" defaultValue={sumaextras} ref={sumaExtrasRef} />
                                    <input type="hidden" name="baseextrastipo2" id="baseextrastipo2" defaultValue="0" ref={sumaExtras2Ref} />
                                </div>
                            </div>
                        </div>
                        {/* ***************** FINAL EXTRES ***************** */}

                        {/* ***************** CODI DESCOMPTE ***************** */}
                        <div className="metro-box-wrapper">
                            <h3>{t("Código promocional")}</h3>
                            <div className="linia"></div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <div className="form-group row">
                                        <label className="col-md-4">{t("Código")}</label>
                                        <div id="codigodescuento" className="col-md-8 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Código")} name="codigodescuento" id="nombrecodigo" onKeyUp={handleChangeDiscount} />
                                            <input type="hidden" id="codigodescuentoprecio" name="codigodescuentoprecio" defaultValue="" ref={codigodescuentoprecioRef} />
                                            <input type="hidden" id="codigodescuentoporcentaje" name="codigodescuentoporcentaje" defaultValue="" ref={codigodescuentoporcentajeRef} />
                                            <input type="hidden" name="moneda2" defaultValue={moneda} />
                                            <input type="hidden" name="abreviaturamoneda2" defaultValue="EUR" />
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ***************** FINAL CODI DESCOMPTE ***************** */}

                        {/* ***************** DADES CLIENT ***************** */}
                        <div className="metro-box-wrapper" id="userInfo">
                            <h3>{t("Información de cobro")}</h3>
                            <div className="linia"></div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <div className="form-group row">
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Nombre")} *</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Nombre")} name="firstname" required defaultValue="" id="nombrec" ref={nombrecRef} />
                                        </div>
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Apellidos")} *</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Apellidos")} name="lastname" required defaultValue="" id="apellidosc" ref={apellidoscRef} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Email")} *</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input id="emailc" type="email" className="mb-0 form-control" placeholder={t("Email")} name="email" required defaultValue="" ref={emailcRef} />
                                        </div>
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Teléfono")} *</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Teléfono")} name="phone" required defaultValue="" id="telefonoc" ref={telefonocRef} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Pasaporte/ID")}*</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input id="documentoc" type="text" className="mb-0 form-control" placeholder={t("Pasaporte/ID")} name="documentousuario" required defaultValue="" ref={documentocRef} />
                                        </div>
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Ciudad")}</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Ciudad")} name="city" defaultValue="" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="mt-5 col-md-12 text-end">{t("Todos los campos * son obligatorios")}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* ***************** FINAL DADES CLIENT ***************** */}

                        {/* ***************** TPV, PAYPAL, ETC ***************** */}
                        <div className="metro-box-wrapper tipopago" style={{ display: "block" }}>
                            <h3>{t("Información de pago")}</h3>
                            <div className="linia"></div>
                            <div className="content">
                                <div id="paymentOption" className="payment-option-wrapper">
                                    <div className="row">
                                        {config.activa_tpv === 1 && tpvs.length > 0 ?
                                            tpvs.map((untpv, index) => {
                                                lostpvs = lostpvs.concat(
                                                    <>
                                                        <div className="col-lg-6 col-md-12" key={"tpv" + index} data-key={"tpv" + index}>
                                                            <div className="radio-block font-icon-radio">
                                                                <input className="payments1 radio tpv" id="elem0" name="payments" type="radio" defaultValue={"paymentsCreditCard" + untpv.id} required="" data-incremento={untpv.markup ? untpv.markup.incremento : 0} data-idtpv={untpv.id} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0} onClick={() => handleChangeTarifa()} defaultChecked={index === 0} />
                                                                <label htmlFor="elem0">

                                                                    {untpv.nombre_comercio} <i className="fa-brands fa-cc-visa fa-xl"></i>

                                                                </label>
                                                            </div>
                                                        </div>

                                                        {untpv.bizum == 1 ?
                                                            <>
                                                                <div className='col-lg-6 col-md-12' key={"bizum" + index} data-key={"bizum" + index}>
                                                                    <div className='radio-block font-icon-radio'>
                                                                        <input className='payments1 radio tpv' id='elem2-0' name='payments' type='radio' value={"paymentsBizum" + untpv.id} required="" data-incremento={untpv.markup ? untpv.markup.incremento : 0} data-idtpv={untpv.id} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0} onClick={() => handleChangeTarifa()} />
                                                                        <label htmlFor='elem2-0'>

                                                                            {t("Bizum")} <i className="fa-duotone fa-mobile fa-xl"></i>

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                )
                                            })
                                            :
                                            lostpvs = lostpvs.concat(<></>)
                                        }
                                        {lostpvs}
                                        {config.activa_paypal === 1 && paypal ?
                                            <>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="radio-block font-icon-radio">
                                                        <input id="payments2 radio" name="payments" type="radio" className="radio" value="paymentPaypal" required="" data-incremento={paypal.markup.incremento} data-tipoincremento={paypal.markup.tipo} onClick={() => handleChangeTarifa()} defaultChecked={tpvs.length === 0} />
                                                        <label htmlFor="payments2">

                                                            {t("Paypal")} <i className="fa-brands fa-paypal fa-xl"></i>

                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {config.activa_stripe === 1 ?
                                            <>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="radio-block font-icon-radio">
                                                        <input id="payments5 radio" name="payments" type="radio" className="radio" value="paymentStripe" required="" data-incremento={stripe.markup.incremento} data-tipoincremento={stripe.markup.tipo} onClick={() => handleChangeTarifa()} defaultChecked={tpvs.length === 0 && config.activa_paypal === 0} />
                                                        <label htmlFor="payments5">

                                                            {stripe.nombre_comercio && stripe.nombre_comercio != '' ? stripe.nombre_comercio : "Stripe"} <i className="fa-brands fa-stripe fa-xl"></i>

                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {config.activa_transferencia === 1 && transferencia ?
                                            <>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="radio-block font-icon-radio">
                                                        <input id="payments4 radio" name="payments" type="radio" className="radio" value="paymentTransferencia" required="" onClick={() => handleChangeTarifa()} defaultChecked={tpvs.length === 0 && config.activa_paypal === 0 && config.activa_stripe === 0} />
                                                        <label htmlFor="payments4">

                                                            {transferencia.nombre_web} <i className="fa-duotone fa-building fa-xl"></i>

                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {config.activa_paycomet === 1 && paycomet ?
                                            <>
                                                {
                                                    Object.keys(paycomet.metodos_pago).map((key) => (
                                                        <div className="col-lg-6 col-md-12" key={"pc" + key} data-key={"pc" + key}>
                                                            <div className="radio-block font-icon-radio">
                                                                <input id="payments6" name="payments" type="radio" className="radio payments6" value={"paymentPaycomet" + key} required="" onClick={() => handleChangeTarifa()} defaultChecked={tpvs.length === 0 && config.activa_paypal === 0 && config.activa_stripe === 0 && config.activa_transferencia === 0} />
                                                                <label htmlFor="payments6">

                                                                    {paycomet.metodos_pago[key].nombre}
                                                                    {
                                                                        paycomet.metodos_pago[key].nombre === "Tarjeta" ?
                                                                            <i className="fa-brands fa-cc-visa fa-xl ms-2"></i>
                                                                            :
                                                                            paycomet.metodos_pago[key].nombre === "PayPal" ?
                                                                                <i className="fa-brands fa-paypal fa-xl ms-2"></i>
                                                                                :
                                                                                <i className="fa-duotone fa-mobile fa-xl ms-2"></i>
                                                                    }

                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    paycomet.apple_pay === 1 ?
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="radio-block font-icon-radio">
                                                                <input id="payments6" name="payments" type="radio" className="radio payments6" value="paymentPaycomet1" required="" onClick={() => handleChangeTarifa()} />
                                                                <label htmlFor="payments6">

                                                                    {t("Apple")} <i className="fa-brands fa-apple-pay fa-2xl ms-2"></i>

                                                                </label>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    paycomet.google_pay === 1 ?
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="radio-block font-icon-radio">
                                                                <input id="payments6" name="payments" type="radio" className="radio payments6" value="paymentPaycomet1" required="" onClick={() => handleChangeTarifa()} />
                                                                <label htmlFor="payments6">

                                                                    {t("Google")} <i className="fa-brands fa-google-pay fa-2xl ms-2"></i>

                                                                </label>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </>
                                            :
                                            <></>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* ***************** FIN TPV, PAYPAL, ETC ***************** */}
                    </form>

                    <Modal
                        show={showModal} onHide={() => setShowModal(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Error")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="clearfix mb-20 ml-20 row">
                                {t("Asegúrate de completar los campos marcados con *")}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>


                <div className="col-md-5 col-lg-4">
                    <div className="mt-5 caixa-preus sticky-top">
                        <div className="row col-md-12">
                            <div className="col-md-12 col-lg-5">
                                {
                                    propiedad.foto_principal.length != 0
                                        ? <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.foto_principal[0].nombre} alt={propiedad.foto_principal[0].texto_alt ? propiedad.foto_principal[0].texto_alt[lang] : ""} title={propiedad.foto_principal[0].texto_title ? propiedad.foto_principal[0].texto_title[lang] : ""} className="imatge-reserva-pas2" />
                                        : <img src="/images/web/bedloop-nofoto.png" alt="--" title="" className="imatge-reserva-pas2" />
                                }
                            </div>
                            <div className="col-md-12 col-lg-7">
                                <h5>{propiedad.nombre}</h5>
                                <p className="small"><i className="fa-duotone fa-location-dot"></i> {propiedad.ciudad + ", " + propiedad.region}</p>
                            </div>
                        </div>
                        <div className="mt-3 mb-5 text-start">
                            <button className="btn btn-primary" onClick={() => handleBack()} style={{ background: "" + websgrupo.color_corporativo_web + "", border: "1px solid" + websgrupo.color_corporativo_web }}>
                                {t("Cambiar fechas")}
                            </button>
                        </div>
                        {websgrupo.activa_multiunit === 1 && numpropiedades > 1
                            ?
                            <>
                                <h4>{t("Número de propiedades")}</h4>
                                <div className="linia"></div>
                                <div className="mt-3 mb-3">
                                    <select className="mb-0 form-control extra" id="numpropiedades0" onChange={() => handleChangeNumPropiedades()} defaultValue="1" ref={numpropiedades0Ref}>
                                        {vectornumpropiedades}
                                    </select>
                                </div>

                            </>
                            :
                            null
                        }


                        <h4>{t("Resumen")}</h4>
                        <div className="linia"></div>

                        <div className="mt-3 mb-3 row">
                            <div className="col-6">{t("Entrada")} {llegada}</div>
                            <div className="col-6 text-end">{t("Salida")} {salida}</div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-12">{adultos + " " + t("Adultos")} {ninos > 0 ? " , " + ninos + " " + t("Niños") : ""} {bebes > 0 ? " , " + bebes + " " + t("Bebés") : ""}</div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-8">{cuentaNoches + " " + t("Noches")}</div>
                            <div className="col-4 text-end pricebase">{precioBase + " " + moneda} </div>
                        </div>

                        <div className="mt-2 mb-2 linia"></div>

                        <div className="mb-3 row">
                            <div className="col-12">{t("Cargos y tasas de servicio")}</div>
                        </div>


                        <div className="mb-3 row" id="hayextracat" style={{ display: showHayExtraCat }}>
                            <div className="col-8">{t("Tasas")}</div>
                            <div className="col-4 text-end"><span id="extracat" ref={pextracatRef}></span> {moneda}</div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-8">{t("Extras")}</div>
                            <div className="col-4 text-end"><span id="pextra" ref={pextraRef}>{(propiedad.fees / 100).toFixed(2)}</span> {moneda}</div>
                        </div>

                        <div className="mb-3 row muestra-codigo-carro" style={{ display: "none" }}>
                            <div className="col-8">{t("Código")}</div>
                            <div className="col-4 text-end"><span className="codigo-carro"></span> {moneda}</div>
                        </div>

                        <div className="mt-2 mb-2 linia"></div>

                        <div className="mb-3 row">
                            <div className="col-8 fw-bolder">{t("Total")}</div>
                            <div className="col-4 text-end fw-bolder totalprice">{(parseFloat(precioBase) + parseFloat(propiedad.fees / 100)).toFixed(2)} {moneda} </div>
                        </div>


                        <div className="mt-3 mb-3 text-center row">
                            <button className="btn btn-primary" onClick={() => handleSubmit()} style={{ background: "" + websgrupo.color_corporativo_web + "", border: "1px solid " + websgrupo.color_corporativo_web }}>
                                <i className="fa-duotone fa-credit-card"></i> {t("Pagar")} {t("Ahora")} <span className="prepago">{((parseFloat(precioBase) + parseFloat(propiedad.fees / 100)) * parseFloat(precios[0].info_tarifa.politica.prepago / 100)).toFixed(2)}</span> {moneda}
                            </button>
                        </div>

                        {
                            propiedad.deposito_reembolsable > 0 ?
                                <div className="mb-3 row">
                                    <div className="col-12 small">{t("La propiedad requiere un depósito de")} {parseFloat(propiedad.deposito_reembolsable).toFixed(2)} {moneda}. {t("Deberás abonarlo por anticipado o al momento de tu llegada al alojamiento.")}</div>
                                </div>
                                :
                                <></>
                        }

                        <p className="mt-4 mb-0">
                            <input type="checkbox" name="aceptacondiciones" required defaultChecked id="aceptacondiciones" ref={aceptacondicionesRef} />
                            <span className="small ms-2 text-start">
                                {t("Al reservar, acepta los")}<a href="./terminos" target="_blank"> {t("términos y condiciones")}</a>, {t("así como la")} <a href="/politica-de-cancelacion" target="_blank">{t("política de cancelación")}</a>. *
                            </span>
                        </p>
                        <p className="mt-5 mb-0 small fst-italic text-end"> * {t("Impuestos incluidos")}</p>
                    </div>

                </div>

                {/*-- *************** DATOS RESERVA ****************** */}

                <span id="datosreserva" data-precioestancia0={precioBase} data-totalprice0={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-prepago0={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-resto0="0.00" data-extras0={propiedad.fees} data-tpcprepago="100" data-fees0="0" data-precioextratarifa="0"></span>
                <span id="datosreservaactuales" ref={datosreservaactualesRef} data-precioestancia={precioBase} data-totalprice={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-prepago={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-resto="0.00" data-extras={propiedad.fees} data-tpcprepago="100" data-fees="0" data-precioextratarifa="0"></span>

                {/*-- ************************************************ */}
            </div>
        </div>
    );
}

export default DatosReserva;
