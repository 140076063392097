import React, { useState, useRef, useEffect } from "react";
import { Container, Form, Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import CustomSelect from '../Compartido/CustomSelect.jsx';
import { useTranslation } from 'react-i18next';


const BuscadorDestino = ({ destino, destinos, tiposcasas, lang, config }) => {

    const { t } = useTranslation();

    registerLocale('es', es)

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const results2 = [];

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const numpersonas = useRef(null);

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if (show == 'none') setShow('block');
        else setShow('none');
    };

    const esconder = useRef(null);

    const [showForm, setShowForm] = useState('none');

    const handleShowForm = () => {
        if (showForm == 'none') {
            setShowForm('block');
            esconder.current.textContent = t("Esconder");
        }
        else {
            setShowForm('none');
            esconder.current.textContent = t("Buscar");
        }
    }

    {/* ********************** */ }

    const [countAdults, setCountAdults] = useState(0);
    const [countNinos, setCountNinos] = useState(0);
    const [countBebes, setCountBebes] = useState(0);

    const handleSumaAdultos = () => {

        if (countAdults < 10) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(countAdults + 1, countNinos, countBebes);
        }
    }

    const handleRestaAdultos = () => {

        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(countAdults - 1, countNinos, countBebes);
        }
    }

    const handleSumaNinos = () => {

        if (countNinos < 10) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(countAdults, countNinos + 1, countBebes);
        }
    }

    const handleRestaNinos = () => {

        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(countAdults, countNinos - 1, countBebes);

        }
    }

    const handleSumaBebes = () => {

        if (countBebes < 10) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(countAdults, countNinos, countBebes + 1);
        }
    }

    const handleRestaBebes = () => {

        if (countBebes > 0) {
            let frase = "";
            setCountBebes(cb => cb - 1);
            handleEtiqueta(countAdults, countNinos, countBebes - 1);
        }
    }


    const handleEtiqueta = (adults, ninos, bebes) => {

        let frase = t("Personas");
        if (adults > 0) frase = " " + adults + " " + t("Adultos");
        if (ninos > 0) frase += " " + ninos + " " + t("Niños");
        if (bebes > 0) frase += " " + bebes + " " + t("Bebés");

        numpersonas.current.textContent = frase;
    }

    let resultsd = [];
    let resultstc = [];
    let optionchecked = "Destinos";

    {
        destinos.forEach((undestino, index) => {
            let objeto = { id: index, name: undestino.elnombre, value: undestino.laurl }
            if (undestino.laurl == destino.laurl) optionchecked = undestino.elnombre;
            resultsd = resultsd.concat(objeto);
        })
    }

    {
        tiposcasas.forEach((untipo, index) => {
            let objeto = { id: index, name: untipo.nombre, value: untipo.id }
            resultstc = resultstc.concat(objeto);
        })
    }

    {/* ********************** */ }

    const handleFocus = (e) => {
        const { target } = e;

        if (target) {
            target.readOnly = true;  // -------> this for all others
            target.blur(); //  ------> this for ios iphone
        }
    };

    const [cMeses, setCMeses] = useState(2);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1023);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            setCMeses(1);
            setShowForm('none');
        }
        else {
            setCMeses(2);
            setShowForm('block');
        }
    }, [isMobile, cMeses]);

    {/* ********************** */ }

    return (
        <Container className="caixa-cercadors">
            <div className="p-0 caixa-seccions solomovil fondo-buscador">
                <div className="row">
                    <div className="col buscador-inputs">
                        <Button className="hidesearc" variant="primary" size="md" onClick={handleShowForm} style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }} ref={esconder}>{t("Buscar")}</Button>
                    </div>
                </div>
            </div>

            <Form className="fondo-buscador caixa-seccions" method="get" action={"/" + preidioma + t("buscar")} style={{ display: showForm }}>
                <div className="row">
                    <div className="col buscador-inputs">
                        <CustomSelect
                            defaultText={optionchecked}
                            optionsList={resultsd}
                            nameInput="destino"
                            realInput={destino.laurl}
                        />
                    </div>
                    <div className="col buscador-inputs">
                        <CustomSelect
                            defaultText="Tipo_Alojamiento"
                            optionsList={resultstc}
                            nameInput="tipo_casa"
                        />
                    </div>
                    <div className="col buscador-inputs">
                        <DatePicker
                            isClearable={true}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            className="form-control mw201 cpointer"
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText={t("Entrada - Salida")}
                            locale={lang}
                            name="daterange"
                            autoComplete="off"
                            monthsShown={cMeses}
                            onFocus={handleFocus}
                        >
                        </DatePicker>

                    </div>
                    <div className="col buscador-inputs">
                        <div onClick={handleShow} className="form-control numpersonas mw201" ref={numpersonas}>{t("Personas")}</div>

                        {/* ********* */}

                        <div className="formpersonas" style={{ display: show }} onBlur={handleShow} tabIndex="0" onMouseLeave={handleShow}>
                            <div className="mt-2 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Adultos")}<div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div></label>
                                <div className="col-md-4 col-6">
                                    <div className="mt-4 input-group">
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="adultos" className="form-control input-number numero-persones" value={countAdults} min="0" max="10" id="numadultos" readOnly={true} />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="linia"></div>

                            <div className="mt-3 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                <div className="mt-2 col-md-4 col-6">
                                    <div className="mt-3 input-group">
                                        <span className="input-group-btn">
                                            <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max="10" id="numninos" readOnly={true} />
                                        <span className="input-group-btn">
                                            <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="linia"></div>

                            <div className="mt-3 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Bebés")}<div className="mini-etiqueta-formulari">( {t("De_0_a_2_años")})</div></label>
                                <div className="mt-2 col-md-4 col-6">
                                    <div className="mt-3 input-group">
                                        <span className="input-group-btn">
                                            <button className="btn btn-xs btn-number boto-cercle" type="button" datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="bebes" className="form-control input-number numero-persones" value={countBebes} min="0" max="10" id="numbebes" readOnly={true} />
                                        <span className="input-group-btn">
                                            <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ********* */}
                    </div>
                    <div className="col buscador-inputs">
                        <Button variant="primary" size="md" type="submit" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                            {t("Buscar")}
                        </Button>
                    </div>
                </div>
            </Form>

        </Container>
    );
}

export default BuscadorDestino;
