import React, { useState, useRef, useEffect } from "react";
import { Container, Form, Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import CustomSelect from '../Compartido/CustomSelect.jsx';
import { useTranslation } from 'react-i18next';

import axios from 'axios';


const BuscadorBarrio = ({ barrio, barrios, destino, destinos, tiposcasas, lang, config, capacidad_maxima }) => {

    const { t } = useTranslation();

    registerLocale('es', es)

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const results2 = [];

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const numpersonas = useRef(null);

    const toggleBlur = (shouldBlur) => {
        var elements = document.getElementsByClassName("potblur");
        if (shouldBlur) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.add('blur');
            }
        } else {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('blur');
            }
        }
    };

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if (show == 'none') {
            setShow('block');
            toggleBlur(true);
        }
        else {
            setShow('none');
            toggleBlur(false);
        }
    };

    const esconder = useRef(null);

    const [showForm, setShowForm] = useState('block');

    const handleShowForm = () => {
        if (showForm == 'none') {
            setShowForm('block');
            esconder.current.textContent = t("Esconder");
        }
        else {
            setShowForm('none');
            esconder.current.textContent = t("Buscar");
        }
    }

    {/* ********************** */ }

    const [countAdults, setCountAdults] = useState(1);
    const [countNinos, setCountNinos] = useState(0);
    const [countBebes, setCountBebes] = useState(0);

    const handleSumaAdultos = () => {

        if (countAdults + countNinos < capacidad_maxima) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(countAdults + 1, countNinos, countBebes);
        }
    }

    const handleRestaAdultos = () => {

        if (countAdults > 1) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(countAdults - 1, countNinos, countBebes);
        }
    }

    const handleSumaNinos = () => {

        if (countNinos + countAdults < capacidad_maxima) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(countAdults, countNinos + 1, countBebes);
        }
    }

    const handleRestaNinos = () => {

        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(countAdults, countNinos - 1, countBebes);

        }
    }

    const handleSumaBebes = () => {

        if (countBebes < capacidad_maxima) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(countAdults, countNinos, countBebes + 1);
        }
    }

    const handleRestaBebes = () => {

        if (countBebes > 0) {
            let frase = "";
            setCountBebes(cb => cb - 1);
            handleEtiqueta(countAdults, countNinos, countBebes - 1);
        }
    }


    const handleEtiqueta = (adults, ninos, bebes) => {

        let frase = t("Escoja personas");
        if (adults == 1) frase = " " + adults + " " + t("Adulto");
        if (adults > 1) frase = " " + adults + " " + t("Adultos");
        if (ninos == 1) frase += " " + ninos + " " + t("Niño");
        if (ninos > 1) frase += " " + ninos + " " + t("Niños");
        if (bebes == 1) frase += " " + bebes + " " + t("Bebé");
        if (bebes > 1) frase += " " + bebes + " " + t("Bebés");

        numpersonas.current.textContent = frase;
    }

    const objeto0 = { id: -1, name: t("Todos"), value: "" }

    let resultsd = [];
    let resultstc = [];
    let optionchecked = "Destinos";

    resultsd = resultsd.concat(objeto0);
    destinos.forEach((undestino, index) => {
        let objeto = { id: index, name: undestino.elnombre, value: undestino.laurl }
        if (undestino.laurl == destino.laurl) optionchecked = undestino.elnombre;
        resultsd = resultsd.concat(objeto);
    })

    resultstc = resultstc.concat(objeto0);
    tiposcasas.forEach((untipo, index) => {
        let objeto = { id: index, name: untipo.nombre, value: untipo.id }
        resultstc = resultstc.concat(objeto);
    })

    {/* ********************** */ }

    const [results4, setResults4] = useState(null);
    const [optioncheckedb, setOptioncheckedb] = useState("Todos");
    const [realBarrio, setRealBarrio] = useState(barrio.id);

    useEffect(() => {
        if (barrios.length > 0) {
            let resultsbarrios = [];
            barrios.forEach((unbarrio, index) => {
                let objeto = { id: index, name: unbarrio.elnombre, value: unbarrio.id }
                if (unbarrio.id == barrio.id) setOptioncheckedb(unbarrio.elnombre);
                resultsbarrios = resultsbarrios.concat(objeto);
            })
            setResults4(resultsbarrios);
        }
    }, [])

    const buscabarrios = (destino) => {
        if (destino) {
            let token = document.getElementById('meta_token').getAttribute('content');

            const result = axios.post('/busca-barrios', {
                destino: destino
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    setResults4(null);

                    setRealBarrio(null);

                    let resultsbarrios = [];
                    if (response.data.length > 0) {
                        setOptioncheckedb("Todos");
                        resultsbarrios = resultsbarrios.concat(objeto0);
                        {
                            response.data.forEach((unbarrio, index) => {
                                let objeto = { id: index, name: unbarrio.elnombre, value: unbarrio.id }
                                resultsbarrios = resultsbarrios.concat(objeto);
                            })
                        }
                        setResults4(resultsbarrios);
                    }
                })
        }
        else {
            setResults4(null);
        }
    }

    {/* ********************** */ }

    const handleFocus = (e) => {
        const { target } = e;

        if (target) {
            target.readOnly = true;  // -------> this for all others
            target.blur(); //  ------> this for ios iphone
        }
    };

    const [cMeses, setCMeses] = useState(2);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1023);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            setCMeses(1);
        }
        else {
            setCMeses(2);
        }
    }, [isMobile, cMeses]);

    return (
        <Container className="caixa-cercadors">
            <div className="p-0 caixa-seccions solomovil fondo-buscador">
                <div className="row">
                    <div className="col buscador-inputs">
                        <Button className="hidesearc" variant="primary" size="md" onClick={handleShowForm} style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }} ref={esconder}>{t("Esconder")}</Button>
                    </div>
                </div>
            </div>

            <Form className="fondo-buscador caixa-seccions" method="get" action={"/" + preidioma + t("buscar")} style={{ display: showForm }}>
                <div className="row">
                    <div className="col buscador-inputs bderecha">
                        <span className="titulobuscador">{t("Destinos")}</span>
                        <CustomSelect
                            defaultText={optionchecked}
                            optionsList={resultsd}
                            nameInput="destino"
                            realInput={destino.laurl}
                            funcion={buscabarrios}
                            onOpen={() => toggleBlur(true)}
                            onClose={() => toggleBlur(false)}
                        />
                    </div>

                    {results4 && (
                        <div className="col buscador-inputs bderecha">
                            <span className="titulobuscador">{t("Barrios")}</span>
                            <CustomSelect
                                defaultText={optioncheckedb}
                                optionsList={results4}
                                nameInput="barrio"
                                realInput={realBarrio}
                                onOpen={() => toggleBlur(true)}
                                onClose={() => toggleBlur(false)}
                            />
                        </div>
                    )}
                    <div className="col buscador-inputs bderecha">
                        <span className="titulobuscador">{t("Tipo Alojamiento")}</span>
                        <CustomSelect
                            defaultText="Todos"
                            optionsList={resultstc}
                            nameInput="tipo_casa"
                            onOpen={() => toggleBlur(true)}
                            onClose={() => toggleBlur(false)}
                        />
                    </div>
                    <div className="col buscador-inputs bderecha">
                        <span className="titulobuscador">{t("Entrada - Salida")}</span>
                        <DatePicker
                            isClearable={true}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            onCalendarOpen={() => toggleBlur(true)}
                            onCalendarClose={() => toggleBlur(false)}
                            className="form-control mw201 cpointer noborder fs15"
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            monthsShown={cMeses}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText={t("DD/MM/YYYY - DD/MM/YYYY")}
                            locale={lang}
                            name="daterange"
                            autoComplete="off"
                            onFocus={handleFocus}
                        >
                        </DatePicker>

                    </div>
                    <div className="col buscador-inputs">
                        <span className="titulobuscador">{t("Personas")}</span>
                        <div onClick={handleShow} className="form-control numpersonas mw201 noborder" ref={numpersonas}>1 {t("Adulto")}</div>

                        {/* ********* */}

                        <div className="formpersonas" style={{ display: show }} onBlur={handleShow} tabIndex="0" onMouseLeave={handleShow}>
                            <div className="mt-2 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Adultos")}<div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div></label>
                                <div className="col-md-4 col-6">
                                    <div className="mt-4 input-group">
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="adultos" className="form-control input-number numero-persones" value={countAdults} min="1" max={capacidad_maxima} id="numadultos" readOnly={true} />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="linia"></div>

                            <div className="mt-3 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                <div className="mt-2 col-md-4 col-6">
                                    <div className="mt-3 input-group">
                                        <span className="input-group-btn">
                                            <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max={capacidad_maxima} id="numninos" readOnly={true} />
                                        <span className="input-group-btn">
                                            <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="linia"></div>

                            <div className="mt-3 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Bebés")}<div className="mini-etiqueta-formulari">( {t("De_0_a_2_años")})</div></label>
                                <div className="mt-2 col-md-4 col-6">
                                    <div className="mt-3 input-group">
                                        <span className="input-group-btn">
                                            <button className="btn btn-xs btn-number boto-cercle" type="button" datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="bebes" className="form-control input-number numero-persones" value={countBebes} min="0" max={capacidad_maxima} id="numbebes" readOnly={true} />
                                        <span className="input-group-btn">
                                            <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ********* */}
                    </div>
                    <div className="col buscador-inputs botonsubmit">
                        <Button variant="primary" size="md" type="submit" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web, borderRadius: "28px", height: "50px", marginTop: "4px" }} className="submit-button-custom">
                            <span className="onlymobile">{t("Buscar")} </span><i className="fa-solid fa-magnifying-glass fs21"></i>
                        </Button>
                    </div>
                </div>
            </Form>

        </Container>
    );
}

export default BuscadorBarrio;
