import { React } from "react";
import { Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextosMarca = ({ eltexto, ruta, lang, tiposbarcos, config }) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

    if (eltexto.imagen) {
        imagen = ruta + "/marcas/" + eltexto.id + "/imagen/" + eltexto.imagen;
    }

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <Container>
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={eltexto.nombre} style={{ backgroundImage: "url('" + imagen + "')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover" }}></div>

                    <h1 className="mb-5 noticia-individual">{eltexto.nombre}</h1>
                    <div className="mt-5 mb-5 noticia-individual">
                        <div className="noticia-individual-descripcio-llarga">
                            {ReactHtmlParser(eltexto.descripcion_corta[lang])}
                        </div>
                        <div className="noticia-individual-descripcio-llarga">
                            {ReactHtmlParser(eltexto.texto[lang])}
                        </div>
                    </div>
                    <div className="mt-5 noticia-individual">
                        <div className="noticia-individual-descripcio-llarga">
                            {
                                tiposbarcos.map((tipo, index) => (

                                    <a key={index} href={"/" + preidioma + t("buscar") + "?tipo_casa=" + tipo.id + "&ruta=" + eltexto.id} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web, display: "inline", marginLeft: "20px", marginRight: "10px" }}>
                                        {t(tipo.nombre)}
                                    </a>

                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}


export default TextosMarca;
