import { React } from "react";
import { Tab, Tabs } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextoPoliticas = ({ ruta }) => {
    let results = [];

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

    if (ruta + '/politicas/cabecera/cabecera.jpg') {
        imagen = ruta + '/politicas/cabecera/cabecera.jpg';
    }

    return (

        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={t("Política_de_cancelación")} style={{ backgroundImage: "url('" + imagen + "')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover" }} ></div>
                    <h1 className="mt-5 mb-5 noticia-individual">{t("Política_de_cancelación")}</h1>
                    <div className="mb-5 noticia-individual-descripcio-llarga">
                        <p>
                            {ReactHtmlParser(t("En Onboard Holidays, trabajamos con diversas compañías de embarcaciones en distintos destinos, por lo que las políticas de cancelación pueden variar según la goleta, catamarán o mini crucero seleccionado"))}
                        </p>
                        <p>
                            {ReactHtmlParser(t("Cuando realices una solicitud de disponibilidad, te proporcionaremos toda la información detallada sobre la política de cancelación aplicable a la embarcación y destino de tu interés."))}
                        </p>
                        <p>
                            {ReactHtmlParser(t("Para garantizar tu tranquilidad, muchas de nuestras opciones incluyen condiciones flexibles y la posibilidad de contratar un seguro de cancelación, permitiéndote modificar o cancelar tu viaje bajo términos favorables."))}
                        </p>
                        <p>{t("Si tienes alguna duda, nuestro equipo estará encantado de asesorarte antes de confirmar tu reserva.")}</p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TextoPoliticas;
