import Menu from './Compartido/Menu.jsx';
import DatosPeticion from './Peticion/DatosPeticion.jsx';
import Footer from './Compartido/Footer.jsx';

const Peticion = (props) => {
    return (
        <main>

            <Menu
                destinos={props.destinos}
                sobrenosotros={props.textosobrenosotros}
                clientes={props.textoclientes}
                propietarios={props.textopropietarios}
                servicios={props.textoservicios}
                experiencias={props.experiencias}
                idiomas={props.idiomas}
                lang={props.lang}
                tiposcasas={props.tiposcasas}
                config={props.config}
                textopropiedades={props.textopropiedades}
                edificios={props.edificios}
                marcas={props.marcas}
                ruta={props.ruta}
            />

            <DatosPeticion
                propiedad={props.propiedad}
                llegada={props.llegada}
                salida={props.salida}
                difdias={props.difdias}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                ruta={props.ruta}
                numpropiedades={props.numpropiedades}
                websgrupo={props.websgrupo}
                precios={props.precios}
                moneda={props.moneda}
                lang={props.lang}
            />

            <Footer
                datos={props.websgrupo}
                terminos={props.textoterminos}
                protocolos={props.textoprotocolos}
                avisolegal={props.textoavisolegal}
                config={props.config}
                elementosweb={props.elementosweb}
                politicaprivacidad={props.textopolitica}
                lang={props.lang}
                ruta={props.ruta}
            />
        </main>
    );
}

export default Peticion;
