
import { React } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';

const Mapa = ({ destinos, elementosweb, config, lang }) => {
    let results = [];

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    var centrolat = 0;
    var centrolong = 0;

    destinos.forEach((undestino, index) => {
        centrolat += undestino.latitud;
        centrolong += undestino.longitud;

        results = results.concat(
            <Marker position={[undestino.latitud, undestino.longitud]} key={index}>
                <Popup>
                    <a href={"/" + preidioma + t("apartamentos") + "-" + undestino.laurl}>{undestino.elnombre}</a>
                </Popup>
            </Marker>
        );
    })
    centrolat = centrolat / destinos.length;
    centrolong = centrolong / destinos.length;

    return (
        config.activa_mapa_web ?
            <div className="mapa mt-4 pt-4 pb-5 potblur">

                <h2 className="text-center">{elementosweb.titulo_mapa ? elementosweb.titulo_mapa[lang] : ""}</h2>
                <p className="text-center">{elementosweb.subtitulo_mapa ? elementosweb.subtitulo_mapa[lang] : ""}</p>

                <MapContainer center={[centrolat, centrolong]} zoom={config.zoom_mapa_web} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                        url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                    />
                    {results}
                </MapContainer>
            </div>
            :
            <></>
    );
}

export default Mapa;
