import { React, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import LangSelector, { langSelector } from './LangSelector.jsx';

import "../../../../../../resources/css/web/kukaay/estils.css";

import { Helmet } from "react-helmet";


const Menu = ({ destinos, sobrenosotros, clientes, propietarios, servicios, experiencias, idiomas, lang, tiposcasas, config, textopropiedades, edificios, marcas, ruta, destinoslarga, meta_titulo, meta_descripcion, meta_keywords }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const primeraLetraMayuscula = (cadena) => cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length).toLowerCase());

    useEffect(() => {
        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

    }, []);


    return (
        <>
            {/* ********** */}

            <Helmet>
                <title>{`${meta_titulo}`}</title>
                <meta name="title" content={`${meta_titulo}`} />
                <meta name="description" content={`${meta_descripcion}`} />
                <meta name="keywords" content={`${meta_keywords}`} />
                <meta charset="utf-8" />
            </Helmet>

            {
                (config.id === 1) ?
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/favicon.ico`}></link>
                    </Helmet>
                    :
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/${config.id}/favicon.ico`}></link>
                    </Helmet>
            }

            {
                (config.activa_analytics === 1) ?
                    <Helmet>
                        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.codigo_analytics}`}></script>
                        <script>
                            {`
                                                        window.dataLayer = window.dataLayer || [];
                                                        function gtag(){window && window.dataLayer && window.dataLayer.push(arguments)}
                                                        gtag('js', new Date())
                                                        gtag('config', '${config.codigo_analytics}');
                                                    `}
                        </script>
                    </Helmet>
                    :
                    ""
            }

            {/* ********** */}

            <Navbar collapseOnSelect expand="lg" className="bg-body menu sticky-top">
                <Navbar.Brand href="https://www.villakukaay.com">
                    {config.logo_fiscal != "" ? <img className="logo" alt="Logo" title="Logo" src={ruta + "/web/logo/" + config.logo_fiscal} /> : <img className="logo" alt="Logo BedLoop" title="Logo BedLoop" src="https://www.bedloop.com/imatges/logo.webp" />}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="flex-row-reverse" id="responsive-navbar-nav">
                    <Nav className="">
                        <LangSelector
                            idiomas={idiomas}
                            langactual={lang.toString().toUpperCase()}
                        />
                        {/*<Nav.Link className="navegacio" href="/loginweb">{ t("Login") }</Nav.Link>*/}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default Menu;
