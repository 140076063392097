import { React } from "react";
import { useTranslation } from 'react-i18next';

const TextoPiePgina = ({ elementosweb, lang }) => {

    const { t } = useTranslation();

    return (
        <div className="text-center p-5 potblur">
            {elementosweb.pie_pagina && <p className="text-center p-5 border-top mb-0">{elementosweb.pie_pagina[lang]}</p>}
        </div>

    );
};

export default TextoPiePgina;
