import Cabecera from './Compartido/Cabecera.jsx';
import Footer from './Compartido/Footer.jsx';
import TextoGracias from './Compartido/TextoGracias.jsx';
import '../../../../../resources/css/web/reysabio/styles.css';

const GraciasPaypal = (props) => {
    return (
        <main>
            <Cabecera
                lang={props.lang}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
                config={props.websgrupo}
            />
            <TextoGracias
            />
            <Footer
                lang={props.lang}
                websgrupo={props.websgrupo}
            />
        </main>
    );
}

export default GraciasPaypal;
