import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import BotonWhatsapp from "./BotonWhatsapp";

const Footer = ({ lang, websgrupo }) => {

    const { t } = useTranslation();

    // var currentTime = new Date();
    // var year = currentTime.getFullYear();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            <div className="container-fluid d-flex justify-content-center w-100 footer">
                <div className="d-flex align-items-center justify-content-center">
                    <img src="/images/web/reysabio/Logo-texto-burdeos.png" alt="" width="130px" height="160px" />
                    <a className="elementor-icon" href="https://www.instagram.com/casadelreysabio/" target="_blank" style={{ padding: " 36% 0 0 0" }}>
                        <i aria-hidden="true" className="fab fa-instagram" style={{ fontSize: "25px" }}></i>
                    </a>
                </div>
                <div className="align-items-center justify-content-center solomobile">
                    <img src="/images/web/reysabio/Logotipo-completo_Vinotinto.png" alt="" width="90%" />
                    <a className="elementor-icon" href="https://www.instagram.com/casadelreysabio/" target="_blank" style={{ padding: " 36% 0 0 0" }}>
                        <i aria-hidden="true" className="fab fa-instagram" style={{ fontSize: "25px" }}></i>
                    </a>
                </div>
                <div className="grid-footer">
                    <div></div>
                    <div className="space-grid-footer-text">
                        <h2 className="noto">{t("Legal")}</h2>
                        <ul className="list-unstyled">
                            <li><a className="text-decoration-none w-100" href={"/" + preidioma + t("legal")}>{t("Aviso Legal")}</a></li>
                            <li><a className="text-decoration-none w-100" href={"/" + preidioma + t("terminos")}>{t("Terminos")}</a></li>
                            <li><a className="text-decoration-none w-100" href={"/" + preidioma + t("cookies")}>{t("Politica de Cookies") + " (UE)"}</a></li>
                        </ul>
                    </div>
                    <div className="space-grid-footer-text">
                        <h2 className="noto">{t("Ayuda")}</h2>
                        <ul className="list-unstyled">
                            <li><a className="text-decoration-none w-100" href={"/" + preidioma + t('politica-de-cancelacion')}>{t("Politicas de cancelación")}</a></li>
                            <li><a className="text-decoration-none w-100" href={"/" + preidioma + t('faqs')}>{t("FAQs")}</a></li>
                            <li><a className="text-decoration-none w-100" href={"/" + preidioma + t("protocolos")}>{t("Protocolos")}</a></li>
                        </ul>
                    </div>
                    <div className="space-grid-footer-last">
                        <h2 className="noto">{t("Newsletter")}</h2>
                        <p>{t("Subscríbete a nuestra newsletter para recibir ofertas y eventos de Sevilla")}</p>
                        <div>
                            <form action="" className="form-footer">
                                <div>
                                    <input type="email" name="" id="" placeholder="Email" className="w-100" />
                                </div>
                                <div>
                                    <button className="w-100">{t("Enviar")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <BotonWhatsapp
                websgrupo={websgrupo}
            />
        </>

    );
};
export default Footer;

