import React, { useState, useEffect, useCallback } from 'react';
import { Container } from "react-bootstrap";
// import '../../../../../../resources/css/web/reysabio/styles.css';
// import '~/resources/css/web/reysabio/styles.css';

import { Helmet } from "react-helmet"
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// import logo from 'Logo-texto-burdeos.png'
const Cabecera = ({ lang, meta_titulo, meta_descripcion, meta_keywords, ruta, config }) => {

    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [langu, setLangu] = useState({ lang });

    const [menuAbierto, setMenuAbierto] = useState(false);

    const elementcerca = document.getElementById('formcerca');

    menuAbierto && elementcerca ? elementcerca.style.zIndex = "0" : "";

    !menuAbierto && elementcerca ? elementcerca.style.zIndex = "5" : "";

    menuAbierto && window.innerWidth > 1024 ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto"

    // var currentTime = new Date();
    // var year = currentTime.getFullYear();

    const [isDesktop, setIsDesktop] = useState(false);
    const [anScroll, setAnScroll] = useState(0);

    const handleScroll = useCallback(() => {
        const scrollY = window.scrollY;
        setAnScroll(scrollY);
        const translateY = scrollY + 25; // ajusta el valor de translate
        const element = document.getElementById('menuamagat'); // obtiene el elemento que deseas actualizar
        element.style.transform = `translateY(${translateY}px)`;
    }, []);

    const getUserBrowserLanguage = (langu) => {
        let lang = "";

        if (langu != "")
            lang = langu.lang.toLowerCase();
        else
            lang = window.navigator.language;

        if (lang.includes("es")) return "es";
        if (lang.includes("en")) return "en";
        if (lang.includes("fr")) return "fr";

        return "es";
    };

    useEffect(() => {

        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

    }, []);


    useEffect(() => {
        const anchoPantalla = window.matchMedia('(min-width: 1024px)');
        setIsDesktop(anchoPantalla.matches);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        const userBrowserLang = getUserBrowserLanguage(langu);

        i18n.changeLanguage(userBrowserLang);
        setLangu(userBrowserLang);
    }, []);

    const classes = {
        lineaidiomas: isDesktop && anScroll > 100 ? 'fijoid' : '',
        btnreservas: isDesktop && anScroll > 100 ? 'fijobtn' : '',
        imgreysabio: isDesktop && anScroll > 100 ? 'fijoimg' : '',
        hamburguesa: isDesktop && anScroll > 100 ? 'fijohmg' : '',
    };

    const handleLangSelect = (currentLang) => {

        i18n.changeLanguage(currentLang.toLowerCase());

        setLangu(currentLang);

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/cambiaidioma', {
            lang: currentLang
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error");
                console.log(error);
            });
    }



    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            {/* ********** */}

            <Helmet>
                <title>{`${meta_titulo}`}</title>
                <meta name="title" content={`${meta_titulo}`} />
                <meta name="description" content={`${meta_descripcion}`} />
                <meta name="keywords" content={`${meta_keywords}`} />
                <meta charset="utf-8" />
            </Helmet>

            {
                (config.id === 1) ?
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/favicon.ico`}></link>
                    </Helmet>
                    :
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/${config.id}/favicon.ico`}></link>
                    </Helmet>
            }

            {
                (config.activa_analytics === 1) ?
                    <Helmet>
                        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.codigo_analytics}`}></script>
                        <script>
                            {`
                                                        window.dataLayer = window.dataLayer || [];
                                                        function gtag(){window && window.dataLayer && window.dataLayer.push(arguments)}
                                                        gtag('js', new Date())
                                                        gtag('config', '${config.codigo_analytics}');
                                                    `}
                        </script>
                    </Helmet>
                    :
                    ""
            }

            {/* ********** */}
            <Helmet>
                {`
                    <script id='cmplz-cookiebanner-js-extra'>
                        var complianz = {"prefix":"cmplz_","user_banner_id":"1","set_cookies":[],"block_ajax_content":"","banner_version":"14","version":"6.3.5","store_consent":"","do_not_track":"","consenttype":"optin","region":"eu","geoip":"","dismiss_timeout":"","disable_cookiebanner":"","soft_cookiewall":"","dismiss_on_scroll":"","cookie_expiry":"365","url":"https:\/\/casadelreysabio.com\/wp-json\/complianz\/v1\/","locale":"lang=es&locale=es_ES","set_cookies_on_root":"","cookie_domain":"","current_policy_id":"14","cookie_path":"\/","categories":{"statistics":"estad\u00edsticas","marketing":"m\u00e1rketing"},"tcf_active":"","placeholdertext":"Haz clic para aceptar las cookies de {category} y permitir este contenido","css_file":"\/css\/web\/reysabio\/banner-{banner_id}-{type}.css?v=14","page_links":{"eu":{"cookie-statement":{"title":"","url":"https:\/\/casadelreysabio.com\/"}}},"tm_categories":"","forceEnableStats":"","preview":"","clean_cookies":""};
                    </script>
                    <script defer defer src='/js/web/reysabio/js/complianz.min.js' id='cmplz-cookiebanner-js'></script>
                `}
            </Helmet>
            <div className="p-0 m-0 container-fluid z-1 header">
                <div className={`${classes.lineaidiomas} container-fluid lang`}>
                    <section className="row">
                        <div className="col">
                            <ul className="p-0 m-0 list-unstyled d-flex justify-content-end opt-lang">
                                <li className="" onClick={() => handleLangSelect('EN')} style={{ cursor: "pointer" }}>english</li>
                                <li className="" onClick={() => handleLangSelect('ES')} style={{ cursor: "pointer" }}>español</li>
                                <li className="" onClick={() => handleLangSelect('FR')} style={{ cursor: "pointer" }}>français</li>
                            </ul>
                        </div>
                    </section>
                </div>

                <div className="container-fluid selectorInp">
                    <section className="pl-5 row w-100 d-flex align-items-center">
                        <div className="m-2 col">
                            <div>
                                <a href={"/" + preidioma}>
                                    <img src="/images/web/reysabio/Logo-texto-burdeos.png" alt="" width="100" height="130" />
                                </a>
                            </div>
                        </div>
                        <div className="col ">
                            <label htmlFor="hidden-menu-mobile" className="label-header menumobile">
                                <img
                                    width="50"
                                    height="50"
                                    src="/images/web/reysabio/Menu_menu-burdeos.png"
                                    className="attachment-full size-full"
                                    alt=""
                                    loading="lazy"
                                    srcSet="/images/web/reysabio/Menu_menu-burdeos.png 4500w, /images/web/reysabio/Menu_menu-burdeos-300x156.png 300w, /images/web/reysabio/Menu_menu-burdeos-1024x531.png 1024w, /images/web/reysabio/Menu_menu-burdeos-768x398.png 768w, /images/web/reysabio/Menu_menu-burdeos-1536x797.png 1536w, /images/web/reysabio/Menu_menu-burdeos-2048x1062.png 2048w"
                                    sizes="50px, 50px"
                                />
                            </label>
                            <input className="inp-menu" type="checkbox" name="" id="hidden-menu-mobile" checked={menuAbierto} onChange={() => setMenuAbierto(!menuAbierto)} />
                        </div>
                        <div className="col-md-auto box-opt" style={{ zIndex: "4" }}>
                            <ul className="p-0 m-0 list-unstyled d-flex opt-nav">
                                <li className="mx-2"><a href={"/" + preidioma + t("sobre-nosotros")}>{t("La Casa")}</a></li>
                                <li className="mx-2"><a href={"/" + preidioma + t("buscar")}>{t("Apartamentos")}</a></li>
                                <li className="mx-2"><a href={"/" + preidioma + t("ayuda")}>{t("Galeria")}</a></li>
                                <li className="mx-2"><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>
                            </ul>
                        </div>
                        <div className="col d-flex justify-content-end align-items-center">
                            <label htmlFor="hidden-menu" className={`label-header ${classes.hamburguesa}`} style={{ display: "none" }}>
                                <img
                                    width="50"
                                    height="50"
                                    src="/images/web/reysabio/Menu_menu-burdeos.png"
                                    className="attachment-full size-full"
                                    alt=""
                                    loading="lazy"
                                    srcSet="/images/web/reysabio/Menu_menu-burdeos.png 4500w, /images/web/reysabio/Menu_menu-burdeos-300x156.png 300w, /images/web/reysabio/Menu_menu-burdeos-1024x531.png 1024w, /images/web/reysabio/Menu_menu-burdeos-768x398.png 768w, /images/web/reysabio/Menu_menu-burdeos-1536x797.png 1536w, /images/web/reysabio/Menu_menu-burdeos-2048x1062.png 2048w"
                                    sizes="50px, 50px"
                                    style={{ cursor: "pointer" }}
                                />
                            </label>
                            <input className="inp-menu" type="checkbox" name="" id="hidden-menu" checked={menuAbierto} onChange={() => setMenuAbierto(!menuAbierto)} />
                            <div className={`btn-reservar ${classes.btnreservas}`} style={{ zIndex: "1000" }}>
                                <a href={"/" + preidioma + t("buscar")} className="text-decoration-none">{t("Reservar")}</a>
                            </div>
                        </div>
                    </section>
                </div>

                <div className={`container-fluid ${classes.imgreysabio}`}>
                    <section className="pl-5 row w-100 d-flex align-items-center">
                        <div className="col">
                            <div>
                                <img src="/images/web/reysabio/Logo-ilustracion-burdeos.png" className="" alt="" width="110" height="200" />
                            </div>
                        </div>
                    </section>
                </div>

                <div className={`container-fluid phone-menu ${menuAbierto && window.innerWidth < 1023 ? 'show-phone' : ''}`}>
                    <ul className='p-0 m-0 w-100'>
                        <li><a href={"/" + preidioma + t("sobre-nosotros")}>{t("La Casa")}</a></li>
                        <li><a href={"/" + preidioma + t("buscar")}>{t("Apartamentos")}</a></li>
                        <li><a href={"/" + preidioma + t("ayuda")}>{t("Galeria")}</a></li>
                        <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>
                    </ul>
                </div>

                <div className={`container-fluid hidden-menu ${menuAbierto ? 'show' : ''}`} id="menuamagat" style={{ transform: "translateY(0)" }}>
                    <div className="grid-hidden-menu">
                        <div className="img-hm">
                            <a href={"/" + preidioma}>
                                <img width="120" height="200" src="/images/web/reysabio/Logo-completo-beige.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="/images/web/reysabio/Logo-completo-beige.png 969w, /images/web/reysabio/Logo-completo-beige-178x300.png 178w, /images/web/reysabio/Logo-completo-beige-608x1024.png 608w, /images/web/reysabio/Logo-completo-beige-768x1293.png 768w, /images/web/reysabio/Logo-completo-beige-912x1536.png 912w" sizes="(max-width: 969px) 100vw, 969px" />
                            </a>
                        </div>
                        <div className="text-hm text-hm-1">
                            <div>
                                <h2 className='noto'>{t("Información")}</h2>
                                <ul className='p-0 m-0'>
                                    <li><a href={"/" + preidioma}>{t("Inicio")}</a></li>
                                    <li><a href={"/" + preidioma + t("sobre-nosotros")}>{t("La Casa")}</a></li>
                                    <li><a href={"/" + preidioma + t("ayuda")}>{t("Galeria")}</a></li>
                                    <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="text-hm">
                            <div>
                                <h2 className='noto'>{t("Apartamentos")}</h2>
                                <ul className='p-0 m-0' style={{ columnCount: "2" }}>
                                    <li>
                                        <form action={"/" + preidioma + t("buscar")} method="get">
                                            <input type="hidden" name="idPropiedades" value="114" />
                                            <button type="submit" className="noboto">{t("Estudio")}</button>
                                        </form>
                                    </li>
                                    <li>
                                        <form action={"/" + preidioma + t("buscar")} method="get">
                                            <input type="hidden" name="idPropiedades" value="109,110,111,112,113" />
                                            <button type="submit" className="noboto">{t("Estándar")}</button>
                                        </form>
                                    </li>
                                    <li>
                                        <form action={"/" + preidioma + t("buscar")} method="get">
                                            <input type="hidden" name="idPropiedades" value="116,117,118,119,120,121,122" />
                                            <button type="submit" className="noboto">{t("Superior")}</button>
                                        </form>
                                    </li>
                                    <li>
                                        <form action={"/" + preidioma + t("buscar")} method="get">
                                            <input type="hidden" name="idPropiedades" value="123" />
                                            <button type="submit" className="noboto">{t("Presidencial")}</button>
                                        </form>
                                    </li>
                                    <li>
                                        <form action={"/" + preidioma + t("buscar")} method="get">
                                            <input type="hidden" name="idPropiedades" value="115" />
                                            <button type="submit" className="noboto">{t("Dúplex")}</button>
                                        </form>
                                    </li>
                                    <li>
                                        <form action={"/" + preidioma + t("buscar")} method="get">
                                            <input type="hidden" name="idPropiedades" value="123,124,125" />
                                            <button type="submit" className="noboto">{t("Terraza")}</button>
                                        </form>
                                    </li>
                                    <li>
                                        <form action={"/" + preidioma + t("buscar")} method="get">
                                            <input type="hidden" name="idPropiedades" value="123,124" />
                                            <button type="submit" className="noboto">{t("Piscina")}</button>
                                        </form>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="hidden-menu-last">
                            <label htmlFor="hidden-menu">
                                <img
                                    width="50"
                                    height="50"
                                    src="/images/web/reysabio/Menu_menu-burdeos.png"
                                    className="attachment-full size-full"
                                    alt=""
                                    loading="lazy"
                                    srcSet="/images/web/reysabio/Menu_menu-burdeos.png 4500w, /images/web/reysabio/Menu_menu-burdeos-300x156.png 300w, /images/web/reysabio/Menu_menu-burdeos-1024x531.png 1024w, /images/web/reysabio/Menu_menu-burdeos-768x398.png 768w, /images/web/reysabio/Menu_menu-burdeos-1536x797.png 1536w, /images/web/reysabio/Menu_menu-burdeos-2048x1062.png 2048w"
                                    sizes="50px, 50px"
                                    style={{ cursor: "pointer" }}
                                />

                            </label>
                            <div className='btn-reservar-hidden' style={{ marginTop: "-40px" }}>
                                <a href={"/" + preidioma + t("buscar")}>{t("Reservar")}</a>
                            </div>
                            <input className="inp-menu" type="checkbox" name="" id="hidden-menu" checked={menuAbierto} onChange={() => setMenuAbierto(!menuAbierto)} />
                        </div>
                    </div>
                </div>
                <label className={`container-fluid whole-label-quit ${menuAbierto ? 'show-blur' : ''}`} htmlFor="hidden-menu">

                </label>
            </div >
        </>

    );
};

export default Cabecera;
