import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomAlert from "../Compartido/CustomAlert";

const Content = ({ lang }) => {

    const { t } = useTranslation();

    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    const [alert, setAlert] = useState({ show: false, type: '', message: '' });

    const enviaContacto = (e) => {
        e.preventDefault()
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/enviarcontacto', {
            yourname: e.target.name.value,
            contactEmail: e.target.email.value,
            contactMessage: e.target.message.value,
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                if (response.data.type == 'OK') {
                    setAlert({ show: true, type: 'ok', message: t("Contacto enviado. En breve nos pondremos en contacto con Ud.") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);

                    location.reload();
                }
                else {
                    setAlert({ show: true, type: 'ko', message: t("Contacto no enviado") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);
                }
            });
    }

    const handleCloseAlert = () => {
        setAlert({ show: false, type: '', message: '' });
    };

    return (
        <div className="container-fluid contacto-box">
            <div>
                <h1 className="noto">{t("Contáctanos")}</h1>
            </div>
            <div className="w-100">
                <form action="" className="contactoForm w-100" onSubmit={enviaContacto}>
                    <div className="mt-3">
                        <input type="text" placeholder={t("Su nombre")} name="name" />
                    </div>
                    <div className="mt-3">
                        <input type="text" placeholder={t("Email")} name="email" />
                    </div>
                    <div className="mt-3">
                        <input type="text" placeholder={t("Mensaje")} name="message" />
                    </div>
                    <div>
                        <button type="submit" className="amayusculas">{t("Enviar")}</button>
                    </div>
                </form>
                <div>
                    <p><strong style={{ fontWeight: "700" }}>{t("Dirección")}: </strong><a href="mailto:info@casadelreysabio.com">Calle Don Alonso el Sabio, 7, 41004 Sevilla</a></p>
                    <p><strong style={{ fontWeight: "700" }}>{t("Teléfono")}: </strong><a href="tel:+34695637232">+34 695 637 232</a></p>
                    <p><strong style={{ fontWeight: "700" }}>{t("Email")}: </strong><a href="mailto:info@casadelreysabio.com">info@casadelreysabio.com</a></p>
                </div>
                <div>
                    <iframe style={{ width: "100%", height: "100%" }} data-placeholder-image="/images/web/reysabio/google-maps-minimal-1280x920.jpg" data-category="marketing" data-service="google-maps" className="cmplz-placeholder-element cmplz-no-video cmplz-processed cmplz-activated" data-cmplz-target="src" data-src-cmplz="https://maps.google.com/maps?q=Casa%20del%20Rey%20Sabio&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=Casa%20del%20Rey%20Sabio&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" title="Casa del Rey Sabio" aria-label="Casa del Rey Sabio"></iframe>
                </div>
            </div>
            {alert.show && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: "50"
                    }}>
                        <CustomAlert type={alert.type} message={alert.message} onClose={handleCloseAlert} />
                    </div>
                </div>
            )}
        </div>
    );
};
export default Content;
