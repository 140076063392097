import React, { useState, useRef, forwardRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Container } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

import "./../../../../../css/magno/cabecera.css";
import "./../../../../../css/magno/home.css";


const Cabecera = ({ lang, config, websgrupo, elementosweb }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    registerLocale('es', es)

    const numpersonas = useRef(null);

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if (show == 'none') setShow('block');
        else setShow('none');
    };

    const [titlePersonas, setTitlePersonas] = useState("1 " + t("Persona"));

    const onClickOutsideListener = () => {
        handleShow()
        document.removeEventListener("click", onClickOutsideListener)
    }

    const onClickOutsideListener2 = (evento) => {
        console.log(evento.target);
        if (!evento.target.closest('.cabecerapersonas') && !evento.target.closest('.formpersonas')) {
            setShow('none');
        }
    }

    useEffect(() => {
        document.addEventListener('click', onClickOutsideListener2);

        return () => {
            document.removeEventListener('click', onClickOutsideListener2);
        };
    }, []);

    {/* ******************* */ }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1023);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    {/* ******************* */ }

    const [countAdults, setCountAdults] = useState(1);
    const [countNinos, setCountNinos] = useState(0);
    const [countBebes, setCountBebes] = useState(0);

    const handleSumaAdultos = () => {
        if (countAdults < 10) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(countAdults + 1, countNinos, countBebes);
        }
    }

    const handleRestaAdultos = () => {
        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(countAdults - 1, countNinos, countBebes);
        }
    }

    const handleSumaNinos = () => {

        if (countNinos < 10) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(countAdults, countNinos + 1, countBebes);
        }
    }

    const handleRestaNinos = () => {

        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(countAdults, countNinos - 1, countBebes);
        }
    }

    const handleSumaBebes = () => {

        if (countBebes < 10) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(countAdults, countNinos, countBebes + 1);
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(countAdults, countNinos, countBebes - 1);
        }
    }

    const handleEtiqueta = (adults, ninos, bebes) => {
        let frase = t("Personas");
        if (adults > 0) frase = " " + (adults + ninos) + " " + t("Personas");
        if (bebes > 0) frase += " " + bebes + " " + t("Bebés");

        numpersonas.current.textContent = frase;
    }

    {/* ******************* */ }

    let imagenfondo = "/images/web/magno/defhome.jpg";

    {/* ******************* */ }

    const [isOpenDP, setIsOpenDP] = useState(false);
    const [isOpenDPM, setIsOpenDPM] = useState(false);

    const ExampleCustomInputEntrada = forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} ref={ref} className="form-control" style={{ height: "40px", display: "flex", justifyContent: "center" }}>
            <i className="premium-title-icon premium-svg-nodraw premium-drawable-icon fa-solid fa-calendar-alt" aria-hidden="true" style={{ color: "#fff" }}></i>
            <input type="text" name="daterange" placeholder={t("Entrada - Salida")} className="cpointer react-datepicker-ignore-onclickoutside datesportada" defaultValue={value} style={{ width: "156px", color: "white", background: "rgb(111,111,111)", border: "none", textAlign: "center", fontFamily: "'Lato', sans-serif", fontWeight: "300", letterSpacing: "1px" }} autoComplete="off" ></input>
        </div>
    ));

    {/* ******************* */ }
    return (
        <Container fluid className="no-padding cabeceratop" style={{ display: "flex", flexDirection: "column" }}>
            <div className="sobrefondo">
                <figure className="fondocabecera" style={{ backgroundImage: 'url(' + imagenfondo + ')', backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", transformOrigin: "center" }}
                ></figure>
            </div>
            <div className="container caixa-home">
                <h2 className="letrablanca" style={{ marginBottom: "16px" }}>
                    <span className="mayusculacss" style={{ lineHeight: "40px", fontFamily: "'Lato',sans-serif", fontWeight: "300", textAlign: "center", fontSize: "33px" }}>{elementosweb.cabecera_buscador[lang]}</span>
                </h2>
                <h4 className="letrablanca" style={{ textAlign: "center", fontFamily: "'Lato', sans-serif", fontWeight: "300", letterSpacing: "3px", marginBottom: "51px" }}>
                    <span style={{ border: "1px solid #fff", padding: "8px 5px", fontSize: "12px" }}>{t("SEVILLA")}</span>
                </h4>

                <form method="get" action={"/" + preidioma + t("busca")} style={{ background: "rgba(0,0,0,.5)", padding: "10px" }}>
                    <div className="row">
                        {isMobile ? null :
                            <div className="col-md-3 buscador-inputs caixainput solodesktop">
                                <DatePicker
                                    isClearable={false}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    className="form-control cpointer"
                                    autoComplete="off"
                                    onInputClick={() => setIsOpenDP(true)}
                                    onClickOutside={() => setIsOpenDP(false)}
                                    open={isOpenDP}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange={true}
                                    monthsShown={2}
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<ExampleCustomInputEntrada />}
                                    minDate={new Date()}
                                    placeholderText={t("Entrada - Salida")}
                                    locale={lang}
                                    name="daterange"
                                    popperClassName="calendarioportada"
                                    popperPlacement="bottom"
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: true // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                >
                                </DatePicker>
                            </div>
                        }
                        {isMobile &&
                            <div className="col-md-3 buscador-inputs caixainput solomobile">
                                <DatePicker
                                    portalId="root-portal"
                                    isClearable={false}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    className="form-control cpointer"
                                    autoComplete="off"
                                    startDate={startDate}
                                    endDate={endDate}
                                    onInputClick={() => setIsOpenDPM(true)}
                                    onClickOutside={() => setIsOpenDPM(false)}
                                    open={isOpenDPM}
                                    selectsRange={true}
                                    monthsShown={1}
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<ExampleCustomInputEntrada />}
                                    minDate={new Date()}
                                    placeholderText={t("Entrada - Salida")}
                                    locale={lang}
                                    name="daterange"
                                    popperClassName="calendarioportadam"
                                    popperPlacement="top"
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                >
                                </DatePicker>
                            </div>
                        }
                        <div className="col-md-3 buscador-inputs caixainput">
                            <div onClick={handleShow} className="form-control cabecerapersonas mw201" style={{ cursor: "pointer" }}>
                                <i className="premium-title-icon premium-svg-nodraw premium-drawable-icon far fa-user" aria-hidden="true" style={{ marginTop: "0px", color: "#fff", paddingRight: "5px" }}></i>
                                <span ref={numpersonas} style={{ textAlign: "center", fontFamily: "'Lato', sans-serif", fontSize: "11px", fontWeight: "300", letterSpacing: "1px" }}>{"1 " + t("Persona")}</span>
                            </div>


                            <div className="formpersonas" style={{ display: show }} tabIndex="0" onMouseLeave={() => { document.addEventListener("click", onClickOutsideListener) }}>

                                <div className="mt-2 row">
                                    <label className="col-md-6 col-6 etiqueta-formulari">
                                        {t("Adultos")}
                                        <div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div>
                                    </label>
                                    <div className="col-md-5 col-6">
                                        <div className="mt-1 input-group">
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                    <span className="centrar-boto-cercle">
                                                        <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                    </span>
                                                </button>
                                            </span>
                                            <input type="text" name="adultos" className="form-control input-number numero-persones" value={countAdults} min="1" max="10" id="numadultos" readOnly={true} />
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                    <span className="centrar-boto-cercle">
                                                        <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                        </svg>
                                                    </span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-md-6 col-6 etiqueta-formulari etiqueta_formulari2"> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                    <div className="col-md-5 col-6">
                                        <div className="mt-1 input-group">
                                            <span className="input-group-btn">
                                                <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                    <span className="centrar-boto-cercle">
                                                        <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                    </span>
                                                </button>
                                            </span>
                                            <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max="10" id="numninos" readOnly={true} />
                                            <span className="input-group-btn">
                                                <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                    <span className="centrar-boto-cercle">
                                                        <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                        </svg>
                                                    </span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-md-6 col-6 etiqueta-formulari etiqueta_formulari2"> {t("Bebés")}<div className="mini-etiqueta-formulari">( {t("De_0_a_2_años")})</div></label>
                                    <div className="col-md-5 col-6">
                                        <div className="mt-1 input-group">
                                            <span className="input-group-btn">
                                                <button className="btn btn-xs btn-number boto-cercle" type="button" datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                    <span className="centrar-boto-cercle">
                                                        <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                        </svg>
                                                    </span>
                                                </button>
                                            </span>
                                            <input type="text" name="bebes" className="form-control input-number numero-persones" value={countBebes} min="0" max="10" id="numbebes" readOnly={true} />
                                            <span className="input-group-btn">
                                                <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                    <span className="centrar-boto-cercle">
                                                        <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                        </svg>
                                                    </span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3 buscador-inputs botocercacap">
                            <Button variant="primary" size="md" type="submit" style={{ background: "#c19b77", border: "1px solid #c19b77", color: "#FFF", textAlign: "center", fontFamily: "'Lato', sans-serif", fontSize: "12px", fontWeight: "300", letterSpacing: "1px" }}>
                                {t("Buscar").toUpperCase()}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>

        </Container>
    );
}
export default Cabecera;
