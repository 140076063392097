import { React, useRef } from "react";

import { useTranslation } from 'react-i18next';

import axios from 'axios';


const TextosPagoResto = ({ datoscliente, datospropiedad, reserva, totalesreserva, restoapagar, config, tpvs, paycomet, tipopago, lang, ruta, moneda, transferencia }) => {
    const { t } = useTranslation();

    const handleChangePago = () => {

        var element = document.querySelector("input[type='radio'][name='payments']:checked");
        var idtpv = element.getAttribute('data-idtpv');
        var incremento = element.getAttribute("data-incremento");
        var tipoincremento = element.getAttribute("data-tipoincremento");
        var restoapagar0 = restoapagar;
        var nombrecliente = datoscliente.nombre;
        var apellidoscliente = datoscliente.apellidos;
        var emailcliente = datoscliente.email
        var telefonocliente = datoscliente.telefono;
        var idpropiedad = datospropiedad.id;
        var idreserva = reserva.id;
        var tipopago0 = tipopago;
        var lang0 = lang;
        var webfiscal = config.web_fiscal;
        var pagado = totalesreserva['Prepago_Reserva'];
        var moneda = 'EUR';
        var restototal = totalesreserva['Total_Reserva'] + totalesreserva['Prepago_Reserva'];
        var totalreserva = totalesreserva['Total_Reserva']
        //var idpolitica  = '<?php echo $idpolitica;?>';
        var arrival = reserva.inicio;

        let token = document.getElementById('meta_token').getAttribute('content');

        switch (element.value) {

            case 'paymentsCreditCard':
                //document.querySelector(".pagostripe").style.display = 'none';
                axios.post('/paybuttontpv', {
                    idtpv: idtpv,
                    incremento: incremento,
                    tipoincremento: tipoincremento,
                    restoapagar: restoapagar,
                    nombrecliente: nombrecliente,
                    apellidoscliente: apellidoscliente,
                    idpropiedad: idpropiedad,
                    idreserva: idreserva,
                    tipopago: tipopago,
                    lang: lang,
                    webfiscal: webfiscal,
                    pagado: pagado,
                    bizum: 0
                }
                    , {
                        headers: {
                            'content-type': 'text/json',
                            'X-CSRF-TOKEN': token
                        }
                    })
                    .then((response) => {
                        document.getElementById("payButton").innerHTML = response.data;
                    })
                break;

            case 'paymentsBizum':
                //document.querySelector(".pagostripe").style.display = 'none';
                axios.post('/paybuttontpv', {
                    idtpv: idtpv,
                    incremento: incremento,
                    tipoincremento: tipoincremento,
                    restoapagar: restoapagar,
                    nombrecliente: nombrecliente,
                    apellidoscliente: apellidoscliente,
                    idpropiedad: idpropiedad,
                    idreserva: idreserva,
                    tipopago: tipopago,
                    lang: lang,
                    webfiscal: webfiscal,
                    pagado: pagado,
                    bizum: 1
                },
                    {
                        headers: {
                            'content-type': 'text/json',
                            'X-CSRF-TOKEN': token
                        }
                    })
                    .then((response) => {
                        document.getElementById("payButton").innerHTML = response.data;
                    })
                break;

            case 'paymentPaypal':
                //document.querySelector(".pagostripe").style.display = 'none';
                axios.post('/paybuttonpaypal', {
                    restoapagar: restoapagar,
                    idreserva: idreserva,
                    idpropiedad: idpropiedad,
                    nombrecliente: nombrecliente,
                    apellidoscliente: apellidoscliente,
                    moneda: moneda,
                    webfiscal: webfiscal,
                    lang: lang,
                    pagado: pagado
                },
                    {
                        headers: {
                            'content-type': 'text/json',
                            'X-CSRF-TOKEN': token
                        }
                    })
                    .then((response) => {
                        document.getElementById("payButton").innerHTML = response.data;
                    })
                break;
            case 'paymentStripe':
                document.getElementById("payButton").innerHTML = response.data;
                document.querySelector(".pagostripe").style.display = "";

                break;
            case 'paymentPaycomet1':
            case 'paymentPaycomet10':
            case 'paymentPaycomet11':
                axios.post('/paybuttonpaycomet', {
                    restoapagar: restoapagar,
                    idreserva: idreserva,
                    idpropiedad: idpropiedad,
                    nombrecliente: nombrecliente,
                    apellidoscliente: apellidoscliente,
                    moneda: moneda,
                    webfiscal: webfiscal,
                    lang: lang,
                    pagado: pagado,
                    tipopago: element.value.replace("paymentPaycomet", "")
                },
                    {
                        headers: {
                            'content-type': 'text/json',
                            'X-CSRF-TOKEN': token
                        }
                    })
                    .then((response) => {
                        document.getElementById("payButton").innerHTML = response.data;
                    })
                break;

            case 'paymentTransferencia':
                axios.post('/paybuttontransfer', {
                    restoapagar: restoapagar,
                    idreserva: idreserva,
                    idpropiedad: idpropiedad,
                    nombrecliente: nombrecliente,
                    apellidoscliente: apellidoscliente,
                    emailcliente: emailcliente,
                    moneda: moneda,
                    webfiscal: webfiscal,
                    lang: lang,
                    pagado: pagado,
                    token: token
                },
                    {
                        headers: {
                            'content-type': 'text/json',
                            'X-CSRF-TOKEN': token
                        }
                    })
                    .then((response) => {
                        document.getElementById("payButton").innerHTML = response.data;
                    })
                break;
        }
    };

    let lostpvs = [];

    return (
        <div className="about-us content-area-7">
            <div className="container">
                <div className="mt-4 row">
                    <div className="col-lg-12 align-self-center mt-30">
                        <div className="mb-3 row">
                            <div className="col-md-6">
                                <h3 class="mb-3"> {t("Querido") + " " + datoscliente.nombre + " " + datoscliente.apellidos}</h3>
                                <h4 class="mb-3"> {t("Sus datos de reserva son")}: </h4>
                                <div className="row">
                                    <div className="mb-3 col-md-6"><strong>{t("Propiedad")}:</strong></div>
                                    <div className="mb-3 col-md-6">{datospropiedad.nombre}</div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6"><strong>{t("Check-in")}:</strong></div>
                                    <div className="mb-3 col-md-6">{reserva.inicio}</div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6"><strong>{t("Check-out")}:</strong></div>
                                    <div className="mb-3 col-md-6">{reserva.final}</div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6"><strong>{t("Reserva")}:</strong></div>
                                    <div className="mb-3 col-md-6"> {totalesreserva['Total_Reserva'] / 100 + " " + moneda}  </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-6"><strong>{t("Pagado")}:</strong></div>
                                    <div className="mb-3 col-md-6">  {totalesreserva['Prepago_Reserva'] / 100 + " " + moneda}</div>
                                </div>
                                {
                                    (totalesreserva['Total_Descuento'] != 0) ?
                                        <div className="row">
                                            <div className="mb-3 col-md-6"><strong>{t("Descuento")}:</strong></div>
                                            <div className="mb-3 col-md-6">{totalesreserva['Total_Descuento'] + " " + moneda} </div>
                                        </div>
                                        :
                                        null
                                }
                                <div className="row">
                                    <div className="mb-3 col-md-6">
                                        <strong>{t("A pagar ahora")}:</strong>
                                    </div>
                                    <div className="mb-3 col-md-6">{restoapagar + " " + moneda} </div>
                                </div>
                                <div id="paymentOption" className="mt-40 payment-option-wrapper">
                                    <div className="row">
                                        {
                                            config.activa_tpv === 1 && tpvs.length > 0 ?
                                                tpvs.map((untpv, index) => {
                                                    lostpvs = lostpvs.concat(
                                                        <>
                                                            <div className='col-6' key={"t" + index}>
                                                                <div className='radio-block font-icon-radio'>
                                                                    <input className='payments1 radio' name='payments' id={"payments-1"} type='radio' value='paymentsCreditCard' required data-incremento={untpv.markup ? untpv.markup.incremento : 0} data-idtpv={untpv.id} style={{ float: "left", width: "18px", marginTop: "14px", visibility: "visible" }} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0} onClick={handleChangePago} />
                                                                    <label htmlFor={"payments-1"} style={{ position: "relative", paddingLeft: "20px" }}>
                                                                        <span>{untpv.nombre_comercio} <i className="fa-duotone fa-credit-card fa-xl"></i></span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            {
                                                                untpv.Bizum == 1 ?
                                                                    <>
                                                                        <div className='col-6' key={"b" + index}>
                                                                            <div className='radio-block font-icon-radio'>
                                                                                <input className='payments1 radio' name='payments' id={"paymentsz-1"} type='radio' value='paymentsBizum' required data-incremento={untpv.makup ? untpv.markup.incremento : 0} data-idtpv={untpv.id} style={{ float: "left", width: "18px", visibility: "visible", marginTop: "14px" }} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0} onClick={handleChangePago} />
                                                                                <label htmlFor={"paymentsz-1"} style={{ position: "relative", paddingLeft: "20px" }}>
                                                                                    <span>Bizum <i className="fa-duotone fa-credit-card fa-xl"></i></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                    )
                                                })
                                                :
                                                lostpvs = lostpvs.concat(<></>)
                                        }
                                        {lostpvs}

                                        {
                                            config.activa_paypal === 1 ?
                                                <>
                                                    <div className="col-6">
                                                        <div className="radio-block font-icon-radio">
                                                            <input id="payments2" name="payments" type="radio" className="radio" value="paymentPaypal" required style={{ float: "left", width: "18px", visibility: "visible", marginTop: "14px" }} onClick={handleChangePago} />

                                                            <label htmlFor="payments2" style={{ position: "relative", paddingLeft: "20px" }}>
                                                                <span>{t("Paypal")} <i className="fa-brands fa-paypal fa-xl"></i></span>

                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            config.activa_paycomet === 1 && paycomet ?
                                                <>
                                                    {
                                                        Object.keys(paycomet.metodos_pago).map((key) => (

                                                            <div className="col-6" key={"pc" + key}>
                                                                <div className="radio-block font-icon-radio">
                                                                    <input id="payments6" name="payments" type="radio" className="radio" value={"paymentPaycomet" + key} required style={{ float: "left", width: "18px", visibility: "visible", marginTop: "14px" }} onClick={handleChangePago} />
                                                                    <label htmlFor="payments6" style={{ position: "relative", paddingLeft: "20px" }}>
                                                                        <span>
                                                                            {paycomet.metodos_pago[key].nombre}
                                                                            {
                                                                                paycomet.metodos_pago[key].nombre === "Tarjeta" ?
                                                                                    <i className="fa-brands fa-cc-visa fa-xl ms-2"></i>
                                                                                    :
                                                                                    paycomet.metodos_pago[key].nombre === "PayPal" ?
                                                                                        <i className="fa-brands fa-paypal fa-xl ms-2"></i>
                                                                                        :
                                                                                        <i className="fa-duotone fa-mobile fa-xl ms-2"></i>
                                                                            }
                                                                        </span>

                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        paycomet.apple_pay === 1 ?
                                                            <div className="col-lg-6 col-md-12">
                                                                <div className="radio-block font-icon-radio">
                                                                    <input id="payments6" name="payments" type="radio" className="radio payments6" value="paymentPaycomet1" required="" style={{ float: "left", width: "18px", visibility: "visible", marginTop: "14px" }} onClick={handleChangePago} />
                                                                    <label htmlFor="payments6" style={{ position: "relative", paddingLeft: "20px" }}>
                                                                        <span>
                                                                            {t("Apple")} <i className="fa-brands fa-apple-pay fa-2xl ms-2"></i>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        paycomet.google_pay === 1 ?
                                                            <div className="col-lg-6 col-md-12">
                                                                <div className="radio-block font-icon-radio">
                                                                    <input id="payments6" name="payments" type="radio" className="radio payments6" value="paymentPaycomet1" required="" style={{ float: "left", width: "18px", visibility: "visible", marginTop: "14px" }} onClick={handleChangePago} />
                                                                    <label htmlFor="payments6" style={{ position: "relative", paddingLeft: "20px" }}>
                                                                        <span>
                                                                            {t("Google")} <i className="fa-brands fa-google-pay fa-2xl ms-2"></i>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            config.activa_transferencia === 1 && transferencia ?
                                                <>
                                                    <div className="col-6">
                                                        <div className="radio-block font-icon-radio">
                                                            <input id="payments4" name="payments" type="radio" className="radio" value="paymentTransferencia" required style={{ float: "left", width: "18px", visibility: "visible", marginTop: "14px" }} onClick={handleChangePago} />

                                                            <label htmlFor="payments2" style={{ position: "relative", paddingLeft: "20px" }}>
                                                                <span>{transferencia.nombre_web} <i className="fa-duotone fa-building fa-xl"></i></span>

                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }

                                        {/*
                                            config.activa_stripe == '1' ?
                                                <>
                                                    <div className = "col-sm-12">
                                                        <div className = "radio-block font-icon-radio">
                                                            <input id = "payments3" name = "payments" type = "radio" className = "radio" value = "paymentStripe" required style={{ float: "left", width:"18px", visibility:"visible" }} onClick={handleChangePago}/>

                                                            <label htmlFor = "payments3" style={{ position: "relative", paddingLeft: "20px" }}>
                                                                <span> {t("Stripe")} <i className="fa-brands fa-stripe"></i></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='mb-20 clear'></div>
                                                </>
                                            :
                                            null
                                            */
                                        }
                                    </div>
                                    <div id="payButton"></div>

                                    { /*
                                        config.activa_stripe == '1' ?
                                            <span className="pagostripe" style={{ display: "none"}}>
                                                <form id='payment-form-reto'>
                                                    <div>
                                                        <label for='card-element'>{ t("Datos de tarjeta")}</label>
                                                        <div id='card-element'>

                                                        </div>

                                                        <div id='card-errors' role='alert'></div>
                                                    </div>
                                                    <div style={{ marginTop: "15px!important"}}>
                                                        <button className='btn btn-lg btn-icon' id='submitresto' style={{backgroundColor: "#556cd6", borderColor: "#556cd6", color:"white"}} data-secret='".$intent->client_secret."'>
                                                            <span>{ t("Confirmar y pagar") }</span>
                                                            (
                                                                <span id='card_data'>{ restoapagar}</span>
                                                                <span style={{ fontWeight: "600"}}>€</span>
                                                            )
                                                            <span className='icon'><i className='fa fa-angle-right' aria-hidden='true'></i></span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </span>
                                        :
                                        null
                                        */
                                    }
                                </div>


                            </div>

                            <div className="col-md-6">
                                {
                                    datospropiedad.foto_principal.length != 0
                                        ? <img src={ruta + "/propiedades/" + datospropiedad.id + "/fotos/web/" + datospropiedad.foto_principal[0].nombre} alt="--" title="" className="mt-1 imagereservation w100" />
                                        : <img src="/images/web/bedloop-nofoto.png" alt="--" title="" className="mt-1 imagereservation w100" />
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TextosPagoResto;
