import { React } from "react";

const CajaImagen = ({ undestino, preidioma, ruta, t, className, aClass, index }) => {
    return (
        <a href={`/${preidioma}${t("embarcaciones")}-${undestino.laurl}`} className={`caixa-destins ${aClass}`} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} key={"destino" + index}>
            {
                undestino.imagen_principal.length > 0 ?
                    <img
                        className={`${className}`}
                        src={`${ruta}/destinos/${undestino.id}/${undestino.imagen_principal[0].imagen}`}
                        alt={undestino.elnombre}
                    />
                    :
                    <img
                        className={`${className}`}
                        src="/images/web/bedloop-nofoto.png"
                        alt={undestino.elnombre}
                    />
            }
            <span className="sobreimagen">
                {undestino.elnombre}
                <br />{undestino.cuantasrutas} {(undestino.cuantasrutas > 1 || undestino.cuantasrutas == 0) ? t("Rutas") : t("Ruta")}
            </span>
        </a>
    );
};

export default CajaImagen;


