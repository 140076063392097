
import { React } from "react";

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextosConfirmadaReserva = ({ lang, datostransferencia }) => {
    const { t } = useTranslation();
    return (
        <div className="container mt-5">
            <h3 className="centra">{t("Gracias por su reserva")}</h3>
            <h4 className="centra">{t("Recuerde completar el pago en la siguente cuenta")}:</h4>
            <div className="centra">
                {ReactHtmlParser(datostransferencia)}
            </div>
        </div>
    );
}

export default TextosConfirmadaReserva;
