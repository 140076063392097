import { React } from "react";

import { useTranslation } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser';

const TextosClientes = ({ clientes, ruta, config }) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

    if (clientes.imagen_cabecera) {
        imagen = ruta + "/" + clientes.imagen_cabecera;
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="imatge-banner" title={t("Clientes")} style={{ backgroundImage: "url('" + imagen + "')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover" }}></div>
                        <h1 className="noticia-individual mb-5">{t("Clientes")}</h1>
                        <div className="noticia-individual-descripcio-llarga mb-5">
                            {ReactHtmlParser(clientes.clientes)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextosClientes;
