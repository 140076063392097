import Menu from './Compartido/Menu.jsx';
import TextosPreautorizar from './Preautorizar/TextosPreautorizar.jsx';
import Footer from './Compartido/Footer.jsx';

import { useTranslation } from 'react-i18next';

const Preautorizar = (props) => {

    let { t } = useTranslation();

    return (
        <main>
            <Menu
                idiomas={props.idiomas}
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                ruta={props.ruta}
                meta_titulo={"Magno Apartments - " + t("Pago resto")}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
            />
            <TextosPreautorizar
                datoscliente={props.datoscliente}
                datospropiedad={props.datospropiedad}
                reserva={props.reserva}
                fianza={props.fianza}
                config={props.config}
                tpvs={props.tpvs}
                lang={props.lang}
                ruta={props.ruta}
                moneda={props.moneda}
                params={props.params}
                version={props.version}
                signature={props.signature}
                urlpago={props.urlpago}
                colorboton={props.colorboton}
            />
            <Footer
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                idiomas={props.idiomas}
                ruta={props.ruta}
            />
        </main>
    );
}
export default Preautorizar;
