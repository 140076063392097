import React, { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';

const Buscador = ({ lang, adultos, ninos, bebes, mascotas, llegada, salida, capacidad_maxima }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    registerLocale('es', es);

    let entr = llegada != null ? parseISO(llegada) : null;
    let sali = salida != null ? parseISO(salida) : null;

    const [dateRange, setDateRange] = useState([entr, sali]);

    const [startDate, endDate] = dateRange;

    {/* ********************* */ }

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));
    const [countMascotas, setCountMascotas] = useState(parseInt(mascotas));

    const handleSumaAdultos = () => {

        if (countAdults + countNinos < capacidad_maxima) {
            setCountAdults(ca => ca + 1);
        }
    }

    const handleRestaAdultos = () => {

        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);

        }
    }

    const handleSumaNinos = () => {
        if (countNinos + countAdults < capacidad_maxima) {
            setCountNinos(cn => cn + 1);
        }
    }

    const handleRestaNinos = () => {
        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
        }
    }

    const handleSumaBebes = () => {
        if (countBebes < capacidad_maxima) {
            setCountBebes(cb => cb + 1);
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
        }
    }

    const handleSumaMascotas = () => {

        if (countMascotas < capacidad_maxima) {
            setCountMascotas(cm => cm + 1);
        }
    }

    const handleRestaMascotas = () => {
        if (countMascotas > 0) {
            setCountMascotas(cm => cm - 1);
        }
    }

    return (
        <div className="sticky sub-header filters-room">
            <div className="container">
                <form
                    className="bottom-panel__wrap d-flex"
                    method="get"
                    id="busqueda0"
                    action={"/" + preidioma + t("buscar")}
                >
                    <div className="row">
                        <div className="mb-2 col-12 col-md-12 col-lg-12 col-xl-4 mb-md-2 mb-xl-0">
                            {/* Dates */}
                            <div className="form-group">
                                <div className="form-dual">
                                    <span className="fa-regular fa-calendar" style={{ marginLeft: "20px" }} />
                                    <DatePicker
                                        isClearable={true}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange={true}
                                        monthsShown={2}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        placeholderText={t("Entrada - Salida")}
                                        locale={lang}
                                        name="daterange"
                                        className="inputText inputText__icon readonly js-datepicker flatpickr-input"
                                        autoComplete="off"
                                    >
                                    </DatePicker>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 col-12 col-md-12 col-lg-6 col-xl-4">
                            {/* Persons */}
                            <div className="form-group form-dual">
                                <div className="js-quantity form-dual__left" style={{ borderRight: "1px solid", borderLeft: "0", borderBottom: "0", borderTop: "0", borderRadius: "0" }}>
                                    <label className="label" htmlFor="person-adult" style={{ marginTop: "5px" }}>
                                        {t("Adultos")}:
                                    </label>
                                    <div className="position-relative w-100 row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleRestaAdultos}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <span className="col-4">
                                            <input
                                                type="number"
                                                className="inputText js-quantity-input readonly"
                                                id="person-adult"
                                                name="adultos"
                                                min={1}
                                                max={capacidad_maxima}
                                                readOnly="readonly"
                                                value={countAdults}
                                            />
                                        </span>
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleSumaAdultos}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="js-quantity form-dual__right" style={{ border: "0" }}>
                                    <label className="label" htmlFor="person-kids" style={{ marginTop: "5px" }}>
                                        {t("Niños")}:
                                    </label>
                                    <div className="position-relative w-100 row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleRestaNinos}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <span className="col-4">
                                            <input
                                                type="number"
                                                className="inputText js-quantity-input readonly"
                                                id="person-kids"
                                                name="ninos"
                                                min={0}
                                                max={capacidad_maxima}
                                                readOnly="readonly"
                                                value={countNinos}
                                            />
                                        </span>
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleSumaNinos}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 col-12 col-md-12 col-lg-6 col-xl-4 mb-md-0">
                            {/* Persons */}
                            <div className="form-group form-dual" >
                                <div className="js-quantity form-dual__left" style={{ borderRight: "1px solid", borderLeft: "0", borderBottom: "0", borderTop: "0", borderRadius: "0" }}>
                                    <label className="label" htmlFor="person-bebes" style={{ marginTop: "5px" }}>
                                        {t("Bebés")}:
                                    </label>
                                    <div className="position-relative w-100 row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleRestaBebes}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <span className="col-4">
                                            <input
                                                type="number"
                                                className="inputText js-quantity-input readonly"
                                                id="person-bebes"
                                                name="bebes"
                                                min={0}
                                                max={capacidad_maxima}
                                                readOnly="readonly"
                                                value={countBebes}
                                            />
                                        </span>
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleSumaBebes}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="js-quantity form-dual__right" style={{ border: "0" }}>
                                    <label className="label" htmlFor="person-pets" style={{ marginTop: "5px" }}>
                                        {t("Mascotas")}:
                                    </label>
                                    <div className="position-relative w-100 row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleRestaMascotas}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <span className="col-4">
                                            <input
                                                type="number"
                                                className="inputText js-quantity-input readonly"
                                                id="person-pets"
                                                name="mascotas"
                                                min={0}
                                                max={capacidad_maxima}
                                                readOnly="readonly"
                                                value={countMascotas}
                                            />
                                        </span>
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleSumaMascotas}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 col-12 col-md-12 col-lg-12 col-xl-12 mb-md-0">
                            <div className="form-group">
                                <div
                                    className="js-quantity form-dual__right"
                                    style={{ marginTop: 10, display: "flex", width: "100%", border: "0" }}
                                >
                                    <div className="position-relative w-100">
                                        <button
                                            className="d-flex btn black-text sendform"
                                            style={{
                                                justifyContent: "center",
                                                display: "flex!important",
                                                margin: "0 auto"
                                            }}
                                            type="submit"
                                        >
                                            {t("Buscar")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    );
};
export default Buscador;
