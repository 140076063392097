import Menu from './Compartido/Menu.jsx';
import TextosCanceladoTpv from './CanceladoTpv/TextosCanceladoTpv.jsx';
import Footer from './Compartido/Footer.jsx';

const CanceladoStripe = (props) => {
    return (
        <main>
            <Menu
                idiomas={props.idiomas}
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                ruta={props.ruta}
                meta_titulo={"Magno Apartments - " + t("Cancelado")}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
            />
            <TextosCanceladoTpv />
            <Footer
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                idiomas={props.idiomas}
                ruta={props.ruta}
            />
        </main>
    )
}

export default CanceladoStripe;
