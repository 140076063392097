import { React } from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextosPoliticaPrivacidad = ({ ruta, politicaprivacidad }) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

    if (politicaprivacidad.imagen_cabecera) {
        imagen = ruta + "/" + politicaprivacidad.imagen_cabecera;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={t("Política de privacidad")} style={{
                        backgroundImage: "url('" + imagen + "')", backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center', backgroundSize: "cover"
                    }} >
                    </div>
                    <h1 className="mt-5 mb-5 noticia-individual">{t("Política de privacidad")}</h1>
                    <div className="mb-5 noticia-individual-descripcio-llarga">
                        {ReactHtmlParser(politicaprivacidad.ladesc)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TextosPoliticaPrivacidad;
