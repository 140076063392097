import { React, useState } from "react";
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';


const FormularioLogin = ({ lang, config }) => {

    const { t } = useTranslation();

    const [dataRes, setDataRes] = useState("<p></p>");

    {/* *************** */ }

    const handleSubmit = (e) => {

        e.preventDefault();
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/dologin', {
            lang: e.target.lang.value,
            user: e.target.user.value,
            password: e.target.password.value
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.type == "success") {
                    setDataRes("<p style='color:green'>" + solucion.message + "</p>");
                }
                else {
                    setDataRes("<p  style='color:red'>" + solucion.message + "</p>");
                }

            });
    }

    {/* *************** */ }

    return (
        <>
            <section className="mt-5 mb-5">
                <div className="container bg-white shadow mt-5 mb-5 br15 col-sm-5">
                    <div className="row noticia-individual noticia-individual-descripcio-llarga mx-auto col-sm-5">
                        <h2 className="noticia-individual mb-5 mt-5 border-bottom pb-3">{t("Login")}</h2>
                        <form className="noticia-individual" id="contactForm" onSubmit={handleSubmit}>
                            <input type="hidden" name="lang" value={lang.toString().toUpperCase()} />
                            <div className="row">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder={t("Usuario")} name="user" required="" />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder={t("Password")} name="password" required="" />
                                </div>
                                <div className="pull-right mt-4 mb-5">
                                    <input type="submit" className="btn btn-danger" value={t("Enviar")} style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }} />
                                </div>
                            </div>
                        </form>
                        <div>
                            <div>
                                {ReactHtmlParser(dataRes)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <script>

            </script>
        </>
    );
}


export default FormularioLogin;
