import { React } from "react";
import { useTranslation } from 'react-i18next';

const Ventajas = ({ ventajas, ruta }) => {

    const { t } = useTranslation();

    return (
        <>
            {
                ventajas.length > 0 ?
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="mb-5 noticia-individual">{t("Ventajas")}</h1>
                            </div>
                            {ventajas.map((ventaja) => (
                                <div className="col-lg-3 mb-30" style={{ textAlign: "center" }} key={ventaja.id}>
                                    {" "}
                                    <img
                                        className="img-fluid"
                                        src={`${ruta}/ventajas/${ventaja.id}/${ventaja.imagen}`}
                                    />
                                    {" "}
                                    <h3 className="mt-20 nombrepersona mb-30">{ventaja.elnombre}</h3>{" "}
                                    <div className="text-justify mb-30 fs14" dangerouslySetInnerHTML={{ __html: ventaja.eltexto }}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    ""
            }
        </>
    );
}

export default Ventajas;
