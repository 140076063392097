import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import BotonWhatsapp from "./BotonWhatsapp";


const Footer = ({ datos, terminos, protocolos, avisolegal, config, elementosweb, politicaprivacidad, lang, ruta, logos_pie, websgrupo, numfaqs }) => {

    const { t } = useTranslation();

    var currentTime = new Date();
    var year = currentTime.getFullYear();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            <footer className={"footer bg-white pt-5 potblur"}>
                <Container>
                    <div className="row justify-content-center">
                        <div className="col-md-2">
                            {datos.logo_fiscal != "" ? <img className="logo-footer" alt={datos.nombre_comercial} title={datos.nombre_comercial} src={ruta + "/web/logo/" + datos.logo_fiscal} /> : <img className="logo" alt="BedLoop" title="BedLoop" src="https://www.bedloop.com/imatges/logo.webp" />}
                        </div>
                        <div className="col-md-8">
                            <ul className="list-inline">
                                <li className="list-inline-item">{datos.nombre_comercial}</li>
                                <li className="list-inline-item">{datos.nombre_fiscal}</li>
                                <li className="list-inline-item">{datos.direccion_fiscal}</li>
                                <li className="list-inline-item">
                                    {datos.codigo_postal_fiscal + " " + datos.poblacion_fiscal}
                                </li>
                                <li className="list-inline-item">{datos.nif_fiscal}</li>
                                <li className="list-inline-item">&copy; {year}</li>
                            </ul>
                        </div>

                        <div className="col-md-2">
                            <ul className="no-padding">
                                {datos.url_facebook && datos.url_facebook != "" ? <li><a href={datos.url_facebook}><i className="fa-brands fa-facebook" target="_blank"></i> Facebook</a></li> : ''}
                                {datos.url_twitter && datos.url_twitter != "" ? <li><a href={datos.url_twitter}><i className="fa-brands fa-twitter" target="_blank"></i> Twitter</a></li> : ''}
                                {datos.url_instagram && datos.url_instagram != "" ? <li><a href={datos.url_instagram}><i className="fa-brands fa-instagram" target="_blank"></i> Instagram</a></li> : ''}
                            </ul>
                        </div>
                    </div>
                </Container>
                {
                    (logos_pie) ?
                        <Container>
                            <div className="row">
                                {
                                    (logos_pie.url_1 != "" && logos_pie.imagen_1 != "") ?
                                        <div className="col-3 mb-5">
                                            <a href={logos_pie.url_1} target="_blank">
                                                <img className="img-fluid w-50 mx-auto d-block" src={ruta + "/piedepagina/1/logos/1/" + logos_pie.imagen_1} />
                                            </a>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (logos_pie.url_2 != "" && logos_pie.imagen_2 != "") ?
                                        <div className="col-3 mb-5">
                                            <a href={logos_pie.url_2} target="_blank" >
                                                <img className="img-fluid w-50 mx-auto d-block" src={ruta + "/piedepagina/1/logos/2/" + logos_pie.imagen_2} />
                                            </a>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (logos_pie.url_3 != "" && logos_pie.imagen_3 != "") ?
                                        <div className="col-3 mb-5">
                                            <a href={logos_pie.url_3} target="_blank">
                                                <img className="img-fluid w-50 mx-auto d-block" src={ruta + "/piedepagina/1/logos/3/" + logos_pie.imagen_3} />
                                            </a>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (logos_pie.url_4 != "" && logos_pie.imagen_4 != "") ?
                                        <div className="col-3 mb-5">
                                            <a href={logos_pie.url_4} target="_blank">
                                                <img className="img-fluid w-50 mx-auto d-block" src={ruta + "/piedepagina/1/logos/4/" + logos_pie.imagen_4} />
                                            </a>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                        </Container>
                        :
                        ""
                }
            </footer>

            <CookieBanner
                message={t("Utilizamos cookies para ofrecerte una mejor experiencia de navegación, personalizar el contenido y los anuncios, proporcionar funciones de medios sociales y analizar nuestro tráfico.Aceptas nuestras cookies si continúas utilizando este sitio web. Lee sobre cómo utilizamos las cookies y cómo puedes controlarlas haciendo clic en el enlace siguiente:")}
                wholeDomain={true}
                privacyPolicyLinkText={t("Lee nuestra Política de cookies")}
                policyLink={"/" + preidioma + t("cookies")}
                necessaryOptionText={t("Necesarias")}
                preferencesOptionText={t("Rendimiento")}
                statisticsOptionText={t("Análisis")}
                marketingOptionText={t("Márqueting")}
                acceptAllButtonText={t("Aceptar todas")}
                acceptSelectionButtonText={t("Aceptar selección")}
                showAcceptSelectionButton={true}
                styles={{
                    dialog: { position: "fixed", top: "auto", left: "0px", right: "0px", zIndex: "100000", backgroundColor: "#FFF", padding: "10px", bottom: "0px", color: "#333" },
                    container: { width: "100%" },
                    message: { color: "#333", minHeight: "32px", fontSize: "10pt", fontWeight: "400", lineHeight: "130%", padding: "10px 20px" },
                    optionLabel: { color: "#333" },
                    selectPane: { padding: "3px 0px 10px 20px" },
                    checkbox: { left: "-5px", marginLeft: "10px", position: "relative" },
                    button: { color: "#fff", backgroundColor: websgrupo.color_corporativo_web, marginRight: "10px", padding: "3px 10px 3px 10px", borderRadius: "5px", border: "1px solid " + websgrupo.color_corporativo_web },
                    policy: { fontSize: "10pt", marginTop: "-9px", marginLeft: "10px", color: "#333", position: "absolute", textDecoration: "underline" }
                }}
            />

            <BotonWhatsapp
                websgrupo={datos}
            />

        </>
    );
};
export default Footer;

