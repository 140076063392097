import { React, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import LangSelector, { langSelector } from './LangSelector.jsx';
import { Helmet } from "react-helmet";

import "../../../../../../resources/css/web/interhome/estils.css";

const Menu = ({ sobrenosotros, clientes, propietarios, servicios, idiomas, lang, config, textopropiedades, ruta }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    useEffect(() => {

        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }
    }, []);

    return (
        <>
            {/* ********** */}

            <Helmet>
                <title>INTERHOME</title>
                <meta name="title" content="INTERHOME" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta charset="utf-8" />
            </Helmet>

            {
                (config.id === 1) ?
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/favicon.ico`}></link>
                    </Helmet>
                    :
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/${config.id}/favicon.ico`}></link>
                    </Helmet>
            }

            {
                (config.activa_analytics === 1) ?
                    <Helmet>
                        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.codigo_analytics}`}></script>
                        <script>
                            {`
                                            window.dataLayer = window.dataLayer || [];
                                            function gtag(){window && window.dataLayer && window.dataLayer.push(arguments)}
                                            gtag('js', new Date())
                                            gtag('config', '${config.codigo_analytics}');
                                        `}
                        </script>
                    </Helmet>
                    :
                    ""
            }

            {/* ********** */}

            <Navbar collapseOnSelect expand="lg" className="bg-body menu sticky-top">
                <Navbar.Brand href="/">
                    {config.logo_fiscal != "" ? <img className="logo" alt="Logo" title="Logo" src={ruta + "/web/logo/" + config.logo_fiscal} /> : <img className="logo" alt="Logo BedLoop" title="Logo BedLoop" src="https://www.bedloop.com/imatges/logo.webp" />}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link className="navegacio" href={"/" + preidioma}>{t("Inicio")}</Nav.Link>
                        <NavDropdown className="navegacio" title={t("Empresa")} id="collasible-nav-dropdown-empresa">
                            {sobrenosotros.ladesc != "" && sobrenosotros.ladesc != "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("sobre-nosotros")}>{t("Sobre_Nosotros")}</NavDropdown.Item> : ''}
                            {clientes.ladesc != "" && clientes.ladesc != "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("clientes")}>{t("Clientes")}</NavDropdown.Item> : ''}
                            {propietarios.ladesc != "" && propietarios.ladesc != "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("propietarios")}>{t("Propietarios")}</NavDropdown.Item> : ''}
                            <NavDropdown.Item href={"/" + preidioma + t("faqs")}>FAQ's</NavDropdown.Item>
                            {servicios.ladesc != "" && servicios.ladesc != "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("servicios")}>{t("Servicios")}</NavDropdown.Item> : ''}
                            {textopropiedades.ladesc != "" && textopropiedades.ladesc != "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("propiedades")}>{t("Propiedades")}</NavDropdown.Item> : ''}
                        </NavDropdown>
                        <NavDropdown className="navegacio" title={t("Ayuda")} id="collasible-nav-dropdown.ayuda">
                            <Container className="mt-1 mb-1 ajuda">
                                <Row className="ml-5">
                                    <Col xs="12" md="12" sm="6" className="mt-3">
                                        <p className="bold">{t("¿Alguna pregunta? ¡Hablemos!")}</p>
                                    </Col>
                                    <Col xs="12" md="12" sm="6">
                                        <i className="fa-duotone fa-square-phone"></i> {config.telefono_fiscal}<br /><br />
                                        <i className="fa-duotone fa-square-envelope" aria-hidden="true"></i> {config.email_fiscal}<br /><br />
                                        <a href="#"><i className="fa-duotone fa-location-dot" aria-hidden="true"></i> {config.direccion_fiscal + ", " + config.codigo_postal_fiscal + ", " + config.poblacion_fiscal}</a>
                                    </Col>
                                </Row>
                            </Container>
                        </NavDropdown>
                        <LangSelector
                            idiomas={idiomas}
                            langactual={lang.toString().toUpperCase()}
                        />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default Menu;
