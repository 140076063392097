import { React, useEffect, useState } from "react";
import { Row, Col, NavDropdown, Container, Navbar, Nav, DropdownDivider } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import { useTranslation } from 'react-i18next';

import LangSelector, { langSelector } from './LangSelector.jsx';

import "../../../../../css/web/motor/styles.css";

import { Helmet } from "react-helmet";

const Menu = ({ destinos, sobrenosotros, clientes, propietarios, servicios, experiencias, idiomas, lang, tiposcasas, config, textopropiedades, edificios, marcas, ruta, destinoslarga, meta_titulo, meta_descripcion, meta_keywords, numblog, numfaqs, paginapersonalizada }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const primeraLetraMayuscula = (cadena) => cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length).toLowerCase());

    useEffect(() => {

        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

    }, []);

    const dominiPrincipal = (url) => {
        const urlObj = new URL(url);
        const parts = urlObj.hostname.split('.');
        const mainDomain = parts.slice(-2).join('.');
        return `${urlObj.protocol}//${mainDomain}`;
    };

    return (
        <>
            {/* ********** */}

            <Helmet>
                <title>{`${meta_titulo}`}</title>
                <meta name="title" content={`${meta_titulo}`} />
                <meta name="description" content={`${meta_descripcion}`} />
                <meta name="keywords" content={`${meta_keywords}`} />
                <meta charset="utf-8" />
            </Helmet>

            {
                (config.id === 1) ?
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/favicon.ico`}></link>
                    </Helmet>
                    :
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/${config.id}/favicon.ico`}></link>
                    </Helmet>
            }

            {
                (config.activa_analytics === 1) ?
                    <Helmet>
                        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.codigo_analytics}`}></script>
                        <script>
                            {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){window && window.dataLayer && window.dataLayer.push(arguments)}
                                gtag('js', new Date())
                                gtag('config', '${config.codigo_analytics}');
                            `}
                        </script>
                    </Helmet>
                    :
                    ""
            }

            {/* ********** */}
            <Navbar collapseOnSelect expand="lg" className="bg-body sticky-top shadow p-3 mb-5 bg-body">
                <Container className="d-flex align-items-center">
                    <Navbar.Brand href={config.url && config.url.trim() !== "" ? dominiPrincipal(config.url) : "/"} className="">            {config.logo_fiscal !== "" ? (
                        <img
                            className="logo logobl1"
                            alt={config.nombre_comercial}
                            title={config.nombre_comercial}
                            src={`${ruta}/web/logo/${config.logo_fiscal}`}
                        />
                    ) : (
                        <img
                            className="logo logobl1"
                            alt="Logo BedLoop"
                            title="Logo BedLoop"
                            src="https://www.bedloop.com/imatges/logo.webp"
                        />
                    )}
                    </Navbar.Brand>
                    <div className="d-flex align-items-center">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="">
                                <NavDropdown
                                    className="navegacio"
                                    title={t("Ayuda")}
                                    id="collasible-nav-dropdown.ayuda"
                                >
                                    <Container className="mt-1 mb-1 ajuda">
                                        <Row className="ml-5">
                                            <Col xs="12" md="12" sm="6" className="mt-3">
                                                <p className="bold">{t("¿Alguna pregunta? ¡Hablemos!")}</p>
                                            </Col>
                                            <Col xs="12" md="12" sm="6">
                                                <i className="fa-duotone fa-square-phone"></i> {config.telefono_fiscal}<br /><br />
                                                <i className="fa-duotone fa-square-envelope" aria-hidden="true"></i> {config.email_fiscal}<br /><br />
                                                <a href="#"><i className="fa-duotone fa-location-dot" aria-hidden="true"></i> {config.direccion_fiscal + ", " + config.codigo_postal_fiscal + ", " + config.poblacion_fiscal}</a>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavDropdown>
                                <LangSelector
                                    idiomas={idiomas}
                                    langactual={lang.toString().toUpperCase()}
                                />
                                {/*<Nav.Link className="navegacio" href="/loginweb">{ t("Login") }</Nav.Link>*/}
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default Menu;
