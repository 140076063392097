import { React } from "react";
import { Col, Nav, Row, Tab } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextosLegal = ({ ruta, legal }) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

    if (legal.imagen_cabecera) {
        imagen = ruta + "/" + legal.imagen_cabecera;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={t("Legal")} style={{
                        backgroundImage: "url('" + imagen + "')", backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center', backgroundSize: "cover"
                    }} >
                    </div>
                    <h1 className="mt-5 mb-5 noticia-individual">{t("Legal")}</h1>
                    <div className="mb-5 noticia-individual-descripcio-llarga">
                        {ReactHtmlParser(legal.ladesc)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TextosLegal;
