import { React } from "react";

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextoProtocolos = ({ protocolos, ruta }) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

    if (protocolos.imagen_cabecera) {
        imagen = ruta + "/" + protocolos.imagen_cabecera;
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="imatge-banner" title={t("Protocolos")} style={{
                            backgroundImage: "url('" + imagen + "')", backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center', backgroundSize: "cover"
                        }} >
                        </div>
                        <h1 className="mt-5 mb-5 noticia-individual">{t("Protocolos")}</h1>
                        <div className="mb-5 noticia-individual-descripcio-llarga">
                            {ReactHtmlParser(protocolos.ladesc)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextoProtocolos;
