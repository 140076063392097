import { React } from "react";
import { useTranslation } from 'react-i18next';
import WidgetPropiedad from "../Compartido/WidgetPropiedad";

const PropiedadesBuscar = ({ lang, ruta, propiedades, adultos, ninos, bebes, llegada, salida, config, moneda }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    {/* ************ */ }

    return (
        <div className="pt-5" >
            <div className="destacats">
                {
                    propiedades.length > 0 ?
                        propiedades.map((propiedad, index) => (
                            <WidgetPropiedad
                                propiedad={propiedad}
                                ruta={ruta}
                                config={config}
                                lang={lang}
                                index={"d" + index}
                                key={"d" + index}
                                moneda={moneda}
                                adultos={adultos}
                                ninos={ninos}
                                bebes={bebes}
                                llegada={llegada}
                                salida={salida}
                            />
                        ))
                        :
                        <div className="centra">
                            {t("No se han encontrado resultados con las fechas suministradas. Vuelva a hacer la consulta con otras fechas")}
                        </div>
                }
            </div>
        </div>
    );
};

export default PropiedadesBuscar;

