import Menu from './Compartido/Menu.jsx';
import TextosPagoResto from './PagoResto/TextosPagoResto.jsx';
import Footer from './Compartido/Footer.jsx';

const PagoResto = (props) => {
    return (
        <main>
            <Menu
                destinos={props.destinos}
                sobrenosotros={props.textosobrenosotros}
                clientes={props.textoclientes}
                propietarios={props.textopropietarios}
                servicios={props.textoservicios}
                experiencias={props.experiencias}
                idiomas={props.idiomas}
                lang={props.lang}
                tiposcasas={props.tiposcasas}
                config={props.websgrupo}
                textopropiedades={props.textopropiedades}
                edificios={props.edificios}
                marcas={props.marcas}
                ruta={props.ruta}
                destinoslarga={props.destinoslarga}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                numblog={props.numblog}
                numfaqs={props.numfaqs}
                paginapersonalizada={props.paginapersonalizada} s
            />
            <TextosPagoResto
                datoscliente={props.datoscliente}
                datospropiedad={props.datospropiedad}
                reserva={props.reserva}
                totalesreserva={props.totalesreserva}
                restoapagar={props.restoapagar}
                config={props.config}
                tpvs={props.tpvs}
                tipopago={props.tipopago}
                lang={props.lang}
                ruta={props.ruta}
                moneda={props.moneda}
                transferencia={props.transferencia}
            />
            <Footer
                datos={props.websgrupo}
                terminos={props.textoterminos}
                protocolos={props.textoprotocolos}
                avisolegal={props.textoavisolegal}
                config={props.config}
                elementosweb={props.elementosweb}
                politicaprivacidad={props.textopolitica}
                lang={props.lang}
                ruta={props.ruta}
                logos_pie={props.logos_pie}
                websgrupo={props.websgrupo}
                numfaqs={props.numfaqs}
            />
        </main>
    );
}
export default PagoResto;
