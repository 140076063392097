import { React, useEffect, Fragment } from "react";
import { Row, Col, NavDropdown, Container, Navbar, Nav, DropdownDivider } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import { useTranslation } from 'react-i18next';

import LangSelector, { langSelector } from './LangSelector.jsx';

import "../../../../../../resources/css/web/onboarding/estils.css";

import { Helmet } from "react-helmet";

const Menu = ({ destinos, sobrenosotros, clientes, propietarios, servicios, experiencias, idiomas, lang, tiposcasas, config, textopropiedades, edificios, marcas, ruta, destinoslarga, meta_titulo, meta_descripcion, meta_keywords, numblog, numfaqs, paginapersonalizada }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const primeraLetraMayuscula = (cadena) => cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length).toLowerCase());

    useEffect(() => {
        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

    }, []);

    return (
        <>
            {/* ********** */}

            <Helmet>
                <title>{`${meta_titulo}`}</title>
                <meta name="title" content={`${meta_titulo}`} />
                <meta name="description" content={`${meta_descripcion}`} />
                <meta name="keywords" content={`${meta_keywords}`} />
                <meta charset="utf-8" />
            </Helmet>

            {
                (config.id === 1) ?
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/favicon.ico`}></link>
                    </Helmet>
                    :
                    <Helmet>
                        <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/${config.id}/favicon.ico`}></link>
                    </Helmet>
            }

            {
                (config.activa_analytics === 1) ?
                    <Helmet>
                        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.codigo_analytics}`}></script>
                        <script>
                            {`
                                            window.dataLayer = window.dataLayer || [];
                                            function gtag(){window && window.dataLayer && window.dataLayer.push(arguments)}
                                            gtag('js', new Date())
                                            gtag('config', '${config.codigo_analytics}');
                                        `}
                        </script>
                    </Helmet>
                    :
                    ""
            }

            {/* ********** */}
            <Navbar collapseOnSelect expand="lg" className="bg-body menu sticky-top">
                <Navbar.Brand href="/">
                    {config.logo_fiscal != "" ? <img className="logo logobl1" alt="Logo" title="Logo" src={ruta + "/web/logo/" + config.logo_fiscal} /> : <img className="logo logobl1" alt="Logo BedLoop" title="Logo BedLoop" src="https://www.bedloop.com/imatges/logo.webp" />}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link className="navegacio" href="/">{t("Inicio")}</Nav.Link>

                        {/* *********** MARCAS ********** */}

                        {destinos.length > 1 ?
                            <NavDropdownMenu className="navegacio" title={t("Rutas")} id="collasible-nav-dropdown" >
                                {destinos.map((undestino, index) => (
                                    <Fragment key={"d1" + index}>
                                        {undestino.querutas.length > 0 ?
                                            <>
                                                <DropdownSubmenu
                                                    title={undestino.elnombre}
                                                    key={"d2" + index}
                                                >
                                                    <NavDropdown.Item href={"/" + preidioma + t("embarcaciones") + "-" + undestino.laurl} >
                                                        {t("Todas")}
                                                    </NavDropdown.Item>
                                                    <DropdownDivider></DropdownDivider>
                                                    <div className="columna">
                                                        {undestino.querutas.map((unaruta, index2) => (
                                                            <NavDropdown.Item href={"/" + preidioma + t("ruta") + "/" + unaruta.nombre} key={"r2" + index2} className="rutaomenu">{unaruta.nombre}</NavDropdown.Item>
                                                        ))}
                                                    </div>
                                                </DropdownSubmenu>
                                            </>
                                            :
                                            <>
                                                <NavDropdown.Item key={"d2" + index} className="navegacio" href={"/" + preidioma + t("embarcaciones") + "-" + undestino.laurl}>{undestino.elnombre}</NavDropdown.Item>
                                            </>
                                        }
                                    </Fragment>
                                ))}
                            </NavDropdownMenu>
                            :
                            destinos[0].querutas.length > 0 ?
                                <NavDropdownMenu className="navegacio" title={destinos[0].elnombre} id="collasible-nav-dropdown" >
                                    <>
                                        <NavDropdown.Item href={"/" + preidioma + t("embarcaciones") + "-" + destinos[0].laurl} >{t("Todas")}</NavDropdown.Item>
                                        <DropdownDivider></DropdownDivider>
                                        <div className="columna">
                                            {destinos[0].querutas.map((unaruta, index) => (
                                                <NavDropdown.Item href={"/" + preidioma + t("ruta") + "/" + unaruta.nombre[lang]} key={"rs2" + index} className="rutamenu">{unaruta.nombre}</NavDropdown.Item>
                                            ))}
                                        </div>
                                    </>
                                </NavDropdownMenu>
                                :
                                <Nav.Link className="navegacio" href={"/" + preidioma + t("embarcaciones") + "-" + destinos[0].laurl}>{destinos[0].elnombre}</Nav.Link>
                        }


                        {/* ********** MARCAS ********** */}

                        {/* marcas.length > 0 ?
                            marcas.length > 1 ?
                                <NavDropdown className="navegacio" title={t("Rutas")} id="collasible-nav-dropdown-exp">
                                    {marcas.map((marca, index) => (
                                        <NavDropdown.Item href={"/" + preidioma + t("ruta") + "/" + marca.nombre} data-key={"m" + index} key={"m" + index}>{marca.nombre}</NavDropdown.Item>
                                    ))}
                                </NavDropdown>
                                :
                                <Nav.Link className="navegacio" href={"/" + preidioma + t("ruta") + "/" + marcas[0].nombre}>{marcas[0].nombre}</Nav.Link>
                            : ""
                        */}
                        {tiposcasas.length > 1 ?
                            <NavDropdown className="navegacio" title={t("Tipo Embarcacion")} id="collasible-nav-dropdown-tipo">
                                <NavDropdown.Item href={"/" + preidioma + t("buscar")}>{t("Todos")}</NavDropdown.Item>
                                {tiposcasas.map((tipocasa, index) => (
                                    <NavDropdown.Item href={"/" + preidioma + t("buscar") + "?tipo_casa=" + tipocasa.id} key={"tc" + index} data-key={"tc" + index}>{primeraLetraMayuscula(t(tipocasa.nombre))}</NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            : ""
                        }
                        {
                            numblog > 0 ?
                                <Nav.Link className="navegacio" href={"/" + preidioma + t("blog")}>Blog</Nav.Link>
                                :
                                ""
                        }

                        <NavDropdown className="navegacio" title={t("Empresa")} id="collasible-nav-dropdown-empresa">
                            {sobrenosotros.ladesc !== null && sobrenosotros.ladesc !== "" && sobrenosotros.ladesc !== "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("sobre-nosotros")}>{t("Sobre_Nosotros")}</NavDropdown.Item> : ''}
                            {clientes.ladesc !== null && clientes.ladesc !== "" && clientes.ladesc !== "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("clientes")}>{t("Clientes")}</NavDropdown.Item> : ''}
                            {propietarios.ladesc !== null && propietarios.ladesc !== "" && propietarios.ladesc !== "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("propietarios")}>{t("Propietarios")}</NavDropdown.Item> : ''}
                            {numfaqs > 0 ? <NavDropdown.Item href={"/" + preidioma + "faqs"}>FAQ's</NavDropdown.Item> : ""}
                            {servicios.ladesc !== null && servicios.ladesc !== "" && servicios.ladesc !== "" && servicios.ladesc !== "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("servicios")}>{t("Servicios")}</NavDropdown.Item> : ''}
                            {textopropiedades.ladesc !== null && textopropiedades.ladesc !== "" && textopropiedades.ladesc !== "<p>&nbsp;</p>" ? <NavDropdown.Item href={"/" + preidioma + t("propiedades")}>{t("Propiedades")}</NavDropdown.Item> : ''}
                        </NavDropdown>


                        <NavDropdown className="navegacio" title={t("Ayuda")} id="collasible-nav-dropdown.ayuda">
                            <Container className="mt-1 mb-1 ajuda">
                                <Row className="ml-5">
                                    <Col xs="12" md="12" sm="6" className="mt-3">
                                        <p className="bold">{t("¿Alguna pregunta? ¡Hablemos!")}</p>
                                    </Col>
                                    <Col xs="12" md="12" sm="6">
                                        <i className="fa-duotone fa-square-phone"></i> {config.telefono_fiscal}<br /><br />
                                        <i className="fa-duotone fa-square-envelope" aria-hidden="true"></i> {config.email_fiscal}<br /><br />
                                        <a href="#"><i className="fa-duotone fa-location-dot" aria-hidden="true"></i> {config.direccion_fiscal + ", " + config.codigo_postal_fiscal + ", " + config.poblacion_fiscal}</a>
                                    </Col>
                                </Row>
                            </Container>
                        </NavDropdown>

                        <LangSelector
                            idiomas={idiomas}
                            langactual={lang.toString().toUpperCase()}
                        />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default Menu;
