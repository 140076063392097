import Header from './Compartido/Header.jsx';
import Footer from './Compartido/Footer.jsx';
import BtnFloating from './Compartido/BtnFloating.jsx';
import TextosPagoResto from './PagoResto/TextosPagoResto.jsx';
import Scripts from './Compartido/Scripts.jsx';

import '../../../../../resources/css/web/holidayhomescanarias/header.css'
import '../../../../../resources/css/web/holidayhomescanarias/general.css';
import '../../../../../resources/css/web/holidayhomescanarias/footer.css'
import '../../../../../resources/css/web/holidayhomescanarias/intro.css'
import '../../../../../resources/css/web/holidayhomescanarias/buttons.css'

const PagoResto = (props) => {
    return (
        <main>
            <Header
                datos={props.websgrupo}
                lang={props.lang}
                idiomas={props.idiomas}
                tipo="pagoresto"
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
            />
            <TextosPagoResto
                datoscliente={props.datoscliente}
                datospropiedad={props.datospropiedad}
                reserva={props.reserva}
                totalesreserva={props.totalesreserva}
                restoapagar={props.restoapagar}
                config={props.config}
                tpvs={props.tpvs}
                tipopago={props.tipopago}
                lang={props.lang}
                ruta={props.ruta}
                moneda={props.moneda}
                transferencia={props.transferencia}
            />
            <Footer
                lang={props.lang}
                config={props.config}
            />
            <BtnFloating />
            <Scripts />
        </main>
    );
}
export default PagoResto;
