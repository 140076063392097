import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import { Carousel } from 'react-bootstrap';
import ImagePopupGallery from "../Compartido/ImagePopupGallery.jsx";

import axios from 'axios';

import { useTranslation } from 'react-i18next';

const Content = ({ lang, propiedad, servicios, ruta, diasbloqueados, primerdialibre, calendario, politicas, preciominimo, llegada, salida, adultos, ninos, bebes, mascotas, configweb, moneda, destino }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    useEffect(() => {
        var script = document.createElement("script");
        script.type = "module";
        script.src = "https://widgets.thereviewsplace.com/2.0/rw-widget-slider.js";
        document.getElementsByTagName("head")[0].appendChild(script);
    }, []);

    let dias = [];

    diasbloqueados.forEach((undia, index) => {
        dias.push(new Date(undia))
    })

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if (show == 'none') setShow('flex');
        else setShow('none');
    }

    registerLocale('es', es);

    let primdialibre = parseISO(primerdialibre);

    let entr = llegada != null ? parseISO(llegada) : null;
    let sali = salida != null ? parseISO(salida) : null;

    const [dateRange, setDateRange] = useState([entr, sali])
    const [startDate, endDate] = dateRange;

    {/* ********************* */ }

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));
    const [countMascotas, setCountMascotas] = useState(parseInt(mascotas));

    const handleSumaAdultos = () => {


        if (countAdults + countNinos < propiedad.capacidad_maxima) {
            setCountAdults(ca => ca + 1);
        }
    }

    const handleRestaAdultos = () => {

        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);

        }
    }

    const handleSumaNinos = () => {
        if (countNinos + countAdults < propiedad.capacidad_maxima) {
            setCountNinos(cn => cn + 1);
        }
    }

    const handleRestaNinos = () => {
        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
        }
    }

    const handleSumaBebes = () => {
        if (countBebes < propiedad.capacidad_maxima) {
            setCountBebes(cb => cb + 1);
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
        }
    }

    const handleSumaMascotas = () => {

        if (countMascotas < propiedad.capacidad_maxima) {
            setCountMascotas(cm => cm + 1);
        }
    }

    const handleRestaMascotas = () => {
        if (countMascotas > 0) {
            setCountMascotas(cm => cm - 1);
        }
    }

    {/* ***************** */ }

    const fotos = propiedad.fotos_web;

    {/* ***************** */ }

    var codigowidget = 25736;

    switch (propiedad.id) {
        case 10: {/* Bahia B */ }
            codigowidget = 27582;
            break;
        case 7: {/* Bahia D */ }
            codigowidget = 27583;
            break;
        case 6: {/* Bahia E */ }
            codigowidget = 27612;
            break;
        case 4:   //Bahia E
            codigowidget = 27613;
            break;
        case 28: {/* Villa Birgit */ }
            codigowidget = 27614;
            break;
        default: codigowidget = 25736;
            break;
    }

    {/* *************** */ }

    const [dataRes, setDataRes] = useState("<p></p>");

    const handleSubmit = (e) => {

        e.preventDefault()

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/haydispo', {
            id: propiedad.id,
            range: e.target.daterange.value,
            personas: Number(countAdults) + Number(countNinos),
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.Error == "OK") {

                    let noches = 0;
                    let estancia = 0;
                    let eltotal = 0;
                    var textoextras = "";
                    var laspersonas = Number(countAdults) + Number(countNinos);
                    let noches_a_multiplicar = 0;

                    const precios = solucion.precios[0];
                    const extras = solucion.extras.relacion_extras;

                    if (propiedad.by_request == 0) {
                        for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                            //estancia += precios.calendario_sin_otas[j].precio;
                            noches++;
                        }
                        noches_a_multiplicar = Math.min(noches, destino.maximo_dias);

                        estancia = precios.suma * 0.01;

                        eltotal = eltotal + estancia;

                        for (let j = 0; j < extras.length; j++) {
                            if (extras[j].obligatorio == 1) {
                                let precio0 = extras[j].precio;
                                let tipo_precio = extras[j].tipo_precio;

                                if (tipo_precio == 1) {
                                    if (extras[j].por_persona == 1) {
                                        precio0 = precio0 * laspersonas;
                                    }
                                    if (extras[j].por_dia_reserva == 1) {
                                        precio0 = precio0 * noches;
                                    }
                                }
                                else {
                                    precio0 = estancia * (precio0 / 100);
                                    console.log(precio0);
                                    if (extras[j].por_persona == 1) {
                                        precio0 = precio0 * laspersonas;
                                    }
                                    if (extras[j].por_dia_reserva == 1) {
                                        precio0 = precio0 * noches_a_multiplicar;
                                    }
                                }

                                textoextras += "  <div class='row mb-3'>" +
                                    "<div class='col-8'>" +
                                    t(extras[j].nombre) +
                                    "</div>" +
                                    "<div class='col-4 text-end'>" +
                                    (precio0 * 0.01).toFixed(2) + moneda +
                                    "</div>" +
                                    "</div>";
                                eltotal = eltotal + (precio0 * 0.01);
                            }

                        }
                    }

                    if (propiedad.by_request == 1) {
                        var formreserva =
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            solucion.noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            "</div>" +
                            "<div class='col-12'>" +
                            "<a href='/" + preidioma + t("peticion") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;mascotas=" + Number(countMascotas) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100 btn__medium w-100 black-text col-12' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Petición") + "</a>";
                        "</div>" +
                            "<div>" +
                            "</div>"
                    }
                    else {
                        var formreserva =
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            estancia.toFixed(2) + moneda +
                            "</div>" +
                            "</div>" +
                            textoextras +
                            "<div class='linia mt-3 mb-3'>" +
                            "</div>" +
                            "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                            t("Total") +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                            eltotal.toFixed(2) + moneda +
                            "</div>" +
                            "<div class='col-12'>" +
                            "<a href='/" + preidioma + t("reserva") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;mascotas=" + Number(countMascotas) + "&amp;solorequest=0' class='btn btn-primary btn-icon mt-2 w100 btn__medium w-100 black-text col-12' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Reservar") + "</a>";
                        "</div>" +
                            "<div>" +
                            "</div>"
                    }

                    setDataRes(
                        formreserva
                    );
                }
                else {
                    setDataRes("<p>" + solucion.Error + "</p>");
                }
            });
    }

    useEffect(() => {
        if (adultos > 0 && entr != null && sali != null) {
            let token = document.getElementById('meta_token').getAttribute('content');

            const fecha = new Date(llegada);
            const fecha2 = new Date(salida);
            const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const llegadaFormateada = fecha.toLocaleDateString('es-ES', opciones);
            const salidaformateada = fecha2.toLocaleDateString('es-ES', opciones);

            const result = axios.post('/haydispo', {
                id: propiedad.id,
                range: llegadaFormateada + " - " + salidaformateada,
                personas: Number(countAdults) + Number(countNinos),
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    const solucion = response.data;
                    if (solucion.Error == "OK") {
                        const precios = solucion.precios[0];

                        let estancia = 0;
                        let eltotal = 0;
                        let noches = 0;
                        for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                            estancia += precios.calendario_sin_otas[j].precio;
                            noches++;
                        }
                        estancia = estancia * 0.01;

                        eltotal = eltotal + estancia;

                        const extras = solucion.extras.relacion_extras;

                        var textoextras = "";

                        for (let j = 0; j < extras.length; j++) {
                            if (extras[j].obligatorio == 1) {
                                textoextras += "  <div class='row mb-3'>" +
                                    "<div class='col-8'>" +
                                    t(extras[j].nombre) +
                                    "</div>" +
                                    "<div class='col-4 text-end'>" +
                                    extras[j].precio * 0.01 + moneda +
                                    "</div>" +
                                    "</div>";
                                eltotal = eltotal + (extras[j].precio * 0.01);
                            }

                        }

                        var urlreserva = "<a href='/" + preidioma + t("reserva") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + llegadaFormateada + " - " + salidaformateada + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;mascotas=" + Number(countMascotas) + "&amp;solorequest=0' class='btn btn__medium w-100 black-text buscadispo'>" + t("Reservar") + "</a>";

                        if (propiedad.by_request == 1) {
                            urlreserva = "<a href='/" + preidioma + t("peticion") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + llegadaFormateada + " - " + salidaformateada + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;mascotas=" + Number(countMascotas) + "&amp;solorequest=1' class='btn btn__medium w-100 black-text buscadispo'>" + t("Petición") + "</a>";
                        }


                        setDataRes(
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            estancia.toFixed(2) + moneda +
                            "</div>" +
                            "</div>" +
                            textoextras +
                            "<div class='linia mt-3 mb-3'>" +
                            "</div>" +
                            "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                            t("Total") +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                            eltotal.toFixed(2) + moneda +
                            "</div>" +
                            "<div class='col-12'>" +
                            urlreserva +
                            "</div>" +
                            "</div>" +
                            "</div>"
                        );
                    }
                    else {
                        setDataRes("<p>" + solucion.Error + "</p>");
                    }
                });
        }
    }
        , [])

    {/* *************** */ }

    let botones = document.querySelectorAll(".boton");

    const cuandoSeHaceClick = function (evento) {

        var nuevo_mes = this.getAttribute("data-nuevo-mes");
        var nuevo_ano = this.getAttribute("data-nuevo-ano");

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/calendario0', {
            idPropiedad: propiedad.id,
            nuevo_mes: nuevo_mes,
            nuevo_ano: nuevo_ano,
            bloqueos: JSON.stringify(diasbloqueados),
            lang: lang
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                document.querySelectorAll(".calendario")[0].innerHTML = response.data;
                botones = document.querySelectorAll(".boton");
                botones.forEach(boton => {
                    boton.addEventListener("click", cuandoSeHaceClick);
                })
            })

    }

    botones.forEach(boton => {
        boton.addEventListener("click", cuandoSeHaceClick);
    })

    {/* *************** */ }

    let gallery0 = [];
    let gallery2 = [];

    let urlprop = "https://www.holidayhomescanarias.com/" + preidioma + "villas-tenerife/" + propiedad.url_dinamica;

    for (let i = 0; i < fotos.length; i++) {

        const image = {
            original: ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + fotos[i].nombre,
            originalClass: "foto" + propiedad.id
        }
        gallery0 = gallery0.concat(image);
    }

    fotos.map((unafoto, index) => (

        gallery2 = gallery2.concat(
            <Carousel.Item key={index}>
                <ImagePopupGallery
                    imageSrc={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre}
                    urlprop={urlprop}
                    gallery={gallery0}
                    altura="500px"
                />
            </Carousel.Item>
        )
    ));


    return (
        <section id="content" className="container">
            <div className="row sticky-parent">
                <div className="order-2 mt-4 col-md-12 col-xl-8 order-xl-1 mt-sm-5">
                    {/* Description room */}
                    <h3 className="title title--h3">{t("Descripción")}</h3>
                    <p dangerouslySetInnerHTML={{ __html: propiedad.ladesc }}></p>
                    {/* Amenity */}
                    <h3 className="mt-4 title title--h3 mt-sm-5">
                        {t("¿Qué te ofrece esta villa?")}
                    </h3>
                    <div className="row services">
                        <ul className="list-unstyled list-feature col-12 col-md-4">

                            {servicios.map((unservicio, index) => {
                                if (index < 4) {
                                    return <li className="list-feature__item" key={index}>{unservicio.elnombre}</li>
                                }
                                return null;
                            })}
                        </ul>
                        <ul className="list-unstyled list-feature col-12 col-md-4">
                            {servicios.map((unservicio, index) => {
                                if (index >= 4 && index < 8) {
                                    return <li className="list-feature__item" key={index}>{unservicio.elnombre}</li>
                                }
                                return null;
                            })}
                        </ul>
                        <ul className="list-unstyled list-feature col-12 col-md-4">
                            {servicios.map((unservicio, index) => {
                                if (index >= 8 && index < 12) {
                                    return <li className="list-feature__item" key={index}>{unservicio.elnombre}</li>
                                }
                                return null;
                            })}
                        </ul>
                        <button className="btn black-text centered show-more-services-btn" onClick={handleShow}>
                            {t("Mostrar más servicios")}
                        </button>
                    </div>
                    <div className="row services show-more-services" style={{ display: show }} >
                        <ul className="list-unstyled list-feature col-12 col-md-4">
                            {servicios.map((unservicio, index) => {
                                if (index >= 12 && index < 16) {
                                    return <li className="list-feature__item" key={index}>{unservicio.elnombre}</li>
                                }
                                return null;
                            })}
                        </ul>
                        <ul className="list-unstyled list-feature col-12 col-md-4">
                            {servicios.map((unservicio, index) => {
                                if (index >= 16 && index < 20) {
                                    return <li className="list-feature__item" key={index}>{unservicio.elnombre}</li>
                                }
                                return null;
                            })}
                        </ul>
                        <ul className="list-unstyled list-feature col-12 col-md-4">
                            {servicios.map((unservicio, index) => {
                                if (index >= 20 && index < 24) {
                                    return <li className="list-feature__item" key={index}>{unservicio.elnombre}</li>
                                }
                                return null;
                            })}
                        </ul>
                    </div>

                    {/* /Gallery slider */}
                    {/* */}
                    <Carousel slide={true} controls={true} indicators={false} className="mt-4">
                        {gallery2}
                    </Carousel>

                    {/* */}
                    <h3 className="mt-4 title title--h3 mt-sm-5">
                        {t("Comprueba la disponibilidad")}
                    </h3>
                    {/* componente calendario */}
                    <div className="mb-5 calendario" align="center">
                        <div dangerouslySetInnerHTML={{ __html: calendario }} className="elcalendario"></div>
                    </div>


                    {/* Rating & Review */}
                    <h3 className="title title--h3">{t("Recomendaciones")}</h3>
                    <div className="mt-4">
                        {/* Begin widget code */}
                        <div data-rw-slider={codigowidget}>
                            <rw-widget-slider
                                data-rw-slider={codigowidget}
                                className="hydrated"
                                style={{ display: "inline-block" }}
                            />
                        </div>
                        {/* End widget code */}
                    </div>
                    <h3 className="mt-4 title title--h3 mt80">
                        {t("Descripción del pueblo y los alrededores")}
                    </h3>
                    <div className="mt-4">
                        <div className="row room-services">
                            <div className="col-12 col-lg-12">
                                <p dangerouslySetInnerHTML={{ __html: propiedad.comollegar }}></p>
                            </div>
                            <div className="col-12 col-lg-12 room-services">
                                <h6 className="mb20 mt40">{t("Las distancias más importantes")}:</h6>
                                <div dangerouslySetInnerHTML={{ __html: propiedad.lainfo }}></div>
                            </div>
                        </div>
                    </div>
                    <h3 className="mt-4 title title--h3 mt80">
                        {t("Normas, limpieza y política de cancelación")}
                    </h3>
                    <div className="mt-4">
                        <div className="row room-services">
                            <div className="col-12 col-lg-4">
                                <h6 className="mb20">{t("Normas de la casa")}</h6>
                                <ul>
                                    <div id="horariotext">
                                        <p className="justificar">
                                            {t("Check-in: a partir de las") + " " + propiedad.hora_checkin + "h"}
                                            <br />
                                            {t("Check out: antes de las") + " " + propiedad.hora_checkout + "h."}
                                            <br />
                                            {t("Si necesita entrar/salir antes o después de dichas horas, deberá solicitarlo en el momento de la reserva. Estas opciones quedan sujetas a disponibilidad y pueden generar un coste adicional.")}
                                        </p>
                                    </div>
                                    <li className="black-text">{t("Llegada")}: {propiedad.hora_checkin}</li>
                                    <li className="black-text">{t("Salida")}: {propiedad.hora_checkout}</li>
                                    <li className="black-text">{t("No se admiten fiestas o eventos")}.</li>
                                    <li className="black-text">{t("Prohibido fumar en el interior")}</li>
                                    <li className="black-text">{t("Admite mascotas")}</li>
                                    <li className="black-text">{t("Admite niños")}</li>
                                    <li className="black-text">
                                        <a href={"/" + preidioma + t("terminos")}>{t("Mostrar más")}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-lg-4">
                                <h6 className="mb20">{t("Limpieza y demás")}</h6>
                                <ul>
                                    <li className="black-text">
                                        {t("Nos compremetemos a seguir una limpieza estricta y aplicamos las pautas con respecto al distanciamiento físico.")}
                                    </li>
                                    <li className="black-text">
                                        {t("Si se producen daños en la vivienda, se te podría cobrar hasta un máximo de 300 €.")}
                                    </li>
                                    {propiedad.hutb ?
                                        <li className="black-text">{t("Número de registro")}: {propiedad.hutb}</li>
                                        :
                                        null
                                    }
                                </ul>
                            </div>
                            <div className="col-12 col-lg-4">
                                <h6 className="mb20">{t("Política de Cancelación")}</h6>
                                <ul>
                                    <li className="black-text">
                                        {politicas.map((unapolitica) => (
                                            unapolitica.ladesc.length > 1 ?
                                                <p dangerouslySetInnerHTML={{ __html: unapolitica.ladesc }} key={unapolitica.id}></p>
                                                :
                                                null

                                        ))}
                                    </li>
                                    <li className="black-text">
                                        <a href={"/" + preidioma + t("politica-de-cancelacion")}>{t("Mostrar más")}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Sidebar Booking */}
                <div
                    className="order-1 col-md-12 col-xl-4 order-xl-2"
                >
                    <div className="sidebar-booking sticky-column">
                        <div className="sidebar-booking__priceWrap">
                            <div className="priceWrap-title">{t("Precio")}</div>
                            <div className="priceWrap-price">
                                <span>{t("Desde")} </span>{(preciominimo / 100).toFixed(2)}€<span> {t("Por noche")}</span>
                            </div>
                        </div>
                        <form className="sidebar-booking__wrap" id="search1" action="#" method="GET" onSubmit={handleSubmit}>
                            <input type="hidden" id="lang" name="lang" value={lang} />
                            <input type="hidden" id="moneda" name="moneda" value="EUR" />
                            <input
                                name="idpropiedad1"
                                id="idpropiedad1"
                                type="hidden"
                                defaultValue={propiedad.id}
                            />
                            {/* Dates */}
                            <div className="form-group">
                                <label className="label" htmlFor="check-in">
                                    {t("Fechas")}
                                </label>
                                <div className="form-dual--mobile">
                                    <div className="form-dual">
                                        <span className="fa-regular fa-calendar" style={{ marginLeft: "20px" }} />
                                        <DatePicker
                                            isClearable={true}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            excludeDates={dias}
                                            className="form-control mw201 cpointer"
                                            autoComplete="off"
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange={true}
                                            monthsShown={2}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={primdialibre}
                                            placeholderText={t("Entrada - Salida")}
                                            locale={lang}
                                            name="daterange"
                                            fixedHeight
                                        >
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* Persons */}
                                <div className="col-12 col-sm-6 form-group">
                                    <label className="label" htmlFor="person-adult">
                                        {t("Adultos")}
                                    </label>
                                    <div className="js-quantity row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleRestaAdultos}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <span className="col-4">
                                            <input
                                                type="number"
                                                className="inputText js-quantity-input readonly"
                                                id="person-adult"
                                                name="person-adult"
                                                min={0}
                                                max={propiedad.capacidad_maxima}
                                                readOnly="readonly"
                                                value={countAdults}
                                                style={{ width: "62px" }}
                                            />
                                        </span>
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleSumaAdultos}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 form-group">
                                    <label className="label" htmlFor="person-kids">
                                        {t("Niños")} ({t("2 - 12 años")})
                                    </label>
                                    <div className="js-quantity row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleRestaNinos} >
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <span className="col-4">
                                            <input
                                                type="number"
                                                className="inputText js-quantity-input readonly col-4"
                                                id="person-kids"
                                                name="my_multi_select_ninos-kids"
                                                min={0}
                                                max={propiedad.capacidad_maxima}
                                                readOnly="readonly"
                                                value={countNinos}
                                                style={{ width: "62px" }}
                                            />
                                        </span>
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleSumaNinos} >
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 form-group">
                                    <label className="label" htmlFor="person-bebes">
                                        {t("Bebés")} ({t("0 - 2 años")})
                                    </label>
                                    <div className="js-quantity row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleRestaBebes} >
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <span className="col-4">
                                            <input
                                                type="number"
                                                className="inputText js-quantity-input readonly col-4"
                                                id="person-bebes"
                                                name="my_multi_select_bebes"
                                                min={1}
                                                max={propiedad.capacidad_maxima}
                                                readOnly="readonly"
                                                value={countBebes}
                                                style={{ width: "62px" }}
                                            />
                                        </span>
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleSumaBebes}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 form-group">
                                    <label className="label" htmlFor="person-pets">
                                        {t("Mascotas")}
                                    </label>
                                    <div className="js-quantity row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleRestaMascotas}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <span className="col-4">
                                            <input
                                                type="number"
                                                className="inputText js-quantity-input readonly col-4"
                                                id="person-pets"
                                                name="my_multi_select_pet-pets"
                                                min={0}
                                                max={propiedad.capacidad_maxima}
                                                readOnly="readonly"
                                                value={countMascotas}
                                                style={{ width: "62px" }}
                                            />
                                        </span>
                                        <span className="input-group-btn col-4" style={{ paddingTop: "14px", cursor: "pointer" }}>
                                            <span onClick={handleSumaMascotas}>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1.2em", width: "1.2em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-1 col-12">
                                    <button
                                        type="submit"
                                        className="btn btn__medium w-100 black-text buscadispo"
                                    >
                                        {t("Reservar")}
                                    </button>
                                </div>
                                <br />
                                <ul className="preprecio" />
                            </div>
                        </form>
                        <div className="mt-4">
                            <div dangerouslySetInnerHTML={{ __html: dataRes }}></div>
                        </div>
                        <div
                            className="row"
                            style={{
                                padding: "1.5rem 1.5rem 1.1rem",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <div className="col-4">
                                <a href={"https://www.facebook.com/sharer/sharer.php?u=https://www.holidayhomescanarias.com/villas-tenerife/" + propiedad.url_dinamica}>
                                    <i className="fa-brands fa-facebook-f" style={{ paddingRight: "4px" }} />
                                    <span>{t("Facebook")}</span>
                                </a>
                            </div>
                            <div className="col-4">
                                <a href={"https://twitter.com/intent/tweet?original_referer=https://www.holidayhomescanarias.com/villas-tenerife/" + propiedad.url_dinamica + "&text=" + propiedad.nombre + "&url=https://www.holidayhomescanarias.com/villas-tenerife/" + propiedad.url_dinamica}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" style={{ paddingRight: "4px" }} viewBox="0 0 512 512">{/*<!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->*/}<path fill="#01c2cb" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                    <span>{t("Twitter")}</span>
                                </a>
                            </div>
                            <div className="col-4">
                                <a href={"http://www.linkedin.com/shareArticle?mini=true&url=https://www.holidayhomescanarias.com/villas-tenerife/" + propiedad.url_dinamica + "&title=" + propiedad.nombre + "&source=Holiday Homes Canarias"}>
                                    <i className="fa-brands fa-linkedin-in" style={{ paddingRight: "4px" }} />
                                    <span>{t("Linkedin")}</span>
                                </a>
                            </div>
                            <div className="col-4">
                                <a
                                    target="_blank"
                                    href={"mailto:?&body=https://www.holidayhomescanarias.com/villas-tenerife/" + propiedad.url_dinamica + "&title=" + propiedad.nombre}
                                >
                                    <i className="fa-thin fa-envelope" style={{ paddingRight: "4px" }} /> <span>{t("E-mail")}</span>
                                </a>
                            </div>
                            <div className="col-4">
                                <a
                                    target="_blank"
                                    href={"whatsapp://send?text= " + propiedad.nombre + " https://www.holidayhomescanarias.com/villas-tenerife/" + propiedad.url_dimanica}
                                >
                                    <i className="fa-brands fa-whatsapp" style={{ paddingRight: "4px" }} />
                                    <span>{t("Whatsapp")}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Sidebar Booking */}
            </div>
        </section >

    );
};
export default Content;
