import { React } from "react";
import { Container } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const DatosDestino = ({ destino }) => {
    return (
        <div className="pt-2 pb-5">
            <h1 className="mb-5 text-center">{destino.elnombre}</h1>
            <Container>
                <div className="noticia-individual-descripcio-llarga">
                    <div>
                        {ReactHtmlParser(destino.eltexto)}
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default DatosDestino;
