import Menu from './Compartido/Menu.jsx';
import TextosGraciasTpv from './GraciasTpv/TextosGraciasTpv.jsx';
import Footer from './Compartido/Footer.jsx';
import { useTranslation } from 'react-i18next';

const GraciasStripe = (props) => {
    let { t } = useTranslation();

    return (
        <main>
            <Menu
                idiomas={props.idiomas}
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                ruta={props.ruta}
                meta_titulo={"Magno Apartments - " + t("Gracias")}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
            />
            <TextosGraciasTpv />
            <Footer
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                idiomas={props.idiomas}
                ruta={props.ruta}
            />
        </main>
    )
}

export default GraciasStripe;
