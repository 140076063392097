import { React } from "react";

import { useTranslation } from 'react-i18next';
import WidgetPropiedad from "../Compartido/WidgetPropiedad";

const PropiedadesEdificio = ({ propiedades, lang, config, ruta }) => {

    const { t } = useTranslation();

    return (
        <div className="pt-5">
            <div className="destacats">
                {propiedades.map((propiedad, index) => (
                    <WidgetPropiedad
                        propiedad={propiedad}
                        ruta={ruta}
                        config={config}
                        lang={lang}
                        index={"d" + index}
                        key={"d" + index}
                    />
                ))}
            </div>
        </div>
    );
}


export default PropiedadesEdificio;
