import React, { useState } from "react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const CustomAlert = ({ type, message, onClose }) => {

    const alertStyles = {
        ok: {
            backgroundColor: 'white',
            color: 'green',
            padding: '20px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '300px',
            height: '200px',
        },
        ko: {
            backgroundColor: 'white',
            color: 'red',
            padding: '20px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '300px',
            height: '200px',
        },
    };

    const alerticon = {
        ok: "<div class='success-animation' style='font-size: 55px; text-align:center; width:80px; height:80px;border: 1px solid; border-radius:50%'><i class='fa-solid fa-check' style='padding-left:10px; padding-right:10px'></i></div>",
        ko: "<div class='error-animation' style='font-size: 55px; text-align:center; width:80px; height:80px; border: 1px solid; border-radius:50%'><i class='fa-solid fa-xmark' style='padding-left:10px; padding-right:10px'></i></div>"
    }
    return (
        <div style={alertStyles[type]}>
            {ReactHtmlParser(alerticon[type])}
            <div style={{ color: "black", marginTop: "30px" }}>{message}</div>
        </div>
    );
};

export default CustomAlert;
