
import Menu from './Compartido/Menu.jsx';
import Buscador from './Buscar2/Buscador.jsx';
import PropiedadesBuscar from './Buscar2/PropiedadesBuscar.jsx';
import Footer from './Compartido/Footer.jsx';

const Inicio = (props) => {

    return (
        <main>
            <Menu
                destinos={props.destinos}
                sobrenosotros={props.textosobrenosotros}
                clientes={props.textoclientes}
                propietarios={props.textopropietarios}
                servicios={props.textoservicios}
                experiencias={props.experiencias}
                idiomas={props.idiomas}
                lang={props.lang}
                tiposcasas={props.tiposcasas}
                config={props.websgrupo}
                textopropiedades={props.textopropiedades}
                edificios={props.edificios}
                marcas={props.marcas}
                ruta={props.ruta}
                destinoslarga={props.destinoslarga}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                numblog={props.numblog}
                numfaqs={props.numfaqs}
                paginapersonalizada={props.paginapersonalizada}
            />
            <Buscador
                destino={props.urldestino}
                destinos={props.destinos}
                tiposcasas={props.tipocasasdestino}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                llegada={props.llegada}
                salida={props.salida}
                tipocasa={props.tipocasa}
                lang={props.lang}
                config={props.websgrupo}
                capacidad_maxima={props.capacidad_maxima}
                barrios={props.barriosdestino}
                barrio={props.barrio}
            />
            <PropiedadesBuscar
                lang={props.lang}
                ruta={props.ruta}
                propiedades={props.propiedades}
                elementosweb={props.elementosweb}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                llegada={props.llegada}
                salida={props.salida}
                config={props.websgrupo}
                moneda={props.moneda}
            />
            <Footer
                datos={props.websgrupo}
                terminos={props.textoterminos}
                protocolos={props.textoprotocolos}
                avisolegal={props.textoavisolegal}
                config={props.config}
                elementosweb={props.elementosweb}
                politicaprivacidad={props.textopolitica}
                lang={props.lang}
                ruta={props.ruta}
                logos_pie={props.logos_pie}
                websgrupo={props.websgrupo}
                numfaqs={props.numfaqs}
            />
        </main>
    );
}

export default Inicio;
