import { React, useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import axios from "axios";

import { useTranslation } from 'react-i18next';

const MapaContacto = ({ config }) => {

    const { t } = useTranslation();

    const [address, setAddress] = useState(config.direccion_fiscal + "," + config.codigo_postal_fiscal + "," + config.poblacion_fiscal);
    const [address2, setAddress2] = useState(config.poblacion_fiscal);
    const [coordinates, setCoordinates] = useState([]);

    useEffect(() => {
        if (address === "") {
            return;
        }

        if (config.latitud) {
            setCoordinates([parseFloat(config.latitud), parseFloat(config.longitud)]);
        }
        else {
            axios
                .get(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
                .then((res) => {
                    if (res.data.length > 0) {
                        setCoordinates([res.data[0].lat, res.data[0].lon]);
                    }
                    else {
                        axios
                            .get(`https://nominatim.openstreetmap.org/search?format=json&q=${address2}`)
                            .then((res) => {
                                if (res.data.length > 0) {
                                    setCoordinates([res.data[0].lat, res.data[0].lon]);
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }

    }, [address, address2]);

    return (
        <>
            <div className="pt-1 pb-5 mapacontacto">
                {coordinates.length > 0 && (
                    <MapContainer center={coordinates} zoom={16} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={coordinates}>
                        </Marker>
                    </MapContainer>
                )}
                <div className="noticia-individual">
                    <p><i className="fa-duotone fa-map-location"></i>  {config.direccion_fiscal}, {config.codigo_postal_fiscal}, {config.poblacion_fiscal}</p>
                    <p><i className="fa-duotone fa-mobile"></i>  {config.telefono_fiscal}</p>
                    <p><i className="fa-duotone fa-envelope"></i>  {config.email_fiscal}</p>
                </div>
            </div>
        </>
    );
}

export default MapaContacto;
