import { React } from "react";
import { Container } from "react-bootstrap";
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const ListaNoticiasHome = ({ noticias, ruta, lang, config, preidioma }) => {

    const { t } = useTranslation();

    return (
        <div className="destacats">
            {noticias.map((noticia, index) => (

                <div className="destacats-noticies" key={index} >

                    {
                        noticia.imagen != ""
                            ? <img className="destacats-imatge-noticies" alt="New" title="New" src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} />
                            : <img className="destacats-imatge-noticies" alt="New" title="New" src="/images/web/bedloop-nofoto.png" />
                    }

                    <div className="destacats-text-noticies">
                        <span className="destacats-data">
                            <i className="fa-solid fa-calendar ml-5 cpointer"></i>
                            <span className="ml-5">{noticia.fecha_publica}</span>
                        </span>
                        <h5 className="destacats-titol-noticies">{noticia.eltitulo}</h5>
                        <span className="destacats-text-noticies">
                            <div dangerouslySetInnerHTML={{ __html: noticia.ladesc }}></div>
                        </span>
                        <div className="text-center">
                            <a className="btn btn-primary" href={"/" + preidioma + t("blog") + "/" + noticia.laurl} style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}> {t("leer más")} </a>
                        </div>
                    </div>
                </div>

            ))}


        </div>
    );
}

const NoticiasHome = ({ noticias, ruta, lang, elementosweb, config }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        noticias.length > 0 ?
            <div className="fons potblur">
                <div className="container linia tex-center mt-3 mb-5"></div>
                <Container>
                    <div className="row text-center">
                        <div className="col-md-10 offset-md-1">
                            <h2>{elementosweb.cabecera_buscador[lang]}</h2>
                            <p>{t("Eventos, guias, trucos, noticias y más...")}</p>
                        </div>
                    </div>
                    <ListaNoticiasHome
                        noticias={noticias}
                        ruta={ruta}
                        lang={lang}
                        config={config}
                        elementosweb={elementosweb}
                        preidioma={preidioma}
                    />
                    <div className="row text-center  pb-5">
                        <div className="col-md-4 offset-md-4">
                            <a href={"/" + preidioma + t("blog")} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>{t("Más propuestas")}</a>
                        </div>
                    </div>
                </Container>
            </div>
            :
            <></>
    )
}

export default NoticiasHome;
