import { React, useState, useEffect, useRef } from "react";

import axios from 'axios';
import { Modal } from 'react-bootstrap';

import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';

const Content = ({ propiedad, precios, llegada, salida, difdias, adultos, ninos, bebes, mascotas, lang, ruta, config, tpvs, paypal, transferencia, stripe, paycomet, moneda, numpropiedades, websgrupo }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    var resprices = [];
    var resextras = [];

    var precioBase = 0;
    var cuentaNoches = 0;
    var sumaextras = 0;

    var prepago = 0;

    const [diall, mesll, anyoll] = llegada.split("-");
    const fechallegada = new Date(anyoll, mesll - 1, diall);

    const [diasal, messal, anyosal] = salida.split("-");
    const fechasalida = new Date(anyosal, messal - 1, diasal);

    {/* *************** */ }

    let lospreciosdiarios = precios[0].calendario_sin_otas;
    let elacumulado = 0;
    for (let i = 0; i < lospreciosdiarios.length; i++) {
        elacumulado += lospreciosdiarios[i].precio / 100;
    }

    const [idTarifaRef, setIdTarifaRef] = useState(precios[0].id);
    const [priceRef, setPriceRef] = useState(btoa(elacumulado));
    const [prepagoRef, setPrepagoRef] = useState(precios[0].info_tarifa.politica.prepago);

    const cantApartRef = useRef(1);
    const sumaExtrasRef = useRef(0);
    const sumaExtras2Ref = useRef(0);
    const feesRef = useRef(0);
    const pextraRef = useRef(null);
    const preciosDiariosRef = useRef(null);

    const textopoliticaRef = useRef(null);

    const nombrecRef = useRef(null);
    const apellidoscRef = useRef(null);
    const emailcRef = useRef(null);
    const telefonocRef = useRef(null);
    const documentocRef = useRef(null);
    const aceptacondicionesRef = useRef(null);

    const codigodescuentoprecioRef = useRef(null);
    const codigodescuentoporcentajeRef = useRef(null);

    const formularioreservaRef = useRef(null);

    const datosreservaactualesRef = useRef(null);

    const numpropiedades0Ref = useRef(null);

    const [showHayExtraCat, setShowHayExtraCat] = useState("none");
    const pextracatRef = useRef(null);

    {/* *************** */ }

    const roundN = (value, decimals) => {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    }

    const handleChangeTarifa = () => {

        var numreservas = cantApartRef.current;

        var precioEstancia = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").value * numreservas);
        var precioExtras = (Number(sumaExtrasRef.current.value) + Number(sumaExtras2Ref.current.value)) * numreservas;
        var precioTotal = Number(precioEstancia) + Number(precioExtras);
        var prepago = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-prepago'));
        var textopol = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-texto');

        var totalextratipo2 = 0;

        var extras = document.getElementsByClassName("extra");
        for (var i = 0; i < extras.length; i++) {
            var unextra = extras[i];
            if (unextra.getAttribute('data-tipoextra') === 2) {
                var porciento = unextra.getAttribute('data-precio');
                var precioextra = (precioEstancia * porciento) / 100;
                var valorcampo = unextra.value;
                if (valorcampo === 1) {
                    totalextratipo2 += precioextra;
                }
            }
        }
        sumaExtras2Ref.current.value = totalextratipo2;

        if (!isNaN(sumaExtrasRef.current.value)) {
            var nuevoPrecioExtras = roundN(sumaExtrasRef.current.value, 2) * numreservas;
            feesRef.current.value = nuevoPrecioExtras;
        }
        else {
            var nuevoPrecioExtras = 0;
            feesRef.current.value = 0;
        }

        if (!isNaN(sumaExtras2Ref.current.value)) {
            var nuevoPrecioExtrasTipo2 = roundN(sumaExtras2Ref.current.value, 2) * numreservas;
            var fees = roundN(feesRef.current.value, 2);

            feesRef.current.value = parseFloat(nuevoPrecioExtrasTipo2) + parseFloat(fees);
            pextraRef.current.textContent = roundN(parseFloat(fees) + parseFloat(nuevoPrecioExtrasTipo2), 2);
        }
        else {
            var nuevoPrecioExtrasTipo2 = 0;
        }

        var nuevoPrecioTotal = roundN(parseFloat(precioEstancia) + parseFloat(nuevoPrecioExtras) + parseFloat(nuevoPrecioExtrasTipo2), 2);

        let incremento = 0;
        let tipoIncremento = 0;

        incremento = document.querySelector("input[type='radio'][name='payments']:checked").getAttribute('data-incremento');
        tipoIncremento = document.querySelector("input[type='radio'][name='payments']:checked").getAttribute("data-tipoincremento");

        let incremento2 = incremento;
        if (tipoIncremento === 1) {
            incremento2 = roundN(Number(nuevoPrecioTotal) * ((Number(incremento) / 100)), 2);
        }

        if (incremento > 0) {
            let incremento_a_mostrar = 0;
            if (tipoIncremento === 0) {
                nuevoPrecioTotal = roundN(Number(nuevoPrecioTotal) + Number(incremento), 2);
                incremento_a_mostrar = roundN(Number(incremento), 2);
            } else {
                incremento_a_mostrar = roundN(Number(nuevoPrecioTotal) * (Number(incremento) / 100), 2);
                nuevoPrecioTotal = roundN(Number(nuevoPrecioTotal) * (1 + (Number(incremento) / 100)), 2);
            }
            setShowHayExtraCat("flex");
            pextracatRef.current.textContent = incremento_a_mostrar;
        }
        else {
            setShowHayExtraCat("none");
            pextracatRef.current.textContent = 0;
        }

        var nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
        var nuevoResto = nuevoPrecioTotal - nuevoPrepago;

        var codigoDescuentoPrecio = codigodescuentoprecioRef.current.value;
        var codigoDescuentoPorcentaje = codigodescuentoporcentajeRef.current.value;

        if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
            var precioSinDescuento = nuevoPrecioTotal;
        }
        else {
            var precioSinDescuento = 0.00;
        }
        if (codigoDescuentoPrecio > 0) {
            nuevoPrecioTotal = (nuevoPrecioTotal - codigoDescuentoPrecio).toFixed(2);
            nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
            nuevoResto = nuevoPrecioTotal - nuevoPrepago;
        }
        if (codigoDescuentoPorcentaje > 0) {
            nuevoPrecioTotal = (nuevoPrecioTotal - (precioEstancia * (codigoDescuentoPorcentaje / 100))).toFixed(2);
            nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
            nuevoResto = nuevoPrecioTotal - nuevoPrepago;
        }

        document.querySelectorAll(".pricebase").forEach(function (elemento) {
            elemento.textContent = roundN(Number(precioEstancia), 2);
        });

        document.querySelectorAll(".totalprice").forEach(function (elemento) {
            elemento.textContent = roundN(Number(nuevoPrecioTotal), 2);
        });

        document.querySelectorAll(".prepago").forEach(function (elemento) {
            elemento.textContent = roundN(Number(nuevoPrepago), 2)
        });

        document.querySelectorAll(".feesincluded").forEach(function (elemento) {
            elemento.textContent = roundN(Number(incremento2), 2)
        });

        pextraRef.current.textContent = roundN(Number(precioExtras), 2);

        if (codigoDescuentoPrecio > 0 || codigoDescuentoPorcentaje > 0) {
            if (codigoDescuentoPrecio > 0) {
                document.querySelector(".codigo-carro").textContent = "-" + parseFloat(codigoDescuentoPrecio).toFixed(2);
            }
            if (codigoDescuentoPorcentaje > 0) {
                document.querySelector(".codigo-carro").textContent = "-" + (precioEstancia * (codigoDescuentoPorcentaje / 100)).toFixed(2);
            }
        }

        //idTarifaRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa");
        setIdTarifaRef(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa"));

        setPriceRef(btoa(precioEstancia));
        //priceRef.current.value = btoa(precioEstancia);
        setPrepagoRef(prepago);
        //prepagoRef.current.value = prepago;

        preciosDiariosRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-preciosdiarios');

        textopoliticaRef.current.value = textopol;

        datosreservaactualesRef.current.setAttribute("data-precioestancia", (Number(precioEstancia), 2));
        datosreservaactualesRef.current.setAttribute("data-totalprice", roundN(Number(precioTotal), 2));
        datosreservaactualesRef.current.setAttribute("data-prepago", roundN(Number(nuevoPrepago), 2));

        if (prepago === 0) {
            if (document.getElementById("payments1"))
                document.getElementById("paymens1").required = false;
            if (document.getElementById("payments2"))
                document.getElementById("payments2").required = false;
        }
        if (prepago > 0) {
            if (document.getElementById("payments1"))
                document.getElementById("payments1").required = true;
            if (document.getElementById("payments2"))
                document.getElementById("payments2").required = true;
            if (document.getElementById("payments5"))
                document.getElementById("payments5").required = true;
            if (document.getElementById("payments6"))
                document.getElementById("payments6").required = true;
        }

    }

    const handleChangeExtra = (param) => {

        var elemento = document.querySelector("select[name='" + param + "']");

        var valor = elemento.value;
        var precioExtra = parseFloat(elemento.getAttribute('data-precio')) / 100;
        var baseExtras = parseFloat(sumaExtrasRef.current.value);
        var baseExtrasTipo2 = parseFloat(sumaExtras2Ref.current.value);

        var precioBaseCE = datosreservaactualesRef.current.getAttribute("data-precioestancia");
        var precioExtraTarifa = datosreservaactualesRef.current.getAttribute("data-precioextratarifa");

        precioBaseCE = precioBaseCE - precioExtraTarifa;

        var tipoextra = parseInt(elemento.getAttribute('data-tipoextra'));

        var porreserva = parseInt(elemento.getAttribute('data-porreserva'));
        var porpersona = parseInt(elemento.getAttribute('data-porpersona'));
        var pordia = parseInt(elemento.getAttribute('data-pordia'));
        var pormes = parseInt(elemento.getAttribute('data-pormes'));

        var personas = parseInt(elemento.getAttribute('data-numpersonas'));
        var difdias = parseInt(elemento.getAttribute('data-numdias'));

        if (porreserva) {
            precioExtra = precioExtra * 1;
        }
        if (porpersona) {
            precioExtra = precioExtra * personas;
        }
        if (pordia) {
            precioExtra = precioExtra * difdias;
        }
        if (pormes) {
            precioExtra = precioExtra * (difdias / 30);
        }

        if (tipoextra === 0) {
            if (valor === "0") {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) - Number(precioExtra), 2);
            }

            else {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) + Number(precioExtra), 2);
            }
        }
        else if (tipoextra === 1) {
            if (valor === "0") {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) - Number(precioExtra), 2);
            }

            else {
                sumaExtrasRef.current.value = roundN(Number(baseExtras) + Number(precioExtra), 2);
            }
        }
        else if (tipoextra === 2) {
            precioExtra = (precioBaseCE * precioExtra) / 100;

            if (valor === "0") {
                sumaExtras2Ref.current.value = roundN(Number(baseExtrasTipo2) - Number(precioExtra), 2);

            }
            else {
                sumaExtras2Ref.current.value = roundN(Number(baseExtrasTipo2) + Number(precioExtra), 2);
            }
        }

        pextraRef.current.textContent = (parseFloat(sumaExtrasRef.current.value) + parseFloat(sumaExtras2Ref.current.value)) * Number(cantApartRef.current.value);

        handleChangeTarifa();

    }

    const handleChangeNumPropiedades = () => {
        cantApartRef.current.value = numpropiedades0Ref.current.value;
        handleChangeTarifa();
    };

    const handleChangeDiscount = () => {

        var el = document.querySelector('#nombrecodigo');

        let parameters = location.search.substring(1);
        var incrementotpv = 0;

        if (document.querySelector("input[type='radio'][name='payments1']") && document.querySelector("input[type='radio'][name='tarifa1']:checked")) {
            incrementotpv = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").attr("data-incremento"));
        }
        if (document.querySelector("#payments2") && document.querySelector("#payments2").checked) {
            incrementotpv = Number(document.querySelector("#payments2").getAttribute("data-incremento"));
        }
        if (document.querySelector("#payments5") && document.querySelector("#payments5").checked) {
            incrementotpv = Number(document.querySelector("#payments5").getAttribute("data-incremento"));
        }

        var precioEstancia = Number(document.querySelector(".tarifa1:checked").value);
        var precioExtras = Number(document.querySelector("#datosreserva").getAttribute('data-extras0'));
        var precioTotal = Number(Number(precioEstancia) + Number(precioExtras));
        var incremento = roundN((precioTotal * (incrementotpv / 100)), 2);
        var preciotpv = roundN((Number(precioTotal) + Number(incremento)), 2);
        var pagoaCuenta = roundN(preciotpv * (Number(document.querySelector(".tarifa1:checked").getAttribute('data-prepago')) / 100), 2);

        const result = axios.post('check-cliente-reserva', {
            idCodigoDescuento: el.value,
            parameters: parameters,
            dataType: 'JSON'
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.status === "success") {
                    var descuento;
                    if (solucion.tipo === 'moneda') {
                        descuento = parseFloat(solucion.descuento).toFixed(2);
                        codigodescuentoprecioRef.current.value = descuento;
                    } else {
                        descuento = (precioEstancia * (solucion.descuento / 100)).toFixed(2);
                        codigodescuentoporcentajeRef.current.value = solucion.descuento;
                    }

                    pagoaCuenta = roundN((preciotpv - descuento) * (Number(document.querySelector(".tarifa1:checked").getAttribute('data-prepago')) / 100), 2);

                    var resto = Number(preciotpv - descuento - pagoaCuenta);

                    document.querySelector('.codigo-carro').innerHTML = "<span>-" + descuento + "</span> ";
                    document.querySelector('.muestra-codigo-carro').style.display = "";
                    document.querySelector('#codigodescuento div').innerHTML = '<div class="ws-errorbox" style="display: block;" id="errorbox-1"><p class="ws-errormessage c008">Codigo de descuento valido.</p></div>';
                    document.querySelector('.prepago').textContent = roundN(Number(pagoaCuenta), 2) + " " + moneda;
                    handleChangeTarifa();
                }
                else {
                    codigodescuentoprecioRef.current.value = "";
                    codigodescuentoporcentajeRef.current.value = "";
                    var resto = preciotpv - pagoaCuenta;
                    document.querySelector('.muestra-codigo-carro').style.display = "none";
                    document.querySelector('.codigo-carro').textContent = '';
                    document.querySelector('#codigodescuento div').innerHTML = '<div class="ws-errorbox" style="display: block;" id="errorbox-1"><p class="ws-errormessage">Codigo de descuento no valido.</p></div>';
                    document.querySelector('.prepago').textContent = roundN(Number(pagoaCuenta), 2) + " " + moneda;
                    handleChangeTarifa();
                }
            })
    };

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    const handleSubmit = () => {
        if (nombrecRef.current.value && nombrecRef.current.value.replace(/\s+/g, '').length > 0 &&
            apellidoscRef.current.value && apellidoscRef.current.value.replace(/\s+/g, '').length > 0 &&
            emailcRef.current.value && emailcRef.current.value.replace(/\s+/g, '').length > 0 &&
            telefonocRef.current.value && telefonocRef.current.value.replace(/\s+/g, '').length > 0 &&
            documentocRef.current.value && documentocRef.current.value.replace(/\s+/g, '').length > 0 &&
            aceptacondicionesRef.current.checked) {
            console.log("voy a enviar");
            formularioreservaRef.current.submit();
        }
        else {
            console.log("error a enviar");
            handleShowModal()
        }
    }

    const handleBack = () => {
        window.history.back();
    }

    useEffect(() => {

        if (document.getElementById("payments2")) {
            document.getElementById("payments2").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments5")) {
            document.getElementById("payments5").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments3")) {
            document.getElementById("payments3").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments4")) {
            document.getElementById("payments4").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.getElementById("payments6")) {
            document.getElementById("payments6").addEventListener("click", function () {
                handleChangeTarifa();
            })
        }

        if (document.querySelector(".tpv")) {
            document.querySelector(".tpv").click(function () {
                handleChangeTarifa();
            });
        }

        if (document.querySelector(".bizum")) {
            document.querySelector(".bizum").click(function () {
                handleChangeTarifa();
            });
        }

    }, [])

    const [showMore, setshowMore] = useState(0);

    let lostpvs = [];

    {/* *************** */ }

    return (
        <div className="properties-details-page content-area-15">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="carousel properties-details-sliders slide mb-60"
                            id="propertiesDetailsSlider"
                        >
                            <div className="breadcrumb-wrapper">
                                <div className="container booking-step">
                                    <div className="gap-0 row">
                                        <div id="wizard">
                                            <ul id="steps">
                                                <li
                                                    className="item active"
                                                    id="item1"
                                                >
                                                    {t("Selección de fechas")}
                                                </li>
                                                <li
                                                    className="item active"
                                                    id="item2"
                                                >
                                                    {t("Selección de  alojamiento")}
                                                </li>
                                                <li
                                                    className="item actual"
                                                    id="item3"
                                                >
                                                    {t("Detalles de la reserva")}
                                                </li>
                                                <li
                                                    className="item"
                                                    id="item4"
                                                >
                                                    {t("Confirmación")}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="order-12 col-lg-8 col-sm-12 slider order-lg-1">
                        <div className="metro-box-wrapper">
                            { /*-- ********************** TARIFES  ********************************* */}
                            <div className="heading">
                                <h3>
                                    {t("Tarifas")}
                                </h3>
                            </div>
                            <div className="content">
                                {/* */}
                                {precios.map((unprecio, index) => {

                                    let acumulado = unprecio.suma / 100;
                                    let checked = ""
                                    let preciosdiarios = unprecio.calendario_sin_otas
                                    for (let i = 0; i < preciosdiarios.length; i++) {
                                        {/*acumulado += preciosdiarios[i].precio / 100;*/ }
                                        if (index === 0) cuentaNoches++;
                                    }
                                    if (index === 0) {
                                        precioBase = Number(acumulado).toFixed(2);
                                    }

                                    acumulado = acumulado.toFixed(2);
                                    resprices = resprices.concat(
                                        <div className="tarifes" key={"t" + index}>
                                            <div className="content">
                                                <div className="mt-radio-list">
                                                    <label className="mt-radio"
                                                        style={{
                                                            fontSize: '14px',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        {index === 0 ?
                                                            <input type="radio" name="tarifa1" className="tarifa1" defaultChecked id="tarifa" defaultValue={acumulado} data-preciosdiarios="" data-prepago={unprecio.info_tarifa.politica.prepago} data-idpolitica={unprecio.info_tarifa.politica_id} data-tarifa={unprecio.id} data-texto={unprecio.info_tarifa.politica.descripcion[lang]} onClick={handleChangeTarifa} />
                                                            :
                                                            <input type="radio" name="tarifa1" className="tarifa1" id="tarifa" defaultValue={acumulado} data-preciosdiarios="" data-prepago={unprecio.info_tarifa.politica.prepago} data-idpolitica={unprecio.info_tarifa.politica_id} data-tarifa={unprecio.id} data-texto={unprecio.info_tarifa.politica.descripcion[lang]} onClick={handleChangeTarifa} />
                                                        }
                                                        {' '}{" " + unprecio.info_tarifa.nombre_web}
                                                        <br />
                                                        <span
                                                            style={{
                                                                marginBottom: '0'
                                                            }}
                                                        >
                                                            <a
                                                                className="politicatitulo"
                                                                data-toggle="collapse"
                                                                href="#propuesta0"
                                                            >
                                                                {' '}
                                                                <span className="explicacion">
                                                                    {unprecio.info_tarifa.politica.nombre[lang]}
                                                                </span>
                                                            </a>
                                                            <span className="rateprice">
                                                                {" " + acumulado + " " + moneda}
                                                            </span>
                                                            <hr />
                                                            <div
                                                                className="collapse"
                                                                id="propuesta0"
                                                                onClick={() => { showMore == index ? setshowMore(0) : setshowMore(index) }}
                                                            >
                                                                <div className="card card-body" >
                                                                    {showMore === index && (
                                                                        <div dangerouslySetInnerHTML={{ __html: unprecio.info_tarifa.politica.descripcion[lang] }}></div>
                                                                    )}

                                                                    <span
                                                                        style={{
                                                                            fontSize: '12px'
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <a
                                                                            href="/intranet"
                                                                            target="_blank"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {resprices}
                                {/* */}
                            </div>
                        </div>
                        <form method="get" action="/resumen" id="formularioreserva" ref={formularioreservaRef}>
                            <input type="hidden" name="idpropiedad" id="idPropiedad" defaultValue={propiedad.id} />
                            <input type="hidden" name="llegada" defaultValue={llegada} id="llegada" />
                            <input type="hidden" name="salida" defaultValue={salida} id="salida" />
                            <input type="hidden" name="adultos" defaultValue={adultos} />
                            <input type="hidden" name="ninos" defaultValue={ninos} />
                            <input type="hidden" name="bebes" defaultValue={bebes} />
                            <input type="hidden" name="mascotas" defaultValue={mascotas} />
                            <input type="hidden" name="price" id="price" defaultValue={priceRef} />
                            <input type="hidden" name="discount" id="discount" defaultValue="0" />
                            <input type="hidden" name="fees" id="fees" defaultValue={Number(propiedad.fees) / 100} ref={feesRef} />
                            <input type="hidden" name="idusuario" id="user" defaultValue="" />
                            <input type="hidden" name="prepago" id="prepago" defaultValue={prepagoRef} />
                            <input type="hidden" name="idTarifa" id="idTarifa" defaultValue={idTarifaRef} />
                            <input type="hidden" name="idpolitica" id="idpolitica" defaultValue={precios[0].info_tarifa.politica_id} />
                            <input type="hidden" name="extratarifa" id="extratarifa" defaultValue="0" />
                            <input type="hidden" name="textopolitica" id="textopolitica" defaultValue={precios[0].info_tarifa.politica.descripcion[lang]} ref={textopoliticaRef} />
                            <input type="hidden" name="preciosDiarios" id="preciosDiarios" defaultValue="" ref={preciosDiariosRef} />


                            <div className="metro-box-wrapper">
                                <div className="heading">
                                    <h3>
                                        {t("Extras")}
                                    </h3>
                                </div>
                                <div className="content">
                                    <div className="form-horizontal mt-15">
                                        <div className="form-group">
                                            <label className="col-sm-4 col-md-3" />
                                        </div>

                                        {/* *** */}
                                        {propiedad.relacion_extras.map((unextra, index) => {
                                            {
                                                let baseprecio = unextra.precio;
                                                let dias_a_multiplicar = Math.min(difdias, propiedad.destino.maximo_dias);

                                                if (unextra.obligatorio) {
                                                    let tipo_precio = unextra.tipo_precio;
                                                    if (tipo_precio === "1") {
                                                        if (unextra.por_persona === 1) {
                                                            baseprecio = baseprecio * (Number(adultos) + Number(ninos))
                                                        }
                                                        if (unextra.por_dia_reserva === 1) {
                                                            baseprecio = baseprecio * difdias;
                                                        }
                                                    }
                                                    else {
                                                        baseprecio = precioBase * (baseprecio / 100);
                                                        if (unextra.por_persona === 1) {
                                                            baseprecio = baseprecio * (Number(adultos) + Number(ninos))
                                                        }
                                                        if (unextra.por_dia_reserva === 1) {
                                                            baseprecio = baseprecio * dias_a_multiplicar;
                                                        }

                                                    }
                                                    sumaextras += (baseprecio / 100)
                                                }

                                                resextras = resextras.concat(
                                                    <div className="form-group row" key={"e" + index}>
                                                        <label className="col-sm-4 col-md-3 control-label">
                                                            <div
                                                                className="mb-0 hoover-help"
                                                                data-html="true"
                                                                data-original-title=""
                                                                data-placement="left"
                                                                data-toggle="tooltip"
                                                                style={{
                                                                    fontSize: '14px'
                                                                }}
                                                                title=""
                                                            >
                                                                <i className="fa-regular fa-square-check text-success" style={{ marginLeft: "15px", marginRight: "7px" }} />
                                                                {unextra.nombre}
                                                            </div>
                                                        </label>

                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 extrasclass">
                                                            <select
                                                                className="selectpicker extra"
                                                                data-numdias={difdias}
                                                                data-numpersonas={Number(adultos) + Number(ninos)}
                                                                data-pordia={unextra.por_dia_reserva ? 1 : 0}
                                                                data-pormes={unextra.por_mes_reserva ? 1 : 0}
                                                                data-porpersona={unextra.por_persona ? 1 : 0}
                                                                data-porreserva={unextra.por_reserva ? 1 : 0}
                                                                data-precio={baseprecio}
                                                                data-tipoextra={unextra.tipo}
                                                                data-dialimpieza=""
                                                                disabled={unextra.obligatorio ? true : false}
                                                                data-extraid={unextra.servicios_extra_id}
                                                                name={"extra" + unextra.servicios_extra_id}
                                                                defaultValue={unextra.obligatorio ? 1 : 0}
                                                                onChange={() => handleChangeExtra("extra" + unextra.servicios_extra_id)}
                                                            >
                                                                <option value="0">{t("Seleccione")}</option>
                                                                <option value="1">{(baseprecio / 100).toFixed(2)} {
                                                                    unextra.por_reserva ?
                                                                        moneda + " / " + t("Por reserva")
                                                                        :
                                                                        unextra.por_persona ?
                                                                            moneda + " / " + t("Por persona")
                                                                            :
                                                                            unextra.por_dia_reserva ?
                                                                                moneda + " / " + t("Por dia")
                                                                                :
                                                                                unextra.por_mes_reserva ?
                                                                                    moneda + " / " + t("Por mes")
                                                                                    :
                                                                                    ""
                                                                }
                                                                </option>
                                                            </select>

                                                            {unextra.obligatorio ?
                                                                <input type="hidden" name={"extra" + unextra.servicios_extra_id} defaultValue={unextra.obligatorio ? 1 : 0} />
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}

                                        {resextras}

                                        {/* *** */}
                                        <input type="hidden" name="baseextras" id="baseextras" defaultValue={sumaextras} ref={sumaExtrasRef} />
                                        <input type="hidden" name="baseextrastipo2" id="baseextrastipo2" defaultValue="0" ref={sumaExtras2Ref} />

                                    </div>
                                </div>
                            </div>
                            <div
                                className="metro-box-wrapper"
                                id="userInfo"
                            >
                                <div className="heading">
                                    <h3>
                                        {t("Datos de la reserva")}
                                    </h3>
                                </div>
                                <div className="content">
                                    <div className="form-horizontal row">
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    className="mb-0 form-control font14"
                                                    defaultValue=""
                                                    name="firstname"
                                                    placeholder={t("Nombre") + " *"}
                                                    required
                                                    type="text"
                                                    ref={nombrecRef}
                                                    id="nombrec"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    className="mb-0 form-control font14"
                                                    defaultValue=""
                                                    name="lastname"
                                                    placeholder={t("Apellidos") + " *"}
                                                    required
                                                    type="text"
                                                    id="apellidosc"
                                                    ref={apellidoscRef}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    className="mb-0 form-control font14"
                                                    defaultValue=""
                                                    id="emailc"
                                                    name="email"
                                                    required
                                                    type="email"
                                                    placeholder={t("Email") + " *"}
                                                    ref={emailcRef}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    className="mb-0 form-control font14"
                                                    defaultValue=""
                                                    id="telefonoc"
                                                    name="phone"
                                                    placeholder={t("Teléfono") + " *"}
                                                    required
                                                    type="text"
                                                    ref={telefonocRef}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    className="mb-0 form-control font14"
                                                    id="documentoc"
                                                    name="documentousuario"
                                                    placeholder={t("Pasaporte/ID") + " *"}
                                                    type="text"
                                                    required
                                                    defaultValue=""
                                                    ref={documentocRef} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    className="mb-0 form-control font14"
                                                    defaultValue=""
                                                    name="city"
                                                    placeholder={t("Ciudad")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            {t("Todos los campos * son obligatorios")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="metro-box-wrapper">
                                <div className="heading">
                                    <h3>
                                        {t("Código promocional")}
                                    </h3>
                                </div>
                                <div className="content">
                                    <div className="form-horizontal row">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div id="codigodescuento">
                                                    <input type="text" className="mb-0 form-control" placeholder={t("Código")} name="codigodescuento" id="nombrecodigo" onKeyUp={handleChangeDiscount} />
                                                    <input type="hidden" id="codigodescuentoprecio" name="codigodescuentoprecio" defaultValue="" ref={codigodescuentoprecioRef} />
                                                    <input type="hidden" id="codigodescuentoporcentaje" name="codigodescuentoporcentaje" defaultValue="" ref={codigodescuentoporcentajeRef} />
                                                    <input type="hidden" name="moneda2" defaultValue={moneda} />
                                                    <input type="hidden" name="abreviaturamoneda2" defaultValue="EUR" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="metro-box-wrapper tipopago"
                                style={{
                                    display: 'block'
                                }}
                            >
                                <div className="heading">
                                    <h3>
                                        {t("Selecciona un método de pago")}
                                    </h3>
                                </div>
                                <div className="content">
                                    <div className="mb-20 clear" />
                                    <div
                                        className="payment-option-wrapper"
                                        id="paymentOption"
                                    >
                                        {/* */}
                                        <div className="row">
                                            {config.activa_tpv === 1 && tpvs.length > 0 ?
                                                tpvs.map((untpv, index) => {
                                                    lostpvs = lostpvs.concat(
                                                        <div key={"t" + index}>
                                                            <div className="col-sm-12"
                                                                style={{
                                                                    marginBottom: '20px'
                                                                }}
                                                            >
                                                                {index === 0 ?
                                                                    <div className="radio-block font-icon-radio btn-payment" >
                                                                        <input className="payments1 radio tpv" id="elem0" name="payments" type="radio" defaultValue={"paymentsCreditCard" + untpv.id} required="" data-incremento={untpv.markup ? untpv.markup.incremento : 0} data-idtpv={untpv.id} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0} onClick={handleChangeTarifa} defaultChecked />
                                                                        <label
                                                                            className="etiquetatpv"
                                                                            htmlFor="elem0"
                                                                            style={{ marginLeft: "10px" }}
                                                                        >

                                                                            {untpv.nombre_comercio} <i className="fa-brands fa-cc-visa fa-xl" style={{ marginLeft: "10px" }}></i>

                                                                        </label>
                                                                    </div>
                                                                    :
                                                                    <div className="radio-block font-icon-radio btn-payment">
                                                                        <input className="payments1 radio tpv" id="elem0" name="payments" type="radio" defaultValue={"paymentsCreditCard" + untpv.id} required="" data-incremento={untpv.markup ? untpv.markup.incremento : 0} data-idtpv={untpv.id} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0} onClick={handleChangeTarifa} />
                                                                        <label
                                                                            className="etiquetatpv"
                                                                            htmlFor="elem0"
                                                                            style={{ marginLeft: "10px" }}
                                                                        >

                                                                            {untpv.nombre_comercio} <i className="fa-brands fa-cc-visa fa-xl" style={{ marginLeft: "10px" }}></i>

                                                                        </label>
                                                                    </div>
                                                                }
                                                            </div>

                                                            {untpv.bizum == 1 ?

                                                                <div className="col-sm-12"
                                                                    style={{
                                                                        marginBottom: '20px'
                                                                    }}

                                                                >
                                                                    <div className='radio-block font-icon-radio btn-payment'>
                                                                        <input className='payments1 radio tpv' id='elem2-0' name='payments' type='radio' value={"paymentsBizum" + untpv.id} required="" data-incremento={untpv.markup ? untpv.markup.incremento : 0} data-idtpv={untpv.id} data-tipoincremento={untpv.markup ? untpv.markup.tipo : 0} onClick={handleChangeTarifa} />
                                                                        <label
                                                                            className="etiquetatpv"
                                                                            htmlFor='elem2-0'
                                                                            style={{ marginLeft: "10px" }}
                                                                        >

                                                                            {t("Bizum")} <i className="fa-duotone fa-mobile fa-xl" style={{ marginLeft: "10px" }}></i>

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                            }
                                                        </div>
                                                    )
                                                })
                                                :
                                                lostpvs = lostpvs.concat(<></>)
                                            }
                                            {lostpvs}
                                            {/* */}

                                            {config.activa_paypal === 1 && paypal ?
                                                <>
                                                    <div className="col-sm-12"
                                                        style={{
                                                            marginBottom: '20px'
                                                        }}
                                                    >
                                                        <div className="radio-block font-icon-radio btn-payment">
                                                            <input id="payments2" name="payments" type="radio" className="radio" value="paymentPaypal" required="" data-incremento={paypal.markup.incremento} data-tipoincremento={paypal.markup.tipo} onClick={handleChangeTarifa} />
                                                            <label
                                                                className="etiquetatpv"
                                                                htmlFor="payments2"
                                                                style={{ marginLeft: "10px" }}
                                                            >

                                                                {t("Paypal")} <i className="fa-brands fa-paypal fa-xl" style={{ marginLeft: "10px" }}></i>

                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            {config.activa_stripe === 1 ?
                                                <>
                                                    <div className="col-sm-12"
                                                        style={{
                                                            marginBottom: '20px'
                                                        }}
                                                    >
                                                        <div className="radio-block font-icon-radio btn-payment">
                                                            <input id="payments5" name="payments" type="radio" className="radio" value="paymentStripe" required="" data-incremento={stripe.markup.incremento} data-tipoincremento={stripe.markup.tipo} onClick={handleChangeTarifa} />
                                                            <label
                                                                className="etiquetatpv"
                                                                htmlFor="payments5"
                                                                style={{ marginLeft: "10px" }}
                                                            >

                                                                {stripe.nombre_comercio && stripe.nombre_comercio != '' ? stripe.nombre_comercio : "Stripe"} <i className="fa-brands fa-stripe fa-xl" style={{ marginLeft: "10px" }}></i>

                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            {config.activa_transferencia === 1 && transferencia ?
                                                <>
                                                    <div
                                                        style={{
                                                            marginBottom: '20px'
                                                        }}
                                                        className="col-sm-12"
                                                    >
                                                        <div className="radio-block font-icon-radio btn-payment">
                                                            <input id="payments4" name="payments" type="radio" className="radio" value="paymentTransferencia" required="" onClick={handleChangeTarifa} />
                                                            <label
                                                                className="etiquetatpv"
                                                                htmlFor="payments4"
                                                                style={{ marginLeft: "10px" }}
                                                            >

                                                                {transferencia.nombre_web} <i className="fa-duotone fa-building fa-xl" style={{ marginLeft: "10px" }}></i>

                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="col-sm-12 mb-10-m explicacionTransferencia"
                                                        style={{
                                                            display: 'none',
                                                            marginTop: '20px'
                                                        }}
                                                    >
                                                        <p dangerouslySetInnerHTML={{ __html: transferencia.descripcion[lang] }}></p>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            {config.activa_paycomet === 1 && paycomet ?
                                                <>
                                                    {
                                                        Object.keys(paycomet.metodos_pago).map((key) => (
                                                            <div
                                                                style={{
                                                                    marginBottom: '20px'
                                                                }}
                                                                className="col-sm-12" key={"pc" + key}>
                                                                <div className="radio-block font-icon-radio btn-payment">
                                                                    <input id="payments6" name="payments" type="radio" className="radio payments6" value={"paymentPaycomet" + key} required="" onClick={handleChangeTarifa} />
                                                                    <label
                                                                        className="etiquetatpv"
                                                                        htmlFor="payments6">

                                                                        {paycomet.metodos_pago[key].nombre}
                                                                        {
                                                                            paycomet.metodos_pago[key].nombre === "Tarjeta" ?
                                                                                <i className="fa-brands fa-cc-visa fa-xl ms-2"></i>
                                                                                :
                                                                                paycomet.metodos_pago[key].nombre === "PayPal" ?
                                                                                    <i className="fa-brands fa-paypal fa-xl ms-2"></i>
                                                                                    :
                                                                                    <i className="fa-duotone fa-mobile fa-xl ms-2"></i>
                                                                        }

                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        paycomet.apple_pay === 1 ?
                                                            <div
                                                                style={{
                                                                    marginBottom: '20px'
                                                                }}
                                                                className="col-sm-12"
                                                            >
                                                                <div className="radio-block font-icon-radio btn-payment">
                                                                    <input id="payments6a" name="payments" type="radio" className="radio payments6" value="paymentPaycomet1" required="" onClick={handleChangeTarifa} />
                                                                    <label
                                                                        className="etiquetatpv"
                                                                        htmlFor="payments6a">

                                                                        {t("Apple")} <i className="fa-brands fa-apple-pay fa-2xl ms-2"></i>

                                                                    </label>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        paycomet.google_pay === 1 ?
                                                            <div
                                                                style={{
                                                                    marginBottom: '20px'
                                                                }}
                                                                className="col-sm-12"
                                                            >
                                                                <div className="radio-block font-icon-radio btn-payment">
                                                                    <input id="payments6g" name="payments" type="radio" className="radio payments6" value="paymentPaycomet1" required="" onClick={handleChangeTarifa} />
                                                                    <label
                                                                        className="etiquetatpv"
                                                                        htmlFor="payments6g"
                                                                    >

                                                                        {t("Google")} <i className="fa-brands fa-google-pay fa-2xl ms-2"></i>

                                                                    </label>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="metro-box-wrapper">
                                <div className="heading" />
                                <div className="content">
                                    <p className="payment-term">
                                        <input
                                            className="aceptacondiciones"
                                            name="aceptacondiciones"
                                            required
                                            type="checkbox"
                                            ref={aceptacondicionesRef}
                                        />
                                        {' '}{t("Acepta los")}{' '}
                                        <a
                                            href={"/" + preidioma + t("terminos")}
                                            target="_blank"
                                        >
                                            {t("términos y condiciones")}
                                        </a>
                                        {' '}{t("tanto como la")}{' '}
                                        <a
                                            href={"/" + preidioma + t("politica-de-cancelacion")}
                                            target="_blank"
                                        >
                                            {t("política de cancelación")}
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                            <input
                                className="btn d-none d-sm-none d-md-none d-lg-block labelpay"
                                id="payhidden"
                                style={{
                                    display: 'none!important'
                                }}
                                type="submit"
                                value={t("pagar")}
                            />
                        </form>
                        <div
                            className="formularioenviar"
                            style={{
                                display: 'none'
                            }}
                        />
                        <div className="payment-congrate">
                            <div className="inner">
                                <div className="centrarelementos">
                                    <input
                                        className="btn d-block d-sm-none d-md-none d-lg-none d-xl-none labelpaym"
                                        id="formbookm"
                                        type="button"
                                        onClick={handleSubmit}
                                        value={t("Reserve Ya")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ***************** */}
                    <div className="order-1 col-lg-4 col-sm-12 order-lg-12">
                        <div className="sidebar mbl sticky-top">
                            <div className="widget search-area">
                                <div className="search-area-inner">
                                    <div className="search-contents ">
                                        <div className="content mb-30">
                                            <h5>
                                                {propiedad.nombre}
                                            </h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        propiedad.foto_principal.length != 0
                                                            ? <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.foto_principal[0].nombre} alt={propiedad.foto_principal[0].texto_alt ? propiedad.foto_principal[0].texto_alt[lang] : ""} title={propiedad.foto_principal[0].texto_title ? propiedad.foto_principal[0].texto_title[lang] : ""}
                                                                className="img-fluid" />
                                                            : <img src="/images/web/bedloop-nofoto.png" alt="properties-photo"
                                                                className="img-fluid" />
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="bookingdates">
                                                        <div className="cell primercell">
                                                            <time className="cal-fecha">
                                                                <strong>
                                                                    {format(fechallegada, 'MMM')}
                                                                </strong>
                                                                <span>
                                                                    {format(fechallegada, 'dd')}
                                                                </span>
                                                                <p>
                                                                    {format(fechallegada, 'yyyy')}
                                                                </p>
                                                            </time>
                                                        </div>
                                                        <div className="cell centercell">
                                                        </div>
                                                        <div className="cell lastcell">
                                                            <time className="cal-fecha">
                                                                <strong>
                                                                    {format(fechasalida, 'MMM')}
                                                                </strong>
                                                                <span>
                                                                    {format(fechasalida, 'dd')}
                                                                </span>
                                                                <p>
                                                                    {format(fechasalida, 'yyyy')}
                                                                </p>
                                                            </time>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-20">
                                            <button
                                                className="search-button btn btn-color"
                                                onClick={handleBack}
                                            >
                                                {t("Cambiar fechas")}
                                            </button>
                                        </div>
                                        <div className="mb-20 content">
                                            <h5>
                                                {t("Precio")}
                                            </h5>
                                            <ul className="inverse" style={{ listStyle: "none" }}>
                                                <li className="clearfix">
                                                    {cuentaNoches + " " + t("Noches") + " " + (Number(adultos) + Number(ninos)) + " " + t("personas")}
                                                    <span className="absolute fderecha">
                                                        <span className="pricebase">
                                                            {precioBase}
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            {moneda}
                                                        </span>
                                                    </span>
                                                </li>
                                                <li
                                                    className="clearfix"
                                                    id="hayextracat"
                                                    style={{
                                                        display: 'none'
                                                    }}
                                                >
                                                    <span className="nombreextracat">
                                                        ----
                                                    </span>
                                                    <span className="absolute extracat fderecha" ref={pextracatRef}>
                                                        {moneda}
                                                    </span>
                                                </li>
                                                <li className="clearfix">
                                                    {t("Extras")}
                                                    <span className="absolute fderecha">
                                                        <span className="pextra" ref={pextraRef}>{(parseFloat(propiedad.fees) / 100).toFixed(2)}</span>
                                                        <span
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            {moneda}
                                                        </span>
                                                    </span>
                                                </li>
                                                <li
                                                    className="clearfix muestra-codigo-carro"
                                                    style={{
                                                        display: 'none'
                                                    }}
                                                >
                                                    {t("Código")}
                                                    <span className="absolute">
                                                        <span className="codigo-carro" />
                                                        <span
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            {moneda}
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="content total-price">
                                            <h5>
                                                {t("Total")}
                                                <span
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                        marginLeft: '4px',
                                                        marginTop: '-1px'
                                                    }}
                                                >
                                                    {moneda}
                                                </span>
                                                <span className="totalprice">
                                                    {(parseFloat(precioBase) + parseFloat(propiedad.fees / 100)).toFixed(2)}
                                                </span>
                                            </h5>
                                            <h5>
                                                {t("Prepago")}
                                                <span
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                        marginLeft: '4px',
                                                        marginTop: '-1px'
                                                    }}
                                                >
                                                    {moneda}
                                                </span>
                                                <span className="prepago">
                                                    {((parseFloat(precioBase) + parseFloat(propiedad.fees / 100)) * parseFloat(precios[0].info_tarifa.politica.prepago / 100)).toFixed(2)}
                                                </span>
                                            </h5>
                                            <h5>
                                                {t("Pago Final")}
                                                <span
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                        marginLeft: '4px',
                                                        marginTop: '-1px'
                                                    }}
                                                >
                                                    {moneda}
                                                </span>
                                                <span className="topay">
                                                    {((parseFloat(precioBase) + parseFloat(propiedad.fees / 100)) - ((parseFloat(precioBase) + parseFloat(propiedad.fees / 100)) * parseFloat(precios[0].info_tarifa.politica.prepago / 100))).toFixed(2)}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="payment-congrate">
                                            <div className="inner">
                                                <div className="centrarelementos">
                                                    <input
                                                        className="btn d-none d-sm-none d-md-none d-lg-block labelpay"
                                                        id="formbook"
                                                        type="submit"
                                                        value={t("Reserve Ya")}
                                                    />
                                                </div>
                                                <p>
                                                    * {t("Impuestos incluidos")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    data-extras0={propiedad.fees}
                                    data-fees0="0"
                                    data-precioestancia0={precioBase}
                                    data-precioextratarifa="0"
                                    data-prepago0={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)}
                                    data-resto0="0"
                                    data-totalprice0={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)}
                                    data-tpcprepago="100"
                                    id="datosreserva"
                                />
                                <span
                                    ref={datosreservaactualesRef}
                                    data-extras={propiedad.fees}
                                    data-fees="0"
                                    data-precioestancia={precioBase}
                                    data-precioextratarifa="0"
                                    data-prepago={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)}
                                    data-resto="0"
                                    data-totalprice={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)}
                                    data-tpcprepago="100"
                                    id="datosreservaactuales"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal} onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Error")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix mb-20 ml-20 row">
                        {t("Campos * son obligatorios")}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default Content;
