import Cabecera from './Compartido/Cabecera.jsx';
import Footer from './Compartido/Footer.jsx';
import Seccion1 from './Apartamentos/Seccion1.jsx';
import GridApartamentos from './Apartamentos/GridApartamentos.jsx';
import '../../../../../resources/css/web/reysabio/styles.css';

const Apartamentos = (props) => {
    return (
        <main>
            <Cabecera
                lang={props.lang}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
                config={props.websgrupo}
            />
            <Seccion1
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                llegada={props.llegada}
                salida={props.salida}
                lang={props.lang}
            />
            <GridApartamentos
                lang={props.lang}
                propiedades={props.propiedades}
                ruta={props.ruta}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                llegada={props.llegada}
                salida={props.salida}
            />
            <Footer
                lang={props.lang}
                websgrupo={props.websgrupo}
            />
        </main>
    );
}

export default Apartamentos;

